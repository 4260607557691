import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getJobOffers = async (page, size, jobCategoryId, title) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/job-offer?page=${pageNumber}&size=${pageSize}`;

  if (jobCategoryId) {
    url += `&jobCategoryId=${jobCategoryId}`;
  }

  if (title) {
    url += `&title=${title}`;
  }

  url += `&active=true`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyJobOffers = async (companyId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/job-offer/company/` + companyId,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getJobOffer = async (jobOfferId) => {
  try {
    const response = await axios.get(`${BASE_URL}/job-offer/` + jobOfferId, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPostulatesByCompany = async (companyId, page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  try {
    const response = await axios.get(
      `${BASE_URL}/job-offer/get-postulates/company/` +
        companyId +
        `?page=${pageNumber}&size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createJobOffer = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/job-offer/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const selectPostulate = async (jobOfferApplicationId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/job-offer-application/select/` + jobOfferApplicationId,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const applyToJobOffer = async (employeeSummaryId, jobOfferId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/job-offer-application/apply/employee/` +
        employeeSummaryId +
        "/job-offer/" +
        jobOfferId,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateJobOffer = async (jobOfferId, data) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/job-offer/` + jobOfferId,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableJobOffer = async (jobOfferId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/job-offer/` + jobOfferId + "/disable",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
