import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { createCourse } from "../../../utils/courses/api";

export default function EmployeeCoursesCreateModal() {
  const [showModal, setShowModal] = useState(false);
  const [newDocumentSrc, setNewDocumentSrc] = useState(null);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleSave = async () => {
    let res;
    try {
      res = await createCourse(formData);
      if (res.status === 200) {
        toast.success("Estudio agregado con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    inProgress: false,
    file: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewDocumentSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
      endDate: checked ? "" : prevData.endDate,
    }));
  };

  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedDate,
    }));
  };

  const handleEndDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      endDate: formattedDate,
    }));
  };

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Agregar estudio
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Agregar estudio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Nombre:</label>
                <input type="text" onChange={handleInputChange} name="name" />
              </div>
              <div>
                <label>Descripción:</label>
                <textarea
                  rows={4}
                  type="text"
                  onChange={handleInputChange}
                  name="description"
                />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  type="date"
                  onChange={handleInputChange}
                  name="startDate"
                />
              </div>
              <div>
                <label>Fecha de fin:</label>
                <input
                  type="date"
                  onChange={handleInputChange}
                  name="endDate"
                  disabled={formData.inProgress}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={formData.inProgress}
                  name="inProgress"
                  onChange={handleCheckboxChange}
                />
                <label>&nbsp;Actualmente en progreso</label>
              </div>
            </Col>
            <div>
              <label className="change-image-label">
                Subir certificado de estudio (opcional) <br></br>[PDF o imagen]
                <br></br>(máx. 5MB, formato: jpg, jpeg, png, pdf)
                <br></br>
                <input
                  type="file"
                  onChange={handleDocumentChange}
                  accept="image/*, application/pdf"
                />
              </label>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Crear
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
