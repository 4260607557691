import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Badge } from "react-bootstrap";
import { faEdit, faSave, faEye } from "@fortawesome/free-solid-svg-icons";
import "./CompanyJobOfferDetailsModal.css";
import moment from "moment/moment";
import { updateJobOffer } from "../../../utils/job-offers/api";
import { toast } from "react-toastify";

export default function CompanyJobOfferDetailsModal({
  jobOffer,
  jobCategories,
  attitudes,
  isDisabled,
}) {
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    jobOffer.jobCategory.id
  );
  const [selectedAttitudes, setSelectedAttitudes] = useState(
    jobOffer.attitudes.map((attitude) => attitude.id)
  );

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
      attitudesId: selectedAttitudes,
    }));
  }, [editing, selectedAttitudes]);

  const handleEdit = () => {
    setEditing(true);

    setFormData({
      title: jobOffer?.title,
      address: jobOffer?.address,
      responsibilities: jobOffer?.responsibilities,
      requirements: jobOffer?.requirements,
      jobCategoryId: selectedCategory || jobOffer?.jobCategory.id,
      attitudesId:
        selectedAttitudes || jobOffer?.attitudes.map((attitude) => attitude.id),
    });
  };

  const handleSave = async () => {
    if (jobOffer) {
      let res;
      try {
        res = await updateJobOffer(jobOffer.id, formData);
        if (res.status === 200) {
          toast.success("Oferta de trabajo modificada con éxito");
          handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.response.data.message);
        }
      } catch (error) {
        const { errors } = res.response.data;
        Object.keys(errors).forEach((field) => {
          const errorMessage = errors[field];
          toast.error(`${errorMessage}`);
        });
      }
    }
  };

  const [formData, setFormData] = useState({
    title: jobOffer?.title || "",
    responsibilities: jobOffer?.responsibilities || "",
    requirements: jobOffer?.requirements || "",
    jobCategoryId: jobOffer?.jobCategory.id || "",
    attitudesId: jobOffer?.attitudes.map((attitude) => attitude.id) || [],
  });

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) => {
      const updatedAttitudes = prevAttitudes.filter((id) => id !== attitudeId);

      setFormData((prevData) => ({
        ...prevData,
        attitudesId: updatedAttitudes,
      }));

      return updatedAttitudes;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "selectedCategory") {
      setSelectedCategory(value);
      setFormData((prevData) => ({
        ...prevData,
        jobCategoryId: value,
      }));
    } else if (name === "selectedAttitudes") {
      const selectedAttitudesArray = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );
      setSelectedAttitudes(selectedAttitudesArray);

      setFormData((prevData) => ({
        ...prevData,
        attitudesId: selectedAttitudesArray,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button className="btn btn-link" onClick={handleOpen}>
        <FontAwesomeIcon icon={faEye} />
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {jobOffer.company.name} - {jobOffer.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Título:</label>
                <input
                  type="text"
                  defaultValue={jobOffer.title}
                  onChange={handleInputChange}
                  disabled={!editing}
                  name="title"
                />
              </div>
              <div>
                <label>Dirección donde se requiere el puesto:</label>
                <input
                  type="text"
                  defaultValue={jobOffer.address}
                  onChange={handleInputChange}
                  disabled={!editing}
                  name="address"
                />
              </div>
            </Col>
            <div>
              <label>Responsabilidades:</label>
              <textarea
                style={{ textAlign: "left" }}
                rows={5}
                defaultValue={jobOffer.responsibilities}
                disabled={!editing}
                onChange={handleInputChange}
                name="responsibilities"
              ></textarea>
            </div>
            <div>
              <label>Requisitos:</label>
              <textarea
                style={{ textAlign: "left" }}
                rows={3}
                disabled={!editing}
                onChange={handleInputChange}
                value={editing ? formData.requirements : jobOffer.requirements}
                name="requirements"
              ></textarea>
            </div>
            <div>
              <label>Actitudes:</label>
              {!editing ? (
                <div>
                  {jobOffer.attitudes.map((attitude) => (
                    <Badge key={attitude.id} className="badge-pill">
                      {attitude.name}
                    </Badge>
                  ))}
                </div>
              ) : (
                <div>
                  {selectedAttitudes.map((attitudeId) => {
                    const attitude = attitudes.find(
                      (attitude) => attitude.id === attitudeId
                    );
                    if (attitude) {
                      return (
                        <Badge
                          key={attitude.id}
                          className="badge-pill"
                          onClick={() => handleRemoveAttitude(attitudeId)}
                        >
                          {attitude.name}
                        </Badge>
                      );
                    }
                    return null;
                  })}
                  <select
                    value=""
                    name="selectedAttitudes"
                    onChange={(e) => {
                      const attitudeId = e.target.value;
                      setSelectedAttitudes((prevAttitudes) => {
                        if (prevAttitudes.includes(attitudeId)) {
                          return prevAttitudes;
                        }
                        return [...prevAttitudes, attitudeId];
                      });
                    }}
                  >
                    <option value="" disabled>
                      -- Agregar actitud --
                    </option>
                    {attitudes.map((attitude) => (
                      <option key={attitude.id} value={attitude.id}>
                        {attitude.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <Col>
              <div>
                <label>Fecha de creación:</label>
                <input
                  type="text"
                  value={moment(jobOffer.createdAt).format("DD/MM/YYYY")}
                  disabled
                />
              </div>
            </Col>
            <Col>
              <div>
                <label>Categoría:</label>
                {!editing ? (
                  <input
                    type="text"
                    value={jobOffer.jobCategory.name}
                    disabled
                  />
                ) : (
                  <Form.Select
                    name="selectedCategory"
                    className="modal-select"
                    value={selectedCategory}
                    onChange={handleInputChange}
                  >
                    {jobCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!editing ? (
            <Button
              variant="outline-primary"
              onClick={handleEdit}
              style={isDisabled ? { display: "none" } : {}}
            >
              <FontAwesomeIcon icon={faEdit} /> Editar
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
