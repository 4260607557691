import React from "react";
import "./EmployeeSummaryContent.css";
import { BASE_URL } from "../../../utils/endpoints/urls";
import moment from "moment/moment";
import Avatar from "react-avatar";
import RatingStars from "../../EmployeeProfile/RatingStars/RatingStars";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const EmployeeSummaryContent = ({
  employeeData,
  companyQualifications,
  companyValidateEmployees,
}) => {
  if (!employeeData) return <div>Cargando...</div>;

  const {
    employee,
    companies,
    courses,
    attitudes,
    workExperiences,
    jobCategories,
    averageQualification,
    openToWork,
    image,
  } = employeeData;

  let imageSrc;
  if (image?.id) {
    imageSrc = `${BASE_URL}/images/${image?.id}`;
  } else {
    imageSrc = null;
  }

  let companyImageSrc;
  if (companies?.image?.id) {
    companyImageSrc = `${BASE_URL}/images/${companies?.image?.id}`;
  } else {
    companyImageSrc = null;
  }

  return (
    <div className="employee-summary">
      <div className="header">
        <div className="profile-picture">
          {imageSrc ? (
            <img className="avatar-image" src={imageSrc} alt="User Avatar" />
          ) : (
            <Avatar
              name={employee.name}
              color="#7800FF"
              size="50"
              round={true}
            />
          )}
        </div>
        <div className="employee-details">
          <h2 className="benefits-h1">{employee.name}</h2>
        </div>
      </div>
      <Row>
        <Col>
          <div>
            <p>
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ marginRight: "5px", color: "#7800FF" }}
              />
              Correo electrónico:{" "}
              <a
                style={{ textDecoration: "none" }}
                href={`mailto:${employee.email}`}
              >
                {employee.email}
              </a>
            </p>
            <p>
              <FontAwesomeIcon
                icon={faPhone}
                style={{ marginRight: "5px", color: "#7800FF" }}
              />
              Teléfono: {employee.phone}
            </p>
            <p>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ marginRight: "5px", color: "#7800FF" }}
              />
              Dirección: {employee.address}
            </p>
            <p>
              <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ marginRight: "5px", color: "#7800FF" }}
              />
              Fecha de nacimiento:{" "}
              {moment(employee.birthDate).format("DD/MM/yyyy")}
            </p>
          </div>
        </Col>
        <Col>
          <div>
            <div className="rating-star-container">
              {averageQualification !== 0 ? (
                <RatingStars rating={averageQualification} />
              ) : (
                <p>Este usuario no tiene calificaciones</p>
              )}
              <p>Calificación promedio: {averageQualification}</p>
            </div>
            <p>
              Tiene interés en buscar nuevo empleo: {openToWork ? "Sí" : "No"}
            </p>
          </div>
        </Col>
      </Row>
      <hr></hr>
      <Row className="row">
        <Col>
          <div className="section" style={{ backgroundColor: "#D8DFE4" }}>
            <h3 style={{ marginLeft: "10px" }} className="benefits-h1">
              Empresa/s activas
            </h3>
            {companies.map((company) => (
              <div key={company.id} className="company">
                <div className="company-info">
                  {company.image ? (
                    <img
                      src={`${BASE_URL}/images/${company.image.id}`}
                      alt="Empresa"
                      className="avatar-image-summary"
                    />
                  ) : (
                    <Avatar
                      name={company.name}
                      color="#7800FF"
                      size="50"
                      round={true}
                    />
                  )}
                  <div className="company-details">
                    <h4 style={{ fontWeight: "bold" }}>{company.name}</h4>
                    <p className="company-description">{company.description}</p>
                  </div>
                </div>
              </div>
            ))}
            <hr className="contact-hr" />
          </div>
        </Col>
        <Col>
          <div className="section" style={{ backgroundColor: "#D8DFE4" }}>
            <h3 className="benefits-h1" style={{ marginLeft: "10px" }}>
              Experiencias Laborales
            </h3>
            {workExperiences.map((experience) => (
              <div key={experience.id} className="experience">
                <h4 style={{ fontWeight: "bold" }}>{experience.position}</h4>
                <p className="company-name">{experience.company}</p>
                <p className="description-name">{experience.description}</p>
                <p className="date-range">
                  {moment(experience.startDate).format("MMM. YYYY")} -{" "}
                  {experience.endDate
                    ? moment(experience.endDate).format("MMM. YYYY")
                    : "Actualidad"}{" "}
                  (
                  {(() => {
                    const startDate = moment(experience.startDate);
                    const endDate = experience.endDate
                      ? moment(experience.endDate)
                      : moment();
                    const duration = moment.duration(endDate.diff(startDate));
                    const years = duration.years();
                    const months = duration.months();

                    let durationString = "";
                    if (years > 0) {
                      durationString +=
                        years === 1 ? "1 año " : `${years} años `;
                    }
                    if (months > 0) {
                      durationString +=
                        months === 1 ? "1 mes" : `${months} meses`;
                    }

                    return durationString;
                  })()}
                  )
                </p>
                <hr className="contact-hr" />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col>
          <div className="section" style={{ backgroundColor: "#D8DFE4" }}>
            <h3 style={{ marginLeft: "10px" }} className="benefits-h1">
              Educación
            </h3>
            <h6 style={{ marginLeft: "10px" }}>
              Puntaje por cursos: {employeeData.coursesScore}
            </h6>
            {courses.map((course) => (
              <div key={course.id} className="course">
                <h4 style={{ fontWeight: "bold" }}>{course.name}</h4>
                <p className="course-description">{course.description}</p>
                <p>
                  {moment(course.startDate).format("DD/MM/yyyy")} -{" "}
                  {course.endDate
                    ? moment(course.endDate).format("DD/MM/yyyy")
                    : "Actualidad"}
                </p>
                {course.certificate && (
                  <div>
                    <a
                      style={{ textDecoration: "none" }}
                      href={`${BASE_URL}/documents/download/${course.certificate.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Descargar certificado
                    </a>
                  </div>
                )}
                <hr className="contact-hr"></hr>
              </div>
            ))}
          </div>
        </Col>
        <Col>
          <div className="section">
            <h3 className="benefits-h1" style={{ marginLeft: "10px" }}>
              Actitudes
            </h3>
            <ul className="attitudes-list">
              {attitudes.map((attitude) => (
                <li key={attitude.id}>{attitude.name}</li>
              ))}
            </ul>

            <h3 className="benefits-h1" style={{ marginLeft: "10px" }}>
              Categorías de empleo
            </h3>
            <ul className="job-categories-list">
              {jobCategories.map((category) => (
                <li key={category.id}>{category.name}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col>
          <div className="section" style={{ backgroundColor: "#D8DFE4" }}>
            <h3 style={{ marginLeft: "10px" }} className="benefits-h1">
              Calificaciones de empresas
            </h3>
            {companyValidateEmployees &&
              companyValidateEmployees.map((companyValidateEmployee) => (
                <ul key={companyValidateEmployee.id} className="attitudes-list">
                  <h4>{companyValidateEmployee.company.name}</h4>
                  <p>
                    {moment(companyValidateEmployee.createdAt).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <div>
                    <div>
                      {companyValidateEmployee.attitudesRating.map(
                        (attitudeRating) => (
                          <li key={attitudeRating.id} className="attitude-item">
                            {attitudeRating.attitude.name}
                            <RatingStars rating={attitudeRating.rating} />
                          </li>
                        )
                      )}
                    </div>
                  </div>
                  <hr></hr>
                </ul>
              ))}
          </div>
        </Col>
        <Col>
          <div className="section">
            <h3 className="benefits-h1" style={{ marginLeft: "10px" }}>
              Calificaciones por atención al público
            </h3>
            <ul className="attitudes-list">
              {companyQualifications &&
                companyQualifications.map((companyQualification) => (
                  <div>
                    <div>
                      <p>
                        Lugar de atención: {companyQualification.company.name}
                      </p>
                      <RatingStars rating={companyQualification.stars} />
                    </div>
                    <p>
                      {moment(companyQualification.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                    <div>
                      {companyQualification?.attitudes?.map((attitude) => (
                        <li key={attitude.id}>{attitude.name}</li>
                      ))}
                    </div>
                    <hr></hr>
                  </div>
                ))}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmployeeSummaryContent;
