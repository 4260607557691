import React, { useState, useEffect } from "react";
import "./SponsorContainer.css";
import SponsorCard from "./SponsorCard";
import { Container } from "react-bootstrap";
import arrow from "../../../assets/worker level elemento25.png";
import { fetchSponsors } from "../../../utils/endpoints/sponsors/api";

const SponsorContainer = () => {
  const [sponsors, setsponsors] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 4;

  useEffect(() => {
    const fetchCommentsFromApi = async () => {
      const fetchedsponsors = await fetchSponsors();
      setsponsors(fetchedsponsors);
    };

    fetchCommentsFromApi();
  }, []);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < Math.ceil(sponsors.length / pageSize) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Obtiene los patrocinadores correspondientes a la página actual
  const getCurrentPageSponsors = () => {
    if(Array.isArray(sponsors) && sponsors.length > 0) {
      const startIndex = currentPage * pageSize;
      const endIndex = startIndex + pageSize;
      return sponsors.slice(startIndex, endIndex);
    } 
    return [];
  };

  return (
    <div
      className="sponsor-parent-container"
      style={{ backgroundColor: "#D8DFE4" }}
    >
      <Container>
        <div className="sponsor-title">
          <h2>Empresas destacadas</h2>
          <h3>
            Publican ofertas de empleo <a href="/employee-job-offer">aquí</a>
          </h3>
        </div>
        <div className="sponsor-container">
          <div className="carousel">
            <button
              className="carousel-button"
              onClick={handlePrevPageClick}
              disabled={currentPage === 0}
            >
              <img src={arrow} alt="arrow" />
            </button>
            <div className="sponsor-cards">
              {getCurrentPageSponsors().map((sponsor) => (
                <SponsorCard key={sponsor.id} sponsor={sponsor} />
              ))}
            </div>
            <button
              className="carousel-button"
              onClick={handleNextPageClick}
              disabled={
                currentPage >= Math.ceil(sponsors.length / pageSize) - 1
              }
            >
              <img
                style={{ transform: "scaleX(-1)" }}
                src={arrow}
                alt="arrow"
              />
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SponsorContainer;
