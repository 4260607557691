import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { createWorkExperience } from "../../../utils/work-experience/api";

export default function EmployeeWorkExperienceCreateModal() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleSave = async () => {
    let res;
    try {
      res = await createWorkExperience(formData);
      if (res.status === 200) {
        toast.success("Experiencia laboral agregada con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error();
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    company: "",
    cuit: "",
    position: "",
    description: "",
    startDate: "",
    endDate: "",
    active: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
      endDate: checked ? "" : prevData.endDate,
    }));
  };

  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedDate,
    }));
  };

  const handleEndDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      endDate: formattedDate,
    }));
  };

  const formatDate = (date) => {
    if (!date) return "";
    const formattedDate = new Date(date).toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Agregar experiencia laboral
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Nueva experiencia laboral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Compañía:</label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  name="company"
                />
              </div>
              <div>
                <label>CUIT: (opcional)</label>
                <input type="text" onChange={handleInputChange} name="cuit" />
              </div>
              <div>
                <label>Puesto:</label>
                <input
                  type="text"
                  onChange={handleInputChange}
                  name="position"
                />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  type="date"
                  onChange={handleInputChange}
                  name="startDate"
                />
              </div>
              <div>
                <label>Fecha de fin:</label>
                <input
                  type="date"
                  onChange={handleInputChange}
                  name="endDate"
                  disabled={formData.active}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={formData.active}
                  name="active"
                  onChange={handleCheckboxChange}
                />
                <label>&nbsp;Actualmente activo</label>
              </div>
            </Col>
            <div>
              <label>Descripción:</label>
              <textarea
                style={{ textAlign: "left" }}
                rows={5}
                onChange={handleInputChange}
                name="description"
              ></textarea>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Crear
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
