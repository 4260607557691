import React from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyQR/CompanyQR.css";
import { Col, Container } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";

export default function FreelanceQR({ user }) {
  const qrRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => qrRef.current,
  });

  if (user?.role !== "FREELANCE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
              <a href="/company-membership">Solicite su membresía aquí</a>
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="qr-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <Col>
            <div ref={qrRef} className="qr-div">
              <div className="header">
                <h4>{user.name}</h4>
              </div>
              <p className="foot-qr">Calificar atención</p>
              <QRCode
                value={`${window.location.origin}/freelance-qualification/${user?.id}`}
              />
              <p className="foot-qr">Gracias</p>
              <p className="small-text">www.workerlevel.com</p>
              <button onClick={handlePrint}>Descargar</button>
            </div>
          </Col>
        </Container>
      </div>
    </div>
  );
}
