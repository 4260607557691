import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Badge, Col } from "react-bootstrap";
import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { createCompanyValidateEmployee } from "../../../utils/company-validate-employee/api";
import AttitudeRatingStar from "../AttitudeRatingStar/AttitudeRatingStar";
import { BASE_URL } from "../../../utils/endpoints/urls";
import Avatar from "react-avatar";

export default function CompanyEmployeesValidateCreateModal({
  attitudes,
  employee,
  company,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);
  const [attitudesRatings, setAttitudesRatings] = useState({});
  const [inputObjective, setInputObjective] = useState("");

  useEffect(() => {
    if (!showModal) return;

    const updateAttitudeRatings = async () => {
      for (const attitudeId of selectedAttitudes) {
        if (!attitudesRatings.hasOwnProperty(attitudeId)) {
          await new Promise((resolve) => setTimeout(resolve, 0)); // Pequeña pausa para permitir que el estado se actualice
          setAttitudesRatings((prevRatings) => ({
            ...prevRatings,
            [attitudeId]: 0,
          }));
        }
      }
    };

    const updateObjectives = async () => {
      // Utilizar una variable local para los objetivos
      const newObjectives = [...formData.objectives];

      for (const objective of newObjectives) {
        if (!formData.objectives.hasOwnProperty(objective)) {
          await new Promise((resolve) => setTimeout(resolve, 0)); // Pequeña pausa para permitir que el estado se actualice
          setFormData((prevData) => ({
            ...prevData,
            [objective]: 0,
          }));
        }
      }
    };

    updateAttitudeRatings();
    updateObjectives();
  }, [showModal, selectedAttitudes, attitudesRatings]);

  useEffect(() => {
    // Actualizar el estado formData cuando attitudesRatings cambia
    setFormData((prevData) => ({
      ...prevData,
      attitudesRating: Object.entries(attitudesRatings).map(
        ([attitudeId, rating]) => ({
          attitudeId,
          rating: parseInt(rating),
        })
      ),
    }));
  }, [attitudesRatings]);

  const handleSave = async () => {
    const attitudesRatingArray = Object.entries(attitudesRatings).map(
      ([attitudeId, rating]) => ({
        attitudeId,
        rating: parseInt(rating),
      })
    );

    setFormData((prevData) => ({
      ...prevData,
      attitudesRating: attitudesRatingArray,
    }));

    let res;

    try {
      res = await createCompanyValidateEmployee(company?.id, formData);
      if (res.status === 200) {
        toast.success("Calificación del empleado creada con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const handleAttitudeRatingChange = (attitudeId, rating) => {
    setAttitudesRatings((prevRatings) => ({
      ...prevRatings,
      [attitudeId]: rating,
    }));
  };

  const [formData, setFormData] = useState({
    comment: "",
    attitudesRating: [],
    objectives: [],
    employeeId: employee.employee?.id,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedAttitudes") {
      const attitudeId = value;
      setSelectedAttitudes((prevAttitudes) => {
        if (prevAttitudes.includes(attitudeId)) {
          return prevAttitudes;
        }
        return [...prevAttitudes, attitudeId];
      });
    } else if (name === "objectives") {
      setInputObjective(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) =>
      prevAttitudes.filter((id) => id !== attitudeId)
    );

    setAttitudesRatings((prevRatings) => {
      const updatedRatings = { ...prevRatings };
      delete updatedRatings[attitudeId];
      return updatedRatings;
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleAddObjective = () => {
    if (inputObjective) {
      if (!formData.objectives.includes(inputObjective)) {
        setFormData((prevData) => ({
          ...prevData,
          objectives: [...prevData.objectives, inputObjective],
        }));
      }
      setInputObjective("");
    }
  };

  const handleRemoveObjective = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      objectives: prevData.objectives.filter((_, i) => i !== index),
    }));
  };

  return (
    <>
      <Button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Calificar
      </Button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Calificar a {employee.employee?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <div className="employee-container">
                  {employee?.image?.id !== null ? (
                    <img
                      src={`${BASE_URL}/images/${employee?.image?.id}`}
                      alt="Empleado"
                      className="employee-image"
                    />
                  ) : (
                    <Avatar
                      name={employee.employee.name}
                      color="#7800FF"
                      size="150"
                      round={true}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <label>
                  <strong>Comentario:</strong>
                </label>
                <textarea
                  rows={5}
                  value={formData.comment}
                  onChange={handleInputChange}
                  name="comment"
                />
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col>
              <div>
                <label>
                  <strong>Objetivo/s:</strong>
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    style={{ textAlign: "left", flex: 1 }}
                    placeholder="Añadir objetivo"
                    onChange={handleInputChange}
                    name="objectives"
                  />
                  <Button
                    size="sm"
                    style={{ backgroundColor: "#7800FF", borderRadius: "20px" }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      onClick={handleAddObjective}
                    />
                  </Button>
                </div>
                {/* Renderizar los objetivos agregados */}
                {formData.objectives.map((objective, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div style={{ flex: 1 }}>{objective}</div>
                    <Button
                      size="sm"
                      style={{
                        backgroundColor: "#7800FF",
                        borderRadius: "20px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        onClick={() => handleRemoveObjective(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </Button>
                  </div>
                ))}
              </div>
            </Col>
            <Col>
              <div>
                <label>
                  <strong>Actitud/es:</strong>
                </label>
                <div>
                  <select
                    value=""
                    name="selectedAttitudes"
                    onChange={(e) => {
                      const attitudeId = e.target.value;
                      setSelectedAttitudes((prevAttitudes) => {
                        if (prevAttitudes.includes(attitudeId)) {
                          return prevAttitudes;
                        }
                        return [...prevAttitudes, attitudeId];
                      });
                    }}
                    style={{ textAlign: "left" }}
                  >
                    <option value="" disabled>
                      -Agregar actitudes-
                    </option>
                    {attitudes.map((attitude) => (
                      <option key={attitude.id} value={attitude.id}>
                        {attitude.name} {} (+)
                      </option>
                    ))}
                  </select>
                  {selectedAttitudes.map((attitudeId) => {
                    const attitude = attitudes.find(
                      (attitude) => attitude.id === attitudeId
                    );
                    if (attitude) {
                      return (
                        <div key={attitude.id}>
                          <Badge
                            key={attitude.id}
                            className="badge-pill"
                            onClick={() => handleRemoveAttitude(attitudeId)}
                          >
                            {attitude.name}
                          </Badge>
                          <AttitudeRatingStar
                            rating={attitudesRatings[attitudeId]}
                            onChange={(rating) =>
                              handleAttitudeRatingChange(attitudeId, rating)
                            }
                          />
                          <hr></hr>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Crear
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
