import React from "react";
import "./Carousel.css";
import { Carousel } from "react-bootstrap";
import image1 from "../../../assets/worker level elemento04.png";
import image3 from "../../../assets/worker level elemento06.png";
import image4 from "../../../assets/worker level elemento08.png";

function CarouselHome() {
  return (
    <div className="carousel-container">
      <Carousel className="carousel">
        <Carousel.Item>
          <div className="carousel-item-container-2">
            <div className="row">
              <div className="col-md-6 ">
                <div className="carousel-text">
                  <h1 className="title" style={{ color: "white" }}>
                    Publica <br></br> tú oferta <br></br>de empleo
                  </h1>
                  <div>
                    <a href="/signin">
                      <button
                        style={{ backgroundColor: "#4EA301" }}
                        className="btn btn-primary"
                      >
                        Ingresar
                      </button>
                    </a>
                    <button
                      style={{ backgroundColor: "#C101FA" }}
                      className="btn btn-primary"
                    >
                      Publicar
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img className="d-block w-100" src={image1} alt="First slide" />
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-item-container-3">
            <div className="row">
              <div className="col-md-6 ">
                <div className="carousel-text">
                  <h1 className="title" style={{ color: "white" }}>
                    Tú empleo <br></br>ideal
                  </h1>
                  <div>
                    <a href="/signin">
                      <button
                        style={{ backgroundColor: "#7800FF" }}
                        className="btn btn-primary"
                      >
                        Ingresar
                      </button>
                    </a>
                    <button
                      style={{ backgroundColor: "#C101FA" }}
                      className="btn btn-primary"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img className="d-block w-100" src={image3} alt="3 slide" />
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-item-container-4">
            <div className="row">
              <div className="col-md-6 ">
                <div className="carousel-text">
                  <h1 className="title" style={{ color: "white" }}>
                    Recibe <br></br>calificaciones
                  </h1>
                  <div>
                    <a href="/signin">
                      <button
                        style={{ backgroundColor: "#7800FF" }}
                        className="btn btn-primary"
                      >
                        Ingresar
                      </button>
                    </a>
                    <button
                      style={{ backgroundColor: "#06C798" }}
                      className="btn btn-primary"
                    >
                      Calificar
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img className="d-block w-100" src={image4} alt="3 slide" />
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselHome;
