import React, { useEffect, useState } from "react";
import "../EmployeeProfile.css";
import { Badge, Col, Row } from "react-bootstrap";
import { BASE_URL } from "../../../utils/endpoints/urls";
import Avatar from "react-avatar";
import { getCompaniesReduced } from "../../../utils/company/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getJobCategories } from "../../../utils/job-category/api";
import { getAttitudes } from "../../../utils/attitudes/api";
import { updateEmployeeSummary } from "../../../utils/employee-summary/api";
import RatingStars from "../RatingStars/RatingStars";

export default function EditEmployeeSummaryForm({ user, employeeSummary }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newImageSrc, setNewImageSrc] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [attitudes, setAttitudes] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedJobCategories, setSelectedJobCategories] = useState([]);
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (companies.length === 0) {
          response = await getCompaniesReduced();
          setCompanies(response.data);
        }

        if (jobCategories.length === 0) {
          response = await getJobCategories();
          setJobCategories(response.data);
        }

        if (attitudes.length === 0) {
          response = await getAttitudes();
          setAttitudes(response.data);
        }

        setFormData((prevData) => ({
          ...prevData,
          attitudes: selectedAttitudes,
          companies: selectedCompanies,
          jobCategories: selectedJobCategories,
        }));
      } catch (error) {}
    };

    if (
      companies.length === 0 ||
      jobCategories.length === 0 ||
      attitudes.length === 0
    ) {
      fetchData();
    }
  }, [selectedAttitudes, selectedCompanies, selectedJobCategories]);

  useEffect(() => {
    if (!isEditing && employeeSummary) {
      setSelectedCompanies(
        employeeSummary.companies.map((company) => company.id)
      );
      setSelectedJobCategories(
        employeeSummary.jobCategories.map((jobCategory) => jobCategory.id)
      );
      setSelectedAttitudes(
        employeeSummary.attitudes.map((attitude) => attitude.id)
      );
    }
  }, [employeeSummary]);

  const [formData, setFormData] = useState({
    companies: employeeSummary?.companies.map((company) => company.id),
    courses: employeeSummary?.courses.map((course) => course.id),
    attitudes: employeeSummary?.attitudes.map((attitude) => attitude.id),
    jobCategories: employeeSummary?.jobCategories.map(
      (jobCategory) => jobCategory.id
    ),
    openToWork: employeeSummary?.openToWork,
    file: null,
  });

  let imageSrc;
  if (user?.imageId) {
    imageSrc = `${BASE_URL}/images/${user.imageId}`;
  } else {
    imageSrc = null;
  }

  const handleImageChange = (event) => {
    handleEdit();

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewImageSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);

    if (employeeSummary) {
      setFormData({
        companies:
          selectedCompanies ||
          employeeSummary?.companies.map((company) => company.id),
        courses: employeeSummary?.courses.map((course) => course.id),
        attitudes:
          selectedAttitudes ||
          employeeSummary?.attitudes.map((attitude) => attitude.id),
        jobCategories:
          selectedJobCategories ||
          employeeSummary?.jobCategories.map((jobCategory) => jobCategory.id),
        openToWork: employeeSummary?.openToWork,
        file: null,
      });
    }
  };

  const handleRemoveCompany = (companyId) => {
    setSelectedCompanies((prevCompanies) =>
      prevCompanies.filter((id) => id !== companyId)
    );
  };

  const handleRemoveJobCategory = (jobCategoryId) => {
    setSelectedJobCategories((prevJobCategories) =>
      prevJobCategories.filter((id) => id !== jobCategoryId)
    );
  };

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) =>
      prevAttitudes.filter((id) => id !== attitudeId)
    );
  };

  const handleAddAttitude = (e) => {
    const attitudeId = e.target.value;
    setSelectedAttitudes((prevAttitudes) => {
      if (prevAttitudes.includes(attitudeId)) {
        return prevAttitudes;
      }
      return [...prevAttitudes, attitudeId];
    });
  };

  const handleAddCompany = (e) => {
    const companyId = e.target.value;
    setSelectedCompanies((prevCompanies) => {
      if (prevCompanies.includes(companyId)) {
        return prevCompanies;
      }
      return [...prevCompanies, companyId];
    });
  };

  const handleAddJobCategory = (e) => {
    const jobCategoryId = e.target.value;
    setSelectedJobCategories((prevJobCategories) => {
      if (prevJobCategories.includes(jobCategoryId)) {
        return prevJobCategories;
      }
      return [...prevJobCategories, jobCategoryId];
    });
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      formData.companies = selectedCompanies;
      formData.jobCategories = selectedJobCategories;
      formData.attitudes = selectedAttitudes;
      let res = await updateEmployeeSummary(formData, user.id);
      if (res.status === 200) {
        toast.success("Resumen editado con éxito");
        window.location.reload();
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      toast.error("Ocurrío un error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="profile-field">
        <h3 className="benefits-h1">Mi resumen</h3>
        <p>
          Para cambiar los datos, haga click en Editar. Haciendo click en los
          campos podrá agregar elementos y seleccionando cada palabra resaltada,
          los quitará.
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <RatingStars rating={employeeSummary?.averageQualification} />
            <h6 className="benefits-h1">
              Calificación promedio: {employeeSummary?.averageQualification}
            </h6>
          </div>
        </div>
        <div className="profile-picture">
          {imageSrc ? (
            <img className="avatar-image" src={imageSrc} alt="User Avatar" />
          ) : (
            <Avatar name={user?.name} color="#7800FF" size="50" round={true} />
          )}
        </div>
        <Row>
          <Col>
            {user?.role === "EMPLOYEE" && (
              <div className="badge-select">
                <label>Empresa/s en las que estoy trabajando ahora:</label>
                {!isEditing ? (
                  <div>
                    {employeeSummary?.companies.map((company) => (
                      <Badge key={company.id} className="badge-pill">
                        {company.name}
                      </Badge>
                    ))}
                  </div>
                ) : (
                  <div>
                    {selectedCompanies.map((companyId) => {
                      const company = companies.find(
                        (company) => company.id === companyId
                      );
                      if (company) {
                        return (
                          <Badge
                            key={company.id}
                            className="badge-pill"
                            onClick={() => handleRemoveCompany(companyId)}
                          >
                            {company.name}
                          </Badge>
                        );
                      }
                      return null;
                    })}
                    <select
                      value=""
                      name="selectedCompanies"
                      onChange={handleAddCompany}
                    >
                      <option value="" disabled>
                        -- Agregar empresa --
                      </option>
                      {companies.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col>
            <div className="badge-select">
              <label>
                {user?.role === "EMPLOYEE"
                  ? "Rubros para mis busquedas"
                  : "Rubros para mis servicios"}
              </label>{" "}
              {!isEditing ? (
                <div>
                  {employeeSummary?.jobCategories.map((jobCategory) => (
                    <Badge key={jobCategory.id} className="badge-pill">
                      {jobCategory.name}
                    </Badge>
                  ))}
                </div>
              ) : (
                <div>
                  {selectedJobCategories.map((jobCategoryId) => {
                    const jobCategory = jobCategories.find(
                      (jobCategory) => jobCategory.id === jobCategoryId
                    );
                    if (jobCategory) {
                      return (
                        <Badge
                          key={jobCategory.id}
                          className="badge-pill"
                          onClick={() => handleRemoveJobCategory(jobCategoryId)}
                        >
                          {jobCategory.name}
                        </Badge>
                      );
                    }
                    return null;
                  })}
                  <select
                    value=""
                    name="selectedJobCategories"
                    onChange={handleAddJobCategory}
                  >
                    <option value="" disabled>
                      -- Agregar rubro --
                    </option>
                    {jobCategories.map((jobCategory) => (
                      <option key={jobCategory.id} value={jobCategory.id}>
                        {jobCategory.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <div className="badge-select">
          <label>Actitudes que poseo:</label>
          {!isEditing ? (
            <div>
              {employeeSummary?.attitudes.map((attitude) => (
                <Badge key={attitude.id} className="badge-pill">
                  {attitude.name}
                </Badge>
              ))}
            </div>
          ) : (
            <div>
              {selectedAttitudes.map((attitudeId) => {
                const attitude = attitudes.find(
                  (attitude) => attitude.id === attitudeId
                );
                if (attitude) {
                  return (
                    <Badge
                      key={attitude.id}
                      className="badge-pill"
                      onClick={() => handleRemoveAttitude(attitudeId)}
                    >
                      {attitude.name}
                    </Badge>
                  );
                }
                return null;
              })}
              <select
                value=""
                name="selectedAttitudes"
                onChange={handleAddAttitude}
              >
                <option value="" disabled>
                  -- Agregar actitud --
                </option>
                {attitudes.map((attitude) => (
                  <option key={attitude.id} value={attitude.id}>
                    {attitude.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div>
          <input
            style={{ marginTop: "15px", marginBottom: "15px" }}
            type="checkbox"
            checked={
              isEditing ? formData.openToWork : employeeSummary?.openToWork
            }
            name="openToWork"
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                openToWork: e.target.checked,
              }))
            }
          />
          <label>&nbsp;Tengo intereses en buscar un nuevo empleo</label>
        </div>
      </div>
      <div>
        <button
          style={{ backgroundColor: "#7800ff", marginBottom: "15px" }}
          onClick={handleEdit}
        >
          Editar
        </button>
        <label
          onClick={() => {
            setIsEditing(true);
          }}
          htmlFor="fileInput"
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            cursor: "pointer",
            backgroundColor: "#06C798",
            color: "white",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          Cambiar foto
        </label>
        <input
          id="fileInput"
          style={{ display: "none" }}
          type="file"
          onChange={handleImageChange}
          accept="image/*"
        />
        <label>(máx. 5MB, formato: jpg, jpeg, png)</label>
        {isEditing && (
          <button
            style={{ marginLeft: "15px" }}
            className="send-button"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            {isSubmitting ? "Guardando..." : "Guardar"}
          </button>
        )}
      </div>
    </div>
  );
}
