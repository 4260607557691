import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyJobOffer/CompanyJobOffer.css";
import { Container, Table } from "react-bootstrap";
import { disableJobOffer } from "../../utils/job-offers/api";
import moment from "moment/moment";
import { getJobCategories } from "../../utils/job-category/api";
import { getAttitudes } from "../../utils/attitudes/api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  disableFreelanceApplication,
  getFreelanceApplicationsByEmployee,
} from "../../utils/freelance/api";
import FreelanceServicesCreateModal from "./FreelanceServicesCreateModal/FreelanceServicesCreateModal";
import FreelanceServicesDetailModal from "./FreelanceServicesCreateModal/FreelanceServicesDetailModal/FreelanceServicesDetailModal";

export default function FreelanceServices({ user }) {
  const [services, setServices] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [attitudes, setAttitudes] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getFreelanceApplicationsByEmployee(
          user?.employeeSummaryId
        );
        setServices(response.data);

        response = await getJobCategories();
        setJobCategories(response.data);

        response = await getAttitudes();
        setAttitudes(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [user?.employeeSummaryId]);

  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleToggleJobOffer = (offerId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deshabilitar" : "habilitar"
      } la publicación de servicio?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await disableFreelanceApplication(offerId);
              if (response.status === 200) {
                toast.success(
                  `Publicación de servicio ${
                    active ? "deshabilitada" : "habilitada"
                  } con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${
                  active ? "deshabilitar" : "habilitar"
                } la publicación de servicio`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  if (user?.role !== "FREELANCE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Publicar servicios</h4>
          <label>
            Próximamente se permitirán agregar más servicios mediante
            suscripción de membresia.
          </label>
          <div className="table-container">
            {services === null ||
              (services.length === 0 && (
                <FreelanceServicesCreateModal
                  jobCategories={jobCategories}
                  attitudes={attitudes}
                  handleClose={handleCreateModalClose}
                />
              ))}
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Título</th>
                  <th>Categoría</th>
                  <th>Fecha de creación</th>
                  <th>Activa</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {services.map((offer) => (
                  <tr key={offer.id}>
                    <td>{offer.code}</td>
                    <td>{offer.title}</td>
                    <td>{offer.jobCategory.name}</td>
                    <td>{moment(offer.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      <button
                        className={`toggle-button ${
                          offer.active ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleToggleJobOffer(offer.id, offer.active)
                        }
                      >
                        {offer.active ? "Habilitada" : "Deshabilitada"}
                      </button>
                    </td>
                    <td>
                      <FreelanceServicesDetailModal
                        service={offer}
                        jobCategories={jobCategories}
                        attitudes={attitudes}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </div>
  );
}
