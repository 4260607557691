import React, { useState } from "react";
import "./WelcomeMessage.css";
import emoji from "../../../assets/workerlevel elemento 46.png";

const WelcomeMessage = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMessage = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        className={`welcome-message ${isOpen ? "open" : ""}`}
        style={{
          backgroundColor: "#D8DFE4",
          color: "#300064",
          borderRadius: "10px",
        }}
        onClick={toggleMessage}
      >
        <div className="header">
          Buenas tardes {user?.name}
          <span className="arrow">{isOpen ? "▲" : "▼"}</span>
        </div>
        {isOpen && (
          <div
            className="content"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>¡Que tengas un día lleno de éxitos!</span>
            <img
              src={emoji}
              alt="emoji"
              style={{ width: "50px", height: "50px" }}
            />
          </div>
        )}
      </div>
      <p>
        Plataforma optimizada para{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.google.com/chrome/"
        >
          Google Chrome
        </a>
      </p>
      {user?.role === "EMPLOYEE" && (
        <p>
          Recuerde completar los datos de Mi Perfil, Titulo y Cursos y Mis
          Experiencias antes de buscar empleo.
        </p>
      )}
      {user?.role === "FREELANCE" && (
        <p>
          Recuerde completar los datos de Mi Perfil, Titulo y Cursos y Mis
          Experiencias antes de crear una oferta de trabajo.
        </p>
      )}
    </div>
  );
};

export default WelcomeMessage;
