import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const createCompanyQualification = async (companyId, data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/company-qualification/${companyId}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};
