import React, { useEffect, useState } from "react";
import "../EmployeeProfile.css";
import { Badge } from "react-bootstrap";
import { BASE_URL } from "../../../utils/endpoints/urls";
import Avatar from "react-avatar";
import { getCompaniesReduced } from "../../../utils/company/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getJobCategories } from "../../../utils/job-category/api";
import { getAttitudes } from "../../../utils/attitudes/api";
import { createEmployeeSummary } from "../../../utils/employee-summary/api";

export default function CreateEmployeeSummaryForm({ user }) {
  const [newImageSrc, setNewImageSrc] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [selectedJobCategories, setSelectedJobCategories] = useState([]);
  const [attitudes, setAttitudes] = useState([]);
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (companies.length === 0) {
          response = await getCompaniesReduced();
          setCompanies(response.data);
        }

        if (jobCategories.length === 0) {
          response = await getJobCategories();
          setJobCategories(response.data);
        }

        if (attitudes.length === 0) {
          response = await getAttitudes();
          setAttitudes(response.data);
        }

        setFormData((prevData) => ({
          ...prevData,
          attitudes: selectedAttitudes,
          companies: selectedCompanies,
          jobCategories: selectedJobCategories,
        }));
      } catch (error) {}
    };

    fetchData();
  }, [selectedCompanies, selectedJobCategories, selectedAttitudes]);

  const [formData, setFormData] = useState({
    companies: [],
    courses: [],
    attitudes: [],
    jobCategories: [],
    openToWork: false,
    file: null,
  });

  let imageSrc;
  if (user?.imageId) {
    imageSrc = `${BASE_URL}/images/${user.imageId}`;
  } else {
    imageSrc = null;
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewImageSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveCompany = (companyId) => {
    setSelectedCompanies((prevCompanies) =>
      prevCompanies.filter((id) => id !== companyId)
    );
  };

  const handleRemoveJobCategory = (jobCategoryId) => {
    setSelectedJobCategories((prevJobCategories) =>
      prevJobCategories.filter((id) => id !== jobCategoryId)
    );
  };

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) =>
      prevAttitudes.filter((id) => id !== attitudeId)
    );
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      let res = await createEmployeeSummary(formData);
      if (res.status === 200) {
        toast.success("Resumen creado con éxito");
        window.location.reload();
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      toast.error("Ocurrío un error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div className="profile-field">
        <h3 className="benefits-h1">Crear resumen</h3>
        <p>
          Haciendo click en los campos podrá agregar elementos y seleccionando
          cada palabra resaltada, los quitará.
        </p>
        <div className="profile-picture">
          {imageSrc ? (
            <img className="avatar-image" src={imageSrc} alt="User Avatar" />
          ) : (
            <Avatar name={user?.name} color="#7800FF" size="50" round={true} />
          )}
          <label
            htmlFor="fileInput"
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              cursor: "pointer",
              backgroundColor: "#4EA301",
              color: "white",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            Cambiar foto
          </label>
          <input
            id="fileInput"
            style={{ display: "none" }}
            type="file"
            onChange={handleImageChange}
            accept="image/*"
          />
          <label>(máx. 5MB, formato: jpg, jpeg, png)</label>
        </div>
        <div className="badge-select">
          {user?.role === "EMPLOYEE" && (
            <div>
              <label>Empresa/s en las que estoy trabajando ahora:</label>
              <div>
                {selectedCompanies.map((companyId) => {
                  const company = companies.find(
                    (company) => company.id === companyId
                  );
                  if (company) {
                    return (
                      <Badge
                        key={company.id}
                        className="badge-pill"
                        onClick={() => handleRemoveCompany(companyId)}
                      >
                        {company.name}
                      </Badge>
                    );
                  }
                  return null;
                })}
                <select
                  value=""
                  name="selectedCompanies"
                  onChange={(e) => {
                    const companyId = e.target.value;
                    setSelectedCompanies((prevCompanies) => {
                      if (prevCompanies.includes(companyId)) {
                        return prevCompanies;
                      }
                      return [...prevCompanies, companyId];
                    });
                  }}
                >
                  <option value="" disabled>
                    -- Agregar empresa --
                  </option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className="badge-select">
          <label>
            {user?.role === "EMPLOYEE"
              ? "Rubros para mis busquedas"
              : "Rubros para mis servicios"}
          </label>
          <div>
            {selectedJobCategories.map((jobCategoryId) => {
              const jobCategory = jobCategories.find(
                (jobCategory) => jobCategory.id === jobCategoryId
              );
              if (jobCategory) {
                return (
                  <Badge
                    key={jobCategory.id}
                    className="badge-pill"
                    onClick={() => handleRemoveJobCategory(jobCategoryId)}
                  >
                    {jobCategory.name}
                  </Badge>
                );
              }
              return null;
            })}
            <select
              value=""
              name="selectedJobCategories"
              onChange={(e) => {
                const jobCategoryId = e.target.value;
                setSelectedJobCategories((prevJobCategories) => {
                  if (prevJobCategories.includes(jobCategoryId)) {
                    return prevJobCategories;
                  }
                  return [...prevJobCategories, jobCategoryId];
                });
              }}
            >
              <option value="" disabled>
                -- Agregar rubro --
              </option>
              {jobCategories.map((jobCategory) => (
                <option key={jobCategory.id} value={jobCategory.id}>
                  {jobCategory.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="badge-select">
          <label>Actitudes que poseo:</label>
          <div>
            {selectedAttitudes.map((attitudeId) => {
              const attitude = attitudes.find(
                (attitude) => attitude.id === attitudeId
              );
              if (attitude) {
                return (
                  <Badge
                    key={attitude.id}
                    className="badge-pill"
                    onClick={() => handleRemoveAttitude(attitudeId)}
                  >
                    {attitude.name}
                  </Badge>
                );
              }
              return null;
            })}
            <select
              value=""
              name="selectedAttitudes"
              onChange={(e) => {
                const attitudeId = e.target.value;
                setSelectedAttitudes((prevAttitudes) => {
                  if (prevAttitudes.includes(attitudeId)) {
                    return prevAttitudes;
                  }
                  return [...prevAttitudes, attitudeId];
                });
              }}
            >
              <option value="" disabled>
                -- Agregar actitud --
              </option>
              {attitudes.map((attitude) => (
                <option key={attitude.id} value={attitude.id}>
                  {attitude.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div>
        <input
          type="checkbox"
          name="openToWork"
          onChange={(e) =>
            setFormData((prevData) => ({
              ...prevData,
              openToWork: e.target.checked,
            }))
          }
        />
        <label>&nbsp;Tengo intereses en buscar un nuevo empleo</label>
      </div>
      <div>
        <button
          className="send-button"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting ? "Guardando..." : "Guardar"}
        </button>
      </div>
    </div>
  );
}
