import React, { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUser,
  faNewspaper,
  faMedal,
  faSearch,
  faLuggageCart,
  faQrcode,
  faPeopleArrows,
  faSuitcase,
  faNetworkWired,
  faCertificate,
  faThumbsUp,
  faComments,
  faChalkboardTeacher,
  faUserShield,
  faChartBar,
  faHandshake,
  faBriefcase,
  faCog,
  faStar,
  faBug,
  faRightFromBracket,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

export default function Sidebar({ user }) {
  const userRole = user?.role;

  const logOut = () => {
    localStorage.removeItem("jwtToken");
    window.location.href = "/";
  };

  const Sidebar = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        setIsLoading(false);
      };

      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getRoleMenuItems = (role) => {
      switch (role) {
        case "COMPANY":
          return (
            <>
              <MenuItem>
                <NavLink to={"/company-profile"}>
                  <FontAwesomeIcon icon={faUser} className="menu-icon" />
                  &nbsp; Mi perfil
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-employees"}>
                  <FontAwesomeIcon icon={faUsers} className="menu-icon" />
                  &nbsp; Empleados
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-postulates"}>
                  <FontAwesomeIcon icon={faNewspaper} className="menu-icon" />
                  &nbsp; Interesados
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-membership"}>
                  <FontAwesomeIcon icon={faMedal} className="menu-icon" />
                  &nbsp; Membresía
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-job-offer"}>
                  <FontAwesomeIcon icon={faSearch} className="menu-icon" />
                  &nbsp; Publicaciones
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-prospect"}>
                  <FontAwesomeIcon
                    icon={faUsersViewfinder}
                    className="menu-icon"
                  />
                  &nbsp; Capital Humano
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-qr"}>
                  <FontAwesomeIcon icon={faQrcode} className="menu-icon" />
                  &nbsp; QR
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/company-qualification"}>
                  <FontAwesomeIcon icon={faStar} className="menu-icon" />
                  &nbsp; Calificaciones
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/comments"}>
                  <FontAwesomeIcon icon={faComments} className="menu-icon" />
                  &nbsp; Comentarios
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/faq"}>
                  <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                  &nbsp; Ayuda
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/reportar-error"}>
                  <FontAwesomeIcon icon={faBug} className="menu-icon" />
                  &nbsp; Reportar error
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/#"} onClick={() => logOut()}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="menu-icon"
                  />
                  &nbsp; Cerrar sesión
                </NavLink>
              </MenuItem>
            </>
          );
        case "OWNER":
          return (
            <>
              <MenuItem>
                <NavLink to={"/owner-profile"}>
                  <FontAwesomeIcon icon={faUser} className="menu-icon" />
                  &nbsp; Mi perfil
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/attitudes-management"}>
                  <FontAwesomeIcon icon={faThumbsUp} className="menu-icon" />
                  &nbsp; Actitudes
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/job-categories-management"}>
                  <FontAwesomeIcon icon={faBriefcase} className="menu-icon" />
                  &nbsp; Rubros de trabajo
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/comment-management"}>
                  <FontAwesomeIcon icon={faComments} className="menu-icon" />
                  &nbsp; Comentarios
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/recommended-courses-management"}>
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    className="menu-icon"
                  />
                  &nbsp; Cursos recomendados
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/membership-management"}>
                  <FontAwesomeIcon icon={faUserShield} className="menu-icon" />
                  &nbsp; Membresías
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/reports-dashboard"}>
                  <FontAwesomeIcon icon={faChartBar} className="menu-icon" />
                  &nbsp; Reportes
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/user-management"}>
                  <FontAwesomeIcon icon={faUsers} className="menu-icon" />
                  &nbsp; Usuarios
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/sponsors-management"}>
                  <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                  &nbsp; Sponsors
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/global-parameters-management"}>
                  <FontAwesomeIcon icon={faCog} className="menu-icon" />
                  &nbsp; Parametros globales
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/faq"}>
                  <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                  &nbsp; Ayuda
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/reportar-error"}>
                  <FontAwesomeIcon icon={faBug} className="menu-icon" />
                  &nbsp; Reportar error
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/#"} onClick={() => logOut()}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="menu-icon"
                  />
                  &nbsp; Cerrar sesión
                </NavLink>
              </MenuItem>
            </>
          );
        case "EMPLOYEE":
          return (
            <>
              <MenuItem>
                <NavLink to={"/employee-profile"}>
                  <FontAwesomeIcon icon={faUser} className="menu-icon" />
                  &nbsp; Mi perfil
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-courses"}>
                  <FontAwesomeIcon icon={faCertificate} className="menu-icon" />
                  &nbsp; Titulos y Cursos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-experiences"}>
                  <FontAwesomeIcon
                    icon={faPeopleArrows}
                    className="menu-icon"
                  />
                  &nbsp; Mis experiencias
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-qualification"}>
                  <FontAwesomeIcon icon={faStar} className="menu-icon" />
                  &nbsp; Calificaciones
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-job-offer"}>
                  <FontAwesomeIcon
                    icon={faNetworkWired}
                    className="menu-icon"
                  />
                  &nbsp; Buscar empleo
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-applications"}>
                  <FontAwesomeIcon icon={faSuitcase} className="menu-icon" />
                  &nbsp; Mis postulaciones
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-recommended-courses"}>
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    className="menu-icon"
                  />
                  &nbsp; Ver cursos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/comments"}>
                  <FontAwesomeIcon icon={faComments} className="menu-icon" />
                  &nbsp; Comentarios
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/faq"}>
                  <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                  &nbsp; Ayuda
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/reportar-error"}>
                  <FontAwesomeIcon icon={faBug} className="menu-icon" />
                  &nbsp; Reportar error
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/#"} onClick={() => logOut()}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="menu-icon"
                  />
                  &nbsp; Cerrar sesión
                </NavLink>
              </MenuItem>
            </>
          );
        case "FREELANCE":
          return (
            <>
              <MenuItem>
                <NavLink to={"/freelance-profile"}>
                  <FontAwesomeIcon icon={faUser} className="menu-icon" />
                  &nbsp; Mi perfil
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-courses"}>
                  <FontAwesomeIcon icon={faCertificate} className="menu-icon" />
                  &nbsp; Titulos y Cursos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-experiences"}>
                  <FontAwesomeIcon
                    icon={faPeopleArrows}
                    className="menu-icon"
                  />
                  &nbsp; Mis experiencias
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/freelance-services"}>
                  <FontAwesomeIcon
                    icon={faNetworkWired}
                    className="menu-icon"
                  />
                  &nbsp; Publicar servicios
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/freelance-qr"}>
                  <FontAwesomeIcon icon={faQrcode} className="menu-icon" />
                  &nbsp; QR
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/freelance-qualification"}>
                  <FontAwesomeIcon icon={faStar} className="menu-icon" />
                  &nbsp; Calificaciones
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/employee-recommended-courses"}>
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    className="menu-icon"
                  />
                  &nbsp; Ver cursos
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/comments"}>
                  <FontAwesomeIcon icon={faComments} className="menu-icon" />
                  &nbsp; Comentarios
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/faq"}>
                  <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                  &nbsp; Ayuda
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/reportar-error"}>
                  <FontAwesomeIcon icon={faBug} className="menu-icon" />
                  &nbsp; Reportar error
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink to={"/#"} onClick={() => logOut()}>
                  <FontAwesomeIcon
                    icon={faRightFromBracket}
                    className="menu-icon"
                  />
                  &nbsp; Cerrar sesión
                </NavLink>
              </MenuItem>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <>
        {!isLoading && (!isMobile || showSidebar) && (
          <ProSidebar
            className={`sidebar""
            } custom-sidebar`}
            breakPoint="md"
          >
            <SidebarContent>
              <Menu>{getRoleMenuItems(userRole)}</Menu>
            </SidebarContent>
          </ProSidebar>
        )}
      </>
    );
  };

  return <Sidebar />;
}
