import React, { useEffect } from "react";
import CarouselHome from "./Carousel/Carousel";
import BenefitsCarousel from "./BenefitsCarousel/BenefitsCarousel";
import CommentContainer from "./CommentContainer/CommentContainer";
import Separator from "./Separator/Separator";
import SponsorContainer from "./Sponsors/SponsorContainer";
import WhiteStaticImage from "./WhiteStaticImage/WhiteStaticImage";
import WhiteStaticImage2 from "./WhiteStaticImage2/WhiteStaticImage2";
export default function Home() {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div>
      <WhiteStaticImage />
      <CarouselHome />
      <br></br>
      <br></br>
      <WhiteStaticImage2 />
      <SponsorContainer />
      <BenefitsCarousel />
      <CommentContainer />
      <Separator />
    </div>
  );
}
