import React from "react";
import image5 from "../../../assets/worker level elemento09.png";
import "./WhiteStaticImage2.css";
import { Container } from "react-bootstrap";

export default function WhiteStaticImage2() {
  return (
    <Container>
      <div className="static-item-container-5">
        <div>
          <img className="d-block w-100" src={image5} alt="3 slide" />
        </div>
        <div className="carousel-text-last">
          <h1 className="title">
            Descubre la plataforma que te dará las <br></br>claves para
            desarrollar tu potencial.
          </h1>
          <div className="carousel-btn-last">
            <a href="/signin">
              <button
                style={{ backgroundColor: "#7800FF" }}
                className="btn btn-primary"
              >
                Iniciar sesión
              </button>
            </a>
            <a href="/signup">
              <button
                style={{ backgroundColor: "#C101FA" }}
                className="btn btn-primary"
              >
                Registrarme
              </button>
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
}
