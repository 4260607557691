import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const createFreelanceQualification = async (employeeId, data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/employee-qualification/${employeeId}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllQualificationsByEmployee = async (employeeId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/employee-qualification/list/${employeeId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
