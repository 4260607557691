import axios from "axios";
import { BASE_URL } from "../urls";

export const logIn = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/users/login`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createUser = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/users/signup`, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getUser = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/users/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllUsers = async (page, size, cuilForSearch, name) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/users/all?page=${pageNumber}&size=${pageSize}`;

  if (cuilForSearch) {
    url += `&cuilForSearch=${cuilForSearch}`;
  }

  if (name) {
    url += `&name=${name}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyUser = async (verifyCode) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/users/verify/` + verifyCode + `/`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getExportCsv = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/users/export-csv`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/users/forgot_password`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableUser = async (userId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/users/${userId}/disable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateUser = async (data) => {
  try {
    const response = axios.put(`${BASE_URL}/users/update `, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const changeUserRol = async (userId, rol) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/users/${userId}/change-rol/${rol}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const resetPassword = async (data, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/users/reset_password/${token}`,
      data
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const changePassword = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/users/change_password/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
