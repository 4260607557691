import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Button, Container, Form, Table } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import {
  changeUserRol,
  deleteUser,
  disableUser,
  getAllUsers,
  getExportCsv,
} from "../../utils/endpoints/users/api";
import moment from "moment/moment";
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./UserManagement.css";
import { BASE_URL } from "../../utils/endpoints/urls";

export default function UserManagement({ user }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [filters, setFilters] = useState({
    cuilForSearch: "",
    name: "",
  });
  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getAllUsers(currentPage, pageSize);
        setUsers(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();
  }, [currentPage]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  const handleEditRole = (user) => {
    setEditingUserId(user.id);
    setSelectedRole(user.role);
  };

  const handleSaveRole = async (userId) => {
    console.log(userId, selectedRole);
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres cambiar el rol del usuario?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await changeUserRol(userId, selectedRole);
              if (response.status === 200) {
                toast.success(`Rol cambiado con éxito`);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(`Error al cambiar el rol`);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleToggleAttitude = (userId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deshabilitar" : "habilitar"
      } el usuario?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await disableUser(userId);
              if (response.status === 200) {
                toast.success(
                  `Usuario ${active ? "deshabilitado" : "habilitado"} con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${active ? "deshabilitar" : "habilitar"} el usuario`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteUser = (userId) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres eliminar el usuario? Esta acción no se puede deshacer.`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await deleteUser(userId);
              if (response.status === 200) {
                toast.success(`Usuario eliminado con éxito`);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(`Error al eliminar el usuario`);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSearch = async () => {
    try {
      const users = await getAllUsers(
        currentPage,
        pageSize,
        filters.cuilForSearch,
        filters.name
      );

      if (users && users.data) {
        setUsers(users.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      cuilForSearch: "",
      name: "",
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleExportCSV = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      };

      const response = await getExportCsv(headers);

      if (response.status === 200) {
        const dateFormat = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });

        const [
          { value: day },
          ,
          { value: month },
          ,
          { value: year },
          ,
          { value: hour },
          ,
          { value: minute },
          ,
          { value: second },
        ] = dateFormat.formatToParts(new Date());
        const formattedDate = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
        const fileName = `usuarios_export_${formattedDate}.xlsx`;

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Error exporting to Excel:", response.statusText);
      }
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  const roleTranslations = {
    OWNER: "PROPIETARIO",
    COMPANY: "EMPRESA",
    EMPLOYEE: "EMPLEADO",
    FREELANCE: "AUTONOMO",
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Gestión de Usuarios</h4>
          <hr></hr>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {currentPage + 1} de {totalPages}
            </span>
          </div>
          <div className="filters">
            <Form.Control
              type="text"
              name="cuilForSearch"
              value={filters.cuilForSearch}
              onChange={handleFilterChange}
              placeholder="Filtrar por CUIT/CUIL"
            />
            <Form.Control
              type="text"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
              placeholder="Filtrar por nombre"
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>CUIL/CUIT</th>
                  <th>Nombre</th>
                  <th>Rol</th>
                  <th>Email</th>
                  <th>Telefono</th>
                  <th>Fecha de registro</th>
                  <th>Estado</th>
                  <th>Empleados</th>
                  <th>Calificaciones</th>
                  <th>QR</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users?.map((userFromTable) => (
                  <tr key={userFromTable.id}>
                    <td>{userFromTable.cuil}</td>
                    <td>{userFromTable.name}</td>
                    <td>
                      {editingUserId === userFromTable.id ? (
                        <select
                          value={selectedRole}
                          onChange={(e) => setSelectedRole(e.target.value)}
                        >
                          <option value="PROPIETARIO">PROPIETARIO</option>
                          <option value="EMPRESA">EMPRESA</option>
                          <option value="EMPLEADO">EMPLEADO</option>
                          <option value="AUTONOMO">AUTONOMO</option>
                        </select>
                      ) : (
                        <span
                          className={`badge badge-${userFromTable.role.toLowerCase()}`}
                        >
                          {roleTranslations[userFromTable.role]}
                        </span>
                      )}{" "}
                    </td>
                    <td>{userFromTable.email}</td>
                    <td>{userFromTable.phone}</td>
                    <td>
                      {moment(userFromTable.registrationDate).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>
                      <button
                        className={`toggle-button ${
                          userFromTable.isEnabled ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleToggleAttitude(
                            userFromTable.id,
                            userFromTable.isEnabled
                          )
                        }
                      >
                        {userFromTable.isEnabled
                          ? "Habilitado"
                          : "Deshabilitado"}
                      </button>
                    </td>
                    <td>
                      {userFromTable.role === "COMPANY" &&
                        userFromTable?.companyId !== null && (
                          <a
                            href={`/company-employees/${userFromTable?.companyId}`}
                          >
                            <button className="toggle-button">Ver</button>
                          </a>
                        )}
                    </td>
                    <td>
                      {userFromTable.role === "COMPANY" &&
                        userFromTable?.companyId !== null && (
                          <a
                            href={`/company-qualification/owner/${userFromTable?.companyId}`}
                          >
                            <button className="toggle-button">Ver</button>
                          </a>
                        )}
                    </td>
                    <td>
                      {userFromTable.role === "COMPANY" &&
                        userFromTable?.companyId !== null && (
                          <a
                            href={`/company-qr/owner/${userFromTable?.companyId}`}
                          >
                            <button className="btn btn-link">
                              <FontAwesomeIcon icon={faEye} />
                            </button>
                          </a>
                        )}
                    </td>
                    <td>
                      {editingUserId === userFromTable.id ? (
                        <button
                          onClick={() => handleSaveRole(userFromTable.id)}
                        >
                          Guardar
                        </button>
                      ) : (
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={() => handleEditRole(userFromTable)}
                        />
                      )}{" "}
                    </td>
                    <td>
                      {userFromTable.role !== "OWNER" && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={() => handleDeleteUser(userFromTable.id)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
            <div style={{ textAlign: "right" }}>
              <button className="export-csv-button" onClick={handleExportCSV}>
                Exportar a CSV
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
