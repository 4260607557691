import React from "react";
import "./SponsorCard.css";
import { BASE_URL } from "../../../utils/endpoints/urls";

const SponsorCard = ({ sponsor }) => {
  return (
    <div className="sponsor-card">
      <img src={`${BASE_URL}/images/${sponsor.imageId}`} alt="sponsor" />
    </div>
  );
};

export default SponsorCard;
