// AttitudeRatingStar.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";

const AttitudeRatingStar = ({ rating, onChange, disabled }) => {
  const starIcons = Array.from({ length: 5 }, (_, index) => (
    <FontAwesomeIcon
      key={index}
      style={{
        color: index < rating ? "#FFC107" : "#E4E5E9",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      icon={index < rating ? solidStar : regularStar}
      onClick={() => !disabled && onChange(index + 1)}
    />
  ));

  return <div>{starIcons}</div>;
};

export default AttitudeRatingStar;
