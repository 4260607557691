import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faEdit, faEye, faSave } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { updateWorkExperience } from "../../../utils/work-experience/api";

export default function EmployeeWorkExperienceDetailModal({ workExperience }) {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const formattedStartDate = workExperience.startDate
    ? workExperience.startDate.substring(0, 10)
    : "";
  const formattedEndDate = workExperience.endDate
    ? workExperience.endDate.substring(0, 10)
    : "";

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
      company: workExperience.company,
      cuit: workExperience.cuit,
      position: workExperience.position,
      description: workExperience.description,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      active: workExperience.active,
    }));
  }, [showModal, workExperience]);

  const handleSave = async () => {
    let res;
    try {
      res = await updateWorkExperience(formData, workExperience.id);
      if (res.status === 200) {
        toast.success("Experiencia laboral actualizada con éxito!");
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    company: "",
    cuit: "",
    position: "",
    description: "",
    startDate: "",
    endDate: "",
    active: false,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
      endDate: checked ? "" : prevData.endDate,
    }));
  };

  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedDate,
    }));
  };

  const handleEndDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      endDate: formattedDate,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <>
      <button className="btn btn-link" onClick={handleOpen}>
        <FontAwesomeIcon icon={faEye} />
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Experiencia laboral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Compañía:</label>
                <input
                  value={formData.company}
                  type="text"
                  onChange={handleInputChange}
                  name="company"
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>CUIT:</label>
                <input
                  value={formData.cuit}
                  type="text"
                  onChange={handleInputChange}
                  name="cuit"
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>Puesto:</label>
                <input
                  value={formData.position}
                  type="text"
                  onChange={handleInputChange}
                  name="position"
                  disabled={!isEditing}
                />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  value={formData.startDate}
                  type="date"
                  onChange={handleInputChange}
                  name="startDate"
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>Fecha de fin:</label>
                <input
                  value={formData.endDate}
                  type="date"
                  onChange={handleInputChange}
                  name="endDate"
                  disabled={formData.active || !isEditing}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={formData.active}
                  name="active"
                  onChange={handleCheckboxChange}
                  disabled={!isEditing}
                />
                <label>&nbsp;Actualmente activo</label>
              </div>
            </Col>
            <div>
              <label>Descripción:</label>
              <textarea
                value={formData.description}
                style={{ textAlign: "left" }}
                rows={5}
                onChange={handleInputChange}
                name="description"
                disabled={!isEditing}
              ></textarea>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!isEditing ? (
            <Button variant="outline-primary" onClick={handleEdit}>
              <FontAwesomeIcon icon={faEdit} /> Editar
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
