import React, { useState, useEffect } from "react";
import video from "./assets/video/worker_level_elemento00.mp4";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./layouts/Navigation";
import Home from "./pages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SignUp from "./pages/SignUp/SignUp";
import Contact from "./pages/Contact/Contact";
import Footer from "./pages/Home/Footer/Footer";
import ReportError from "./pages/ReportError/ReportError";
import SignIn from "./pages/SignIn/SignIn";
import { Provider } from "react-redux";
import store from "./store";
import VerifyAccount from "./pages/VerifyAccount/VerifyAccount";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import styled from "styled-components";
import Faq from "./pages/Faq/Faq";
import { getUser } from "./utils/endpoints/users/api";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import CompanyPanel from "./pages/CompanyPanel/CompanyDashboard";
import CompanyProfile from "./pages/CompanyProfile/CompanyProfile";
import CompanyQR from "./pages/CompanyQR/CompanyQR";
import CompanyJobOffer from "./pages/CompanyJobOffer/CompanyJobOffer";
import CompanyMembership from "./pages/CompanyMembership/CompanyMembership";
import CompanyProspect from "./pages/CompanyProspect/CompanyProspect";
import CompanyEmployees from "./pages/CompanyEmployees/CompanyEmployees";
import CompanyPostulates from "./pages/CompanyPostulates/CompanyPostulates";
import EmployeeDashboard from "./pages/EmployeePanel/EmployeeDashboard";
import EmployeeProfile from "./pages/EmployeeProfile/EmployeeProfile";
import EmployeeExperiences from "./pages/EmployeeExperiences/EmployeeExperiences";
import EmployeeApplications from "./pages/EmployeeApplications/EmployeeApplications";
import EmployeeCourses from "./pages/EmployeeCourses/EmployeeCourses";
import EmployeeJobOffer from "./pages/EmployeeJobOffer/EmployeeJobOffer";
import EmployeeSummary from "./pages/EmployeeSummary/EmployeeSummary";
import OwnerProfile from "./pages/OwnerProfile/OwnerProfile";
import OwnerDashboard from "./pages/OwnerDashboard/OwnerDashboard";
import AttitudesManagement from "./pages/AttitudesManagement/AttitudesManagement";
import CommentManagement from "./pages/CommentManagement/CommentManagement";
import RecommendedCoursesManagement from "./pages/RecommendedCoursesManagement/RecommendedCoursesManagement";
import JobCategoriesManagement from "./pages/JobCategoriesManagement/JobCategoriesManagement";
import UserManagement from "./pages/UserManagement/UserManagement";
import MembershipManagement from "./pages/MembershipManagement/MembershipManagement";
import GlobalParametersManagement from "./pages/GlobalParametersManagement/GlobalParametersManagement";
import SponsorsManagement from "./pages/SponsorsManagement/SponsorsManagement";
import Comment from "./pages/Comment/Comment";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import ReportsDashboard from "./pages/ReportDashboard/ReportsDashboard";
import CompanyQualificationClient from "./pages/CompanyQualificationClient/CompanyQualificationClient";
import CompanyQualification from "./pages/CompanyQualification/CompanyQualification";
import EmployeeQualification from "./pages/EmployeeQualification/EmployeeQualification";
import EmployeeRecommendedCourses from "./pages/EmployeeRecommendedCourses/EmployeeRecommendedCourses";
import FreelanceDashboard from "./pages/FreelanceDashboard/FreelanceDashboard";
import CompanyEmployeesOwner from "./pages/CompanyEmployees/CompanyEmployeesOwner";
import CompanyQualificationOwner from "./pages/CompanyQualification/CompanyQualificationOwner";
import FreelanceQR from "./pages/FreelanceQR/FreelanceQR";
import FreelanceServices from "./pages/FreelanceServices/FreelanceServices";
import FreelanceQualificationClient from "./pages/FreelanceQualificationClient/FreelanceQualificationClient";
import FreelanceQualification from "./pages/FreelanceQualification/FreelanceQualification";
import CompanyQROwner from "./pages/CompanyQROwner/CompanyQROwner";
import FreelanceApplications from "./pages/FreelanceApplications/FreelanceApplications";
import FreelanceQualificationAll from "./pages/FreelanceQualificationAll/FreelanceQualificationAll";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1;
`;

function App() {
  const [videoEnded, setVideoEnded] = useState(true);
  const [contentHeight, setContentHeight] = useState("auto");
  const loggedIn = localStorage.getItem("jwtToken");
  const [user, setUser] = useState(null);

  useEffect(() => {
    document.documentElement.lang = "es";
    const fetchData = async () => {
      try {
        if (loggedIn) {
          const response = await getUser();
          setUser(response.data);
        }
      } catch (error) {}
    };

    fetchData();

    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const navbarHeight = document.querySelector("nav")?.offsetHeight || 0;
      const footerHeight = document.querySelector("footer")?.offsetHeight || 0;
      const contentHeight = windowHeight - navbarHeight - footerHeight;
      setContentHeight(`${contentHeight}px`);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [loggedIn]);

  const handleVideoEnd = () => {
    setVideoEnded(true);
  };

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      {!videoEnded && (
        <video
          className="video-intro"
          src={video}
          autoPlay
          muted
          onEnded={handleVideoEnd}
        />
      )}
      {videoEnded && (
        <Provider store={store}>
          <Router>
            <Wrapper>
              <Content style={{ minHeight: contentHeight }}>
                <div>
                  <Navigation user={user} />
                </div>
                <ToastContainer
                  className="toast-position"
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <div style={{ paddingTop: "5rem" }}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contacto" element={<Contact />} />
                    <Route path="/reportar-error" element={<ReportError />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/verify/:code" element={<VerifyAccount />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/forgot_password/:token"
                      element={<ResetPassword />}
                    />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route
                      path="/company-qualification/:companyId"
                      element={<CompanyQualificationClient />}
                    />
                    <Route
                      path="/freelance-qualification/:employeeId"
                      element={<FreelanceQualificationClient />}
                    />
                    <Route
                      path="/freelance-qualification/:freelanceId/all/freelance/:employeeId"
                      element={<FreelanceQualificationAll />}
                    />
                    <Route
                      path="/recommended-courses"
                      element={<EmployeeRecommendedCourses user={user} />}
                    />
                    <Route
                      path="/employee-job-offer"
                      element={<EmployeeJobOffer user={user} />}
                    />
                    <Route
                      path="/freelance-applications"
                      element={<FreelanceApplications user={user} />}
                    />
                    <Route element={<ProtectedRoutes />}>
                      <Route
                        path="/freelance-dashboard"
                        element={<FreelanceDashboard user={user} />}
                      />
                      <Route
                        path="/freelance-profile"
                        element={<EmployeeProfile user={user} />}
                      />
                      <Route
                        path="/freelance-qr"
                        element={<FreelanceQR user={user} />}
                      />
                      <Route
                        path="/freelance-services"
                        element={<FreelanceServices user={user} />}
                      />
                      <Route
                        path="/freelance-qualification"
                        element={<FreelanceQualification user={user} />}
                      />
                      <Route
                        path="/company-dashboard"
                        element={<CompanyPanel user={user} />}
                      />
                      <Route
                        path="/company-profile"
                        element={<CompanyProfile user={user} />}
                      />
                      <Route
                        path="/company-qr"
                        element={<CompanyQR user={user} />}
                      />
                      <Route
                        path="/company-job-offer"
                        element={<CompanyJobOffer user={user} />}
                      />
                      <Route
                        path="/company-membership"
                        element={<CompanyMembership user={user} />}
                      />
                      <Route
                        path="/company-prospect"
                        element={<CompanyProspect user={user} />}
                      />
                      <Route
                        path="/company-employees"
                        element={<CompanyEmployees user={user} />}
                      />
                      <Route
                        path="/company-employees/:companyId"
                        element={<CompanyEmployeesOwner user={user} />}
                      />
                      <Route
                        path="/company-qr/owner/:companyId"
                        element={<CompanyQROwner user={user} />}
                      />
                      <Route
                        path="/company-qualification"
                        element={<CompanyQualification user={user} />}
                      />
                      <Route
                        path="/company-qualification/owner/:companyId"
                        element={<CompanyQualificationOwner user={user} />}
                      />
                      <Route
                        path="/company-postulates"
                        element={<CompanyPostulates user={user} />}
                      />
                      <Route
                        path="/employee-dashboard"
                        element={<EmployeeDashboard user={user} />}
                      />
                      <Route
                        path="/employee-profile"
                        element={<EmployeeProfile user={user} />}
                      />
                      <Route
                        path="/employee-experiences"
                        element={<EmployeeExperiences user={user} />}
                      />
                      <Route
                        path="/employee-applications"
                        element={<EmployeeApplications user={user} />}
                      />
                      <Route
                        path="/employee-courses"
                        element={<EmployeeCourses user={user} />}
                      />
                      <Route
                        path="/employee-summary/:id"
                        element={<EmployeeSummary user={user} />}
                      />
                      <Route
                        path="/owner-profile"
                        element={<OwnerProfile user={user} />}
                      />
                      <Route
                        path="/owner-dashboard"
                        element={<OwnerDashboard user={user} />}
                      />
                      <Route
                        path="/attitudes-management"
                        element={<AttitudesManagement user={user} />}
                      />
                      <Route
                        path="/comment-management"
                        element={<CommentManagement user={user} />}
                      />
                      <Route
                        path="/recommended-courses-management"
                        element={<RecommendedCoursesManagement user={user} />}
                      />
                      <Route
                        path="/job-categories-management"
                        element={<JobCategoriesManagement user={user} />}
                      />
                      <Route
                        path="/user-management"
                        element={<UserManagement user={user} />}
                      />
                      <Route
                        path="/membership-management"
                        element={<MembershipManagement user={user} />}
                      />
                      <Route
                        path="/global-parameters-management"
                        element={<GlobalParametersManagement user={user} />}
                      />
                      <Route
                        path="/sponsors-management"
                        element={<SponsorsManagement user={user} />}
                      />
                      <Route
                        path="/comments"
                        element={<Comment user={user} />}
                      />
                      <Route
                        path="/employee-qualification"
                        element={<EmployeeQualification user={user} />}
                      />
                      <Route
                        path="/employee-recommended-courses"
                        element={<EmployeeRecommendedCourses user={user} />}
                      />
                      <Route
                        path="/change-password"
                        element={<ChangePassword user={user} />}
                      />
                      <Route
                        path="/reports-dashboard"
                        element={<ReportsDashboard user={user} />}
                      />
                    </Route>
                  </Routes>
                </div>
              </Content>
              <Footer style={{ position: "fixed" }} />
            </Wrapper>
          </Router>
        </Provider>
      )}
    </div>
  );
}

export default App;
