import React, { useState, useEffect } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { Col, Container, Row } from "react-bootstrap";
import {
  getCompaniesEntered,
  getEmployeesEntered,
  getFreelancersEntered,
  getMembershipsCloseToExpire,
  getUsersEntered,
} from "../../utils/reports-stats/api";
import CompaniesEnteredChart from "./CompaniesEnteredChart";
import UsersEnteredChart from "./UsersEnteredChart";
import MembershipCloseToExpireChart from "./MembershipsCloseToExpireChart";
import EmployeesEnteredChart from "./EmployeesEnteredChart";
import FreelancersEnteredChart from "./FreelancersEnteredChart";

export default function ReportsDashboard({ user }) {
  const [companiesEnteredData, setCompaniesEnteredData] = useState([]);
  const [usersEnteredData, setUsersEnteredData] = useState([]);
  const [employeesEnteredData, setEmployeesEnteredData] = useState([]);
  const [freelancersEnteredData, setFreelancersEnteredData] = useState([]);
  const [membershipsCloseToExpireData, setMembershipsCloseToExpireData] =
    useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCompaniesEntered();
        setCompaniesEnteredData(response.data);

        response = await getUsersEntered();
        setUsersEnteredData(response.data);

        response = await getEmployeesEntered();
        setEmployeesEnteredData(response.data);

        response = await getFreelancersEntered();
        setFreelancersEnteredData(response.data);

        response = await getMembershipsCloseToExpire();
        setMembershipsCloseToExpireData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  let totalCompaniesEntered = () => {
    let total = 0;
    for (let key in companiesEnteredData) {
      total += companiesEnteredData[key];
    }
    return total;
  };

  let totalEmployeesEntered = () => {
    let total = 0;
    for (let key in employeesEnteredData) {
      total += employeesEnteredData[key];
    }
    return total;
  };

  let totalFreelancersEntered = () => {
    let total = 0;
    for (let key in freelancersEnteredData) {
      total += freelancersEnteredData[key];
    }
    return total;
  };

  let totalMembershipsCloseToExpire = () => {
    let total = 0;
    const keys = Object.keys(membershipsCloseToExpireData);

    for (let i = 0; i < keys.length - 1; i++) {
      total += membershipsCloseToExpireData[keys[i]];
    }
    return total;
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Panel de Reportes</h4>
          <hr />
          <Row>
            <Col xs={12} md={4} lg={6}>
              <h5 className="benefits-h1">
                Empresas Ingresadas: total {totalCompaniesEntered()}
              </h5>{" "}
              <CompaniesEnteredChart companiesEntered={companiesEnteredData} />
            </Col>
            <Col xs={12} md={4} lg={6}>
              <h5 className="benefits-h1">
                Empleados Ingresados: total {totalEmployeesEntered()}
              </h5>{" "}
              <EmployeesEnteredChart employeesEntered={employeesEnteredData} />
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col xs={12} md={4} lg={6}>
              <h5 className="benefits-h1">
                Autónomos Ingresados: total {totalFreelancersEntered()}
              </h5>{" "}
              <FreelancersEnteredChart
                freelancersEntered={freelancersEnteredData}
              />
            </Col>
            <Col xs={12} md={4} lg={6}>
              <h5 className="benefits-h1">
                Cantidad de membresías próximas a expirar:{" "}
                {totalMembershipsCloseToExpire()}/
                {membershipsCloseToExpireData.TOTAL}
              </h5>{" "}
              <MembershipCloseToExpireChart
                membershipCloseToExpire={membershipsCloseToExpireData}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
