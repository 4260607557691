import React from 'react';
import moment from 'moment/moment';
import "./CommentCard.css"
import stars from "../../../assets/worker level elemento09.png"

const CommentCard = ({ comment }) => {
  return (
    <div className="comment-card">
      <h3>{comment.name}</h3>
      <h4>{comment.company}</h4>
      <p>{comment.comment}</p>
      {/* <p>{moment(comment.createdAt).format("DD-MM-YYYY")}</p> */}
      <img src={stars} alt="stars" />
    </div>
  );
};

export default CommentCard;
