import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { createJobCategory } from "../../utils/job-category/api";

export default function JobCategoriesCreateModal() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleSave = async () => {
    let res;
    try {
      res = await createJobCategory(formData);
      if (res.status === 200) {
        toast.success("Rubro de trabajo creado con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Agregar rubro de trabajo
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Agregar rubro de trabajo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Nombre:</label>
            <input type="text" onChange={handleInputChange} name="name" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Crear
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
