import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export default function MembershipCloseToExpireChart({
  membershipCloseToExpire,
}) {
  delete membershipCloseToExpire[
    Object.keys(membershipCloseToExpire)[
      Object.keys(membershipCloseToExpire).length - 1
    ]
  ];

  const data = {
    labels: Object.keys(membershipCloseToExpire),
    datasets: [
      {
        label: "Cantidad de membresías próximas a expirar",
        data: Object.values(membershipCloseToExpire),
        fill: false,
        backgroundColor: "rgb(120, 0, 255)",
        borderColor: "rgba(120, 0, 255, 0.2)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={data} options={options} />;
}
