import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const fetchEnabledRecommendedCourses = async (
  page,
  size,
  description,
  name
) => {
  let url = `${BASE_URL}/recommended-courses/enabled?page=${page}&size=${size}`;

  if (description) {
    url += `&description=${description}`;
  }

  if (name) {
    url += `&name=${name}`;
  }

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchAllRecommendedCourses = async (page, size) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/recommended-courses/all?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createRecommendedCourse = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/recommended-courses`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateRecommendedCourse = async (
  recommendedCourseId,
  formData
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/recommended-courses/${recommendedCourseId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableRecommendedCourse = async (recommendedCourseId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/recommended-courses/${recommendedCourseId}/disable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
