import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const createCompanyValidateEmployee = async (companyId, data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/company-validate-employee/${companyId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCompanyValidateEmployee = async (companyId, data) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/company-validate-employee/${companyId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyValidateEmployeesByCompanyId = async (companyId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company-validate-employee/company/${companyId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyValidateEmployeesByEmployeeId = async (employeeId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company-validate-employee/employee/${employeeId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
