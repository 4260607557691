import React, { useEffect, useState } from "react";
import "../CompanyQualificationClient/CompanyQualificationClient.css";
import "../CompanyQR/CompanyQR.css";
import { Badge, Col, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { getAttitudes } from "../../utils/attitudes/api";
import { toast } from "react-toastify";
import { getEmployeeSummary } from "../../utils/employee-summary/api";
import { BASE_URL } from "../../utils/endpoints/urls";
import Avatar from "react-avatar";
import StarRating from "../CompanyQualificationClient/StarRating";
import { getFingerprint } from "react-fingerprint";
import { createFreelanceQualification } from "../../utils/freelance-qualification-client/api";

export default function FreelanceQualificationClient() {
  const [attitudes, setAttitudes] = useState([]);
  const [employeeSummary, setEmployeeSummary] = useState({});
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    stars: "",
    comment: "",
    attitudesIds: [],
  });

  const { employeeId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getEmployeeSummary(employeeId);
        setEmployeeSummary(response.data);

        response = await getAttitudes();
        setAttitudes(response.data);

        const fingerprint = await getFingerprint();

        setFormData((prevData) => ({
          ...prevData,
          id: fingerprint,
          attitudesIds: selectedAttitudes,
        }));
      } catch (error) {}
    };
    fetchData();
  }, [employeeId, selectedAttitudes]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedAttitudes") {
      const attitudeId = value;
      setSelectedAttitudes((prevAttitudes) => {
        if (prevAttitudes.includes(attitudeId)) {
          return prevAttitudes;
        }
        return [...prevAttitudes, attitudeId];
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleStarChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      stars: value,
    }));
  };

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) =>
      prevAttitudes.filter((id) => id !== attitudeId)
    );
  };

  const handleCommentChange = (event) => {
    const { name, value } = event.target;
    if (value.length <= 150) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      toast.info("El comentario debe tener máximo 150 caracteres.");
    }
  };

  const handleSave = async () => {
    let res;
    try {
      res = await createFreelanceQualification(employeeId, formData);
      if (res.status === 200) {
        toast.success("Calificación enviada con éxito!");
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  if (!!localStorage.getItem("jwtToken")) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="qr-container">
      <div className="content">
        <Container>
          <Col>
            <div className="qr-div">
              <div className="header">
                <h4>
                  Califica tu experiencia con {employeeSummary?.employee?.name}
                </h4>
              </div>
              <p className="foot-qr">Calificar atención</p>
              <div>
                <div className="employee-container">
                  {employeeSummary?.image ? (
                    <img
                      src={`${BASE_URL}/images/${employeeSummary?.image.id}`}
                      alt="Empleado seleccionado"
                      className="employee-image"
                    />
                  ) : (
                    <Avatar
                      name={employeeSummary?.employee?.name}
                      color="#7800FF"
                      size="150"
                      round={true}
                    />
                  )}
                </div>
              </div>
              <br></br>
              <div>
                <StarRating
                  value={formData.stars}
                  onChange={handleStarChange}
                />
              </div>
              <br></br>
              <div>
                <label>Actitudes: (máximo 3)</label>
                <div>
                  {selectedAttitudes.map((attitudeId) => {
                    const attitude = attitudes.find(
                      (attitude) => attitude.id === attitudeId
                    );
                    if (attitude) {
                      return (
                        <Badge
                          key={attitude.id}
                          className="badge-pill"
                          onClick={() => handleRemoveAttitude(attitudeId)}
                        >
                          {attitude.name}
                        </Badge>
                      );
                    }
                    return null;
                  })}
                  <select
                    value=""
                    name="selectedAttitudes"
                    onChange={(e) => {
                      const attitudeId = e.target.value;
                      setSelectedAttitudes((prevAttitudes) => {
                        if (prevAttitudes.includes(attitudeId)) {
                          return prevAttitudes;
                        }
                        if (prevAttitudes.length >= 3) {
                          toast.info("Solo se permiten hasta 3 actitudes");
                          return prevAttitudes;
                        }
                        return [...prevAttitudes, attitudeId];
                      });
                    }}
                  >
                    <option value="" disabled>
                      -- Agregar actitud --
                    </option>
                    {attitudes.map((attitude) => (
                      <option key={attitude.id} value={attitude.id}>
                        {attitude.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <br></br>
              <div>
                <label>Comentario: (opcional) máx. 150 caracteres</label>
                <textarea
                  rows={2}
                  name="comment"
                  value={formData.comment}
                  onChange={(handleInputChange, handleCommentChange)}
                />
              </div>
              <button onClick={handleSave} type="submit">
                Enviar calificación
              </button>
              <br></br>
              <p className="foot-qr">Gracias</p>
              <p className="small-text">www.workerlevel.com</p>
            </div>
          </Col>
        </Container>
      </div>
    </div>
  );
}
