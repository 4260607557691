import React, { useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeProfile/EmployeeProfile.css";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../utils/endpoints/users/api";
import { toast } from "react-toastify";

export default function OwnerProfile({ user }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingUserInfo, setIsEditingUserInfo] = useState(false);
  const navigate = useNavigate();

  const [userFormData, setUserFormData] = useState({
    name: "",
    email: "",
    birthDate: "",
    phone: "",
  });

  const updateUserInfo = async () => {
    try {
      setIsSubmitting(true);
      let res = await updateUser(userFormData);
      if (res.status === 200) {
        toast.success("Datos personales modificados con éxito");
        navigate("/owner-profile");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditUserInfo = () => {
    setIsEditingUserInfo(true);

    if (user) {
      setUserFormData({
        name: user?.name,
        email: user?.email,
        birthDate: user?.birthDate,
        phone: user?.phone,
      });
    }
  };

  const handleUserInputChange = (event) => {
    const { name, value } = event.target;
    setUserFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <div className="profile-section">
            <button
              style={{ marginLeft: "15px", backgroundColor: "#7800ff" }}
              onClick={handleEditUserInfo}
            >
              {"Editar datos personales"}
            </button>
            {isEditingUserInfo && (
              <button
                style={{ marginLeft: "15px", backgroundColor: "#4EA301" }}
                onClick={updateUserInfo}
              >
                {isSubmitting ? "Guardando..." : "Actualizar datos personales"}
              </button>
            )}
            <br></br>
            <div className="user-info">
              <div className="user-field">
                <label>Nombre:</label>
                <input
                  type="text"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={isEditingUserInfo ? userFormData.name : user.name}
                  name="name"
                />
              </div>
              <div className="user-field">
                <label>CUIT</label>
                <input type="text" disabled value={user.cuil} />
              </div>
              <div className="user-field">
                <label>Correo electrónico</label>
                <input
                  type="text"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={isEditingUserInfo ? userFormData.email : user.email}
                  name="email"
                />
              </div>
              <div className="user-field">
                <label>Fecha de nacimiento</label>
                <input
                  type="date"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={
                    isEditingUserInfo ? userFormData.birthDate : user.birthDate
                  }
                  name="birthDate"
                />
              </div>
              <div className="user-field">
                <label>Teléfono</label>
                <input
                  type="text"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={isEditingUserInfo ? userFormData.phone : user.phone}
                  name="phone"
                />
              </div>
            </div>
          </div>
          <hr></hr>
          <a href="/change-password">
            <button style={{ marginLeft: "15px" }}>Cambiar contraseña</button>
          </a>
        </Container>
      </div>
    </div>
  );
}
