import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import { Badge, Container, Table } from "react-bootstrap";
import { setProperty } from "../../utils/global-parameters/api";
import { fetchSponsors } from "../../utils/endpoints/sponsors/api";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { getCompaniesWithSponsorEnabled } from "../../utils/company/api";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { BASE_URL } from "../../utils/endpoints/urls";
import Avatar from "react-avatar";

export default function SponsorsManagement({ user }) {
  const [companies, setCompanies] = useState([]);
  const [initialSelectedSponsors, setInitialSelectedSponsors] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    if (initialSelectedSponsors.length === 0) {
      const fetchData = async () => {
        try {
          let response = await getCompaniesWithSponsorEnabled(
            currentPage,
            pageSize
          );
          setCompanies(response.data);
          setTotalPages(response.data[0].totalPages);

          response = await fetchSponsors();
          setInitialSelectedSponsors(response.map((sponsor) => sponsor.id));
        } catch (error) {
          // Manejar el error
        }
      };
      fetchData();
    }
  }, [currentPage]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleToggleSponsor = (companyId, state) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        state ? "remover" : "seleccionar"
      } la empresa como sponsor?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              const formData = {
                propertyName: "sponsors",
                propertyValue: companyId,
              };

              const res = await setProperty(formData);

              if (res.status === 200) {
                toast.success("Sponsors modificados con éxito!");
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error("Error al guardar el sponsor");
              }
            } catch (error) {
              console.log(error);
              toast.error("Error al guardar el sponsor");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Gestión de Sponsors</h4>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {currentPage + 1} de {totalPages}
            </span>
          </div>
          <Table>
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Sponsor</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                <tr key={company.id}>
                  <td>
                    {company.image !== null ? (
                      <img
                        className="avatar-image"
                        src={`${BASE_URL}/images/${company.image?.id}`}
                        alt="Empleado"
                      />
                    ) : (
                      <Avatar name={company.name} size="40" round />
                    )}

                    {company.name}
                  </td>
                  <td>
                    {initialSelectedSponsors.includes(company.id) ? (
                      <button
                        className={`toggle-button active`}
                        onClick={() => handleToggleSponsor(company.id, true)}
                      >
                        Seleccionada
                      </button>
                    ) : (
                      <button
                        className={`toggle-button inactive`}
                        onClick={() => handleToggleSponsor(company.id, false)}
                      >
                        No seleccionada
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination">
            <button
              className="page-button"
              onClick={goToFirstPage}
              disabled={currentPage === 0}
            >
              {"<<"}
            </button>
            <button
              className="page-button"
              onClick={handlePrevPageClick}
              disabled={currentPage === 0}
            >
              Anterior
            </button>
            {renderPageButtons()}
            <button
              className="page-button"
              onClick={handleNextPageClick}
              disabled={currentPage === totalPages - 1}
            >
              Siguiente
            </button>
            <button
              className="page-button"
              onClick={goToLastPage}
              disabled={currentPage === totalPages - 1}
            >
              {">>"}
            </button>
          </div>
        </Container>
      </div>
    </div>
  );
}
