import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getEmployeesForProspect = async (
  page,
  size,
  jobCategory,
  age,
  attitude,
  averageQualification
) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/employee-summary/?page=${pageNumber}&size=${pageSize}`;

  if (jobCategory) {
    url += `&jobCategory=${jobCategory}`;
  }
  if (age) {
    url += `&age=${age}`;
  }
  if (attitude) {
    url += `&attitude=${attitude}`;
  }
  if (averageQualification) {
    url += `&averageQualification=${averageQualification}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeSummary = async (employeeId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/employee-summary/${employeeId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeesByCompany = async (companyId, page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/employee-summary/company/${companyId}?page=${pageNumber}&size=${pageSize}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getValidatedEmployeeSummariesByCompany = async (
  companyId,
  page,
  size
) => {
  const defaultPage = 0;
  const defaultSize = 100;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/employee-summary/company/${companyId}/validated?page=${pageNumber}&size=${pageSize}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeApplications = async (employeeId, page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/employee-summary/get-applications/${employeeId}?page=${pageNumber}&size=${pageSize}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const createEmployeeSummary = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/employee-summary/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateEmployeeSummary = async (data, employeeId) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/employee-summary/` + employeeId,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const validateEmployeeByCompany = async (employeeId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/employee-summary/${employeeId}/validate-employee`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteEmployeeValidation = async (employeeId) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/employee-summary/${employeeId}/delete-employee-validation`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const assignPublicAttentionToEmployeeByCompany = async (employeeId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/employee-summary/${employeeId}/assign-public-attention`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
