import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faCheck, faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import "../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal.css";
import { toast } from "react-toastify";
import { updateMembership } from "../../utils/membership/api";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import "./MembershipUpdateModal.css";

export default function MembershipUpdateModal({ membership }) {
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formattedStartDate = membership.startDate
    ? membership.startDate.substring(0, 10)
    : "";
  const formattedEndDate = membership.endDate
    ? membership.endDate.substring(0, 10)
    : "";

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedStartDate || "",
      membershipType: membership?.type || "",
      paymentReceipt: membership?.paymentReceipt || "",
      endDate: formattedEndDate || "",
    }));
  }, [editing, membership]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = async () => {
    if (membership) {
      let res;
      setIsSubmitting(true);

      try {
        toast.info(
          "Estamos procesando la aprobación. Por favor, espere, esto puede tardar unos segundos..."
        );
        res = await updateMembership(membership.id, formData);
        if (res.status === 200) {
          toast.dismiss();
          toast.success("Membresía renovada con éxito!");
          handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          toast.error(res.response.data.message);
        }
      } catch (error) {
        const { errors } = res.response.data;
        Object.keys(errors).forEach((field) => {
          const errorMessage = errors[field];
          toast.error(`${errorMessage}`);
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const [formData, setFormData] = useState({
    startDate: membership?.startDate || "",
    paymentReceipt: membership?.paymentReceipt || "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const calculateEndDate = () => {
    const { startDate, membershipType } = formData;
    if (startDate && membershipType) {
      const startDateObj = new Date(startDate);
      let endDateObj = new Date(startDate);

      if (membershipType === "MENSUAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 1);
      } else if (membershipType === "TRIMESTRAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 3);
      } else if (membershipType === "SEMESTRAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 6);
      } else if (membershipType === "ANUAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 12);
      }

      const endDate = `${endDateObj.getMonth() + 1}/${
        endDateObj.getDate() + 1
      }/${endDateObj.getFullYear()}`;
      return endDate;
    }

    return "";
  };

  switch (membership.type) {
    case "MONTHLY":
      membership.type = "MENSUAL";
      break;
    case "TRIMESTERLY":
      membership.type = "TRIMESTRAL";
      break;
    case "SEMESTERLY":
      membership.type = "SEMESTRAL";
      break;
    case "YEARLY":
      membership.type = "ANUAL";
      break;
    default:
      break;
  }

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#06C798" }}
        onClick={handleOpen}
      >
        Renovar membresía
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Renovar Membresía</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Empresa:</label>
                <input type="text" value={membership?.company?.name} disabled />
              </div>
            </Col>
            <Col>
              <div>
                <label>Tipo de membresía:</label>
                <input type="text" value={membership?.type} disabled />
              </div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <div>
                <label>Comprobante de pago:</label>
                <input
                  type="text"
                  defaultValue={membership?.paymentReceipt}
                  name="paymentReceipt"
                  onChange={handleInputChange}
                  disabled={!editing}
                />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  type="date"
                  onChange={handleInputChange}
                  defaultValue={formattedStartDate}
                  disabled={!editing}
                  name="startDate"
                />
              </div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <div></div>
            </Col>
            <Col>
              {!editing ? (
                <div>
                  <label>Fecha de fin:</label>
                  <input
                    type="text"
                    value={moment(membership?.endDate)
                      .format("MM/DD/YYYY")
                      .toString()}
                    disabled
                  />
                </div>
              ) : (
                <div>
                  <label>Fecha de fin estimada:</label>
                  <input type="text" value={calculateEndDate()} disabled />
                </div>
              )}
            </Col>
          </Row>
          <p>NOTA: Las fechas se encuentran en formato MM/DD/YYYY</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} />{" "}
            {isSubmitting ? "Aprobando..." : "Aprobar renovación"}
          </Button>

          <Button variant="outline-primary" onClick={handleEdit}>
            <FontAwesomeIcon icon={faEdit} /> Editar
          </Button>

          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
