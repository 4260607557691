import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Container, Table } from "react-bootstrap";
import moment from "moment/moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { fetchAllCommentsByUser } from "../../utils/endpoints/comments/api";
import "../CommentManagement/CommentManagement.css";
import CommentCreateModal from "./CommentCreateModal";

export default function Comment({ user }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [comments, setComments] = useState([]);

  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetchAllCommentsByUser(
          user.id,
          currentPage,
          pageSize
        );
        setComments(response.data);

        if (comments.length > 0 && comments[0].totalPages) {
          setTotalPages(comments[0].totalPages);
        }
      } catch (error) {}
    };

    fetchData();
  }, [currentPage, user]);

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const handleToggleCollapse = (commentId) => {
    setComments((prevComments) =>
      prevComments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            collapsed: !comment.collapsed,
          };
        }
        return comment;
      })
    );
  };

  if (
    user?.role !== "EMPLOYEE" &&
    user?.role !== "COMPANY" &&
    user?.role !== "FREELANCE"
  ) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Comentarios para Worker Level</h4>
          <div className="table-container">
            <CommentCreateModal
              user={user}
              handleClose={handleCreateModalClose}
            />
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Empresa</th>
                  <th>Comentario</th>
                  <th>Fecha de creación</th>
                </tr>
              </thead>
              <tbody>
                {comments?.map((comment) => (
                  <tr key={comment.id}>
                    <td>{comment.company}</td>
                    <td>
                      <div
                        className={`comment-content ${
                          comment.collapsed ? "collapsed" : ""
                        }`}
                        onClick={() => handleToggleCollapse(comment.id)}
                      >
                        {comment.comment}
                      </div>
                    </td>
                    <td>{moment(comment.createdAt).format("DD/MM/YYYY")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
