import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const applyToMembership = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/membership/apply`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const applyToRenewalMembership = async (data, id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/membership/apply/renewal/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const approveMembership = async (membershipId, data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/membership/approve/${membershipId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateMembership = async (membershipId, data) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/membership/${membershipId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllMemberships = async (page, pageSize) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/membership/all?page=${page}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableMembership = async (membershipId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/membership/${membershipId}/disable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
