import React from "react";
import "./Separator.css";

const Separator = () => (
  <div className="separator-container">
    <h2 className="separator-h2">Crea un perfil para tu empresa</h2>
    <div>
      <a href="/signup">
        <button>Iniciar</button>
      </a>
    </div>
  </div>
);

export default Separator;
