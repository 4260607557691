import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faEdit, faEye, faSave } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { updateCourse } from "../../../utils/courses/api";
import { BASE_URL } from "../../../utils/endpoints/urls";

export default function EmployeeCoursesDetailModal({ course }) {
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newDocumentSrc, setNewDocumentSrc] = useState(null);
  const isImage = /\.(png|jpe?g)$/i.test(course?.certificate?.name);

  const documentUrl =
    course?.certificate !== null
      ? `${BASE_URL}/documents/download/${course.certificate.id}`
      : null;

  const formattedStartDate = course.startDate
    ? course.startDate.substring(0, 10)
    : "";
  const formattedEndDate = course.endDate
    ? course.endDate.substring(0, 10)
    : "";

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
      name: course.name,
      description: course.description,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      inProgress: course.inProgress,
      file: course.file,
    }));
  }, [showModal, course]);

  const handleSave = async () => {
    let res;
    try {
      res = await updateCourse(formData, course.id);
      if (res.status === 200) {
        toast.success("Estudio actualizado con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewDocumentSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    inProgress: "",
    file: null,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
      endDate: checked ? "" : prevData.endDate,
    }));
  };

  const handleStartDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      startDate: formattedDate,
    }));
  };

  const handleEndDateChange = (date) => {
    const formattedDate = date ? date.toISOString().split("T")[0] : null;
    setFormData((prevData) => ({
      ...prevData,
      endDate: formattedDate,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  return (
    <>
      <button className="btn btn-link" onClick={handleOpen}>
        <FontAwesomeIcon icon={faEye} />
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estudio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Nombre:</label>
                <input
                  value={formData.name}
                  type="text"
                  onChange={handleInputChange}
                  name="name"
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>Descripción:</label>
                <textarea
                  value={formData.description}
                  style={{ textAlign: "left" }}
                  rows={5}
                  onChange={handleInputChange}
                  name="description"
                  disabled={!isEditing}
                ></textarea>
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  value={formData.startDate}
                  type="date"
                  onChange={handleInputChange}
                  name="startDate"
                  disabled={!isEditing}
                />
              </div>
              <div>
                <label>Fecha de fin:</label>
                <input
                  value={formData.endDate}
                  type="date"
                  onChange={handleInputChange}
                  name="endDate"
                  disabled={formData.inProgress || !isEditing}
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={formData.inProgress}
                  name="inProgress"
                  onChange={handleCheckboxChange}
                  disabled={!isEditing}
                />
                <label>&nbsp;Actualmente activo</label>
              </div>
            </Col>
            <div>
              {isEditing ? (
                <div>
                  <input
                    type="file"
                    onChange={handleDocumentChange}
                    accept="image/*, application/pdf"
                  />
                </div>
              ) : (
                <div>
                  <label className="change-image-label">
                    Subir certificado de estudio (opcional)<br></br> [PDF o
                    imagen]
                    <br></br>
                    <p>
                      {course.certificate && (
                        <div>
                          {course.certificate.fileName.split(".")[1] +
                            "." +
                            course.certificate.fileName.split(".")[2]}
                        </div>
                      )}
                    </p>
                    {course.certificate && (
                      <a
                        href={documentUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {isImage ? "Descargar imagen" : "Descargar PDF"}
                      </a>
                    )}
                  </label>
                </div>
              )}
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!isEditing ? (
            <Button variant="outline-primary" onClick={handleEdit}>
              <FontAwesomeIcon icon={faEdit} /> Editar
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
