import React from "react";
import "./StarRating.css"; // Archivo CSS para estilos

const StarRating = ({ value, onChange }) => {
  const handleChange = (newValue) => {
    onChange(newValue);
  };

  const handleHover = (hoveredValue) => {
    const stars = document.querySelectorAll(".qualification-star");
    stars.forEach((star, index) => {
      if (index < hoveredValue) {
        star.classList.add("hover");
      } else {
        star.classList.remove("hover");
      }
    });
  };

  return (
    <div className="star-rating" onMouseLeave={() => handleHover(value)}>
      {[1, 2, 3, 4, 5].map((star) => (
        <label
          key={star}
          className={`qualification-star ${star <= value ? "active" : ""}`}
          onMouseEnter={() => handleHover(star)}
          onClick={() => handleChange(star)}
        >
          &#9733;
        </label>
      ))}
    </div>
  );
};

export default StarRating;
