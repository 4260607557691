import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getJobCategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/job-category`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllJobCategories = async (page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;
  try {
    const response = await axios.get(
      `${BASE_URL}/job-category/all?page=${pageNumber}&size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createJobCategory = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/job-category/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateJobCategory = async (jobCategoryId, data) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/job-category/${jobCategoryId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableJobCategory = async (jobCategoryId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/job-category/` + jobCategoryId + "/disable",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
