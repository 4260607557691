import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { createRecommendedCourse } from "../../../utils/recommended-courses/api";

export default function RecommendedCourseCreateModal() {
  const [showModal, setShowModal] = useState(false);
  const [newImageSrc, setNewImageSrc] = useState(null);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleSave = async () => {
    let res;
    try {
      res = await createRecommendedCourse(formData);
      if (res.status === 200) {
        toast.success("Curso recomendado creado con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    url: "",
    file: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewImageSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Agregar Curso Recomendado
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Curso Recomendado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Nombre:</label>
            <input type="text" onChange={handleInputChange} name="name" />
          </div>
          <div>
            <label>Descripción:</label>
            <textarea
              type="text"
              onChange={handleInputChange}
              rows={3}
              name="description"
            />
          </div>
          <div>
            <label>URL:</label>
            <input type="text" onChange={handleInputChange} name="url" />
          </div>
          <div>
            <label className="change-image-label">
              Subir imagen (formato .jpg/.png/.jpeg)
              <br></br>
              <input
                type="file"
                onChange={handleImageChange}
                accept="image/*"
              />
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Crear
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
