import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyProspect.css";
import { Container, Form, FormSelect, Table } from "react-bootstrap";
import { getJobCategories } from "../../utils/job-category/api";
import { getAttitudes } from "../../utils/attitudes/api";
import { getEmployeesForProspect } from "../../utils/employee-summary/api";
import Avatar from "react-avatar";
import { BASE_URL } from "../../utils/endpoints/urls";
import { toast } from "react-toastify";
import { getCompany } from "../../utils/company/api";

export default function CompanyProspect({ user }) {
  const [employees, setEmployees] = useState([]);
  const [company, setCompany] = useState(null);
  const [jobCategories, setJobCategories] = useState([]);
  const [attitudes, setAttitudes] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;
  const [filters, setFilters] = useState({
    jobCategory: "",
    averageQualification: "",
    age: "",
    attitudeId: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.companyId) return;

        let response = await getCompany(user?.companyId);
        setCompany(response.data);

        response = await getEmployeesForProspect(currentPage, pageSize);
        setEmployees(response.data);
        setTotalPages(response.data[0].totalPages);

        response = await getJobCategories();
        setJobCategories(response.data);

        response = await getAttitudes();
        setAttitudes(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [currentPage, user?.companyId, company?.id]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleFilterChange = (event) => {
    if (
      event.target.name === "averageQualification" &&
      (event.target.value > 5 || event.target.value < 1)
    ) {
      toast.error("La calificación promedio debe ser un número entre 1 y 5");
      return;
    }

    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    if (filters.age !== "" && filters.age < 18) {
      toast.error("La edad mínima es 18 años");
      return;
    }

    try {
      const employees = await getEmployeesForProspect(
        currentPage,
        pageSize,
        filters.jobCategory,
        filters.age,
        filters.attitudeId,
        filters.averageQualification
      );

      if (employees && employees.data) {
        setEmployees(employees.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      jobCategory: "",
      averageQualification: "",
      age: "",
      attitudeId: "",
    });
  };

  if (
    user?.role !== "COMPANY" ||
    user?.companyId === null ||
    company?.membership === null ||
    company?.membership?.approvalPending === true
  ) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
              <a href="/company-membership">Solicite su membresía aquí</a>
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h2 className="benefits-h1">Capital Humano</h2>
          <hr></hr>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {currentPage + 1} de {totalPages}
            </span>
          </div>
          <div className="filters">
            <FormSelect
              name="jobCategory"
              value={filters.jobCategory}
              onChange={handleFilterChange}
            >
              <option value="" disabled>
                Seleccione una categoría *
              </option>
              {jobCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </FormSelect>
            <FormSelect
              name="attitudeId"
              value={filters.attitudeId}
              onChange={handleFilterChange}
            >
              <option value="" disabled>
                Seleccione una actitud
              </option>
              {attitudes.map((attitude) => (
                <option key={attitude.id} value={attitude.id}>
                  {attitude.name}
                </option>
              ))}
            </FormSelect>
            <Form.Control
              style={{
                marginBottom: "8px",
                backgroundColor: "white",
                textAlign: "center",
              }}
              type="number"
              step="0.01"
              name="averageQualification"
              value={filters.averageQualification}
              onChange={handleFilterChange}
              placeholder="Calificación promedio (1-5)"
              min={1}
              max={5}
            />
            <Form.Control
              style={{
                marginBottom: "8px",
                backgroundColor: "white",
                textAlign: "center",
              }}
              type="number"
              name="age"
              value={filters.age}
              onChange={handleFilterChange}
              placeholder="Edad mínima"
              min={18}
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>CUIL</th>
                  <th>Categoría</th>
                  <th>Calificación promedio</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={employee.id}>
                    <td>
                      {employee.employee.imageId !== null ? (
                        <img
                          className="avatar-image"
                          src={`${BASE_URL}/images/${employee.employee.imageId}`}
                          alt="Empleado"
                        />
                      ) : (
                        <Avatar name={employee.employee.name} size="40" round />
                      )}
                      &nbsp; {employee.employee.name}
                    </td>
                    <td>{employee.employee.cuil}</td>
                    <td>
                      {employee.jobCategories.map((category, index) => (
                        <span key={category.id}>
                          {category.name}
                          {index !== employee.jobCategories.length - 1 && ", "}
                        </span>
                      ))}
                    </td>
                    <td>{employee.averageQualification}</td>
                    <td>
                      <a href={`/employee-summary/${employee.employee.id}`}>
                        <button
                          style={{
                            backgroundColor: "#06C798",
                            fontWeight: "bold",
                          }}
                        >
                          Ampliar
                        </button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
