import React, { useState, useEffect } from "react";
import { fetchComments } from "../../../utils/endpoints/comments/api";
import "./CommentContainer.css";
import CommentCard from "./CommentCard";
import { Container } from "react-bootstrap";
import arrow from "../../../assets/worker level elemento25.png";

const CommentContainer = () => {
  const [comments, setComments] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const pageSize = 3;

  useEffect(() => {
    const fetchCommentsFromApi = async () => {
      const fetchedComments = await fetchComments(currentPage, pageSize);
      setComments(fetchedComments);

      if (fetchedComments.length > 0 && fetchedComments[0].totalPages) {
        setTotalPages(fetchedComments[0].totalPages);
      }
    };

    fetchCommentsFromApi();
  }, [currentPage]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Container className="comment-parent-container">
      <div className="comment-title">
        <h2>Comentarios de usuarios</h2>
        <h3>La retroalimentación nos impulsa a crecer.</h3>
      </div>
      <div className="comment-container">
        <div className="carousel">
          <button
            className="carousel-button"
            onClick={handlePrevPageClick}
            disabled={currentPage === 0}
          >
            <img src={arrow} alt="arrow" />
          </button>
          <div className="comment-cards">
            {comments.map((comment) => (
              <CommentCard key={comment.id} comment={comment} />
            ))}
          </div>
          <button
            className="carousel-button"
            onClick={handleNextPageClick}
            disabled={currentPage === totalPages - 1}
          >
            <img style={{ transform: "scaleX(-1)" }} src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
    </Container>
  );
};

export default CommentContainer;
