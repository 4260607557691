import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getGlobalParameters = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/global-parameters`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getMembershipLinks = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/global-parameters/membership-links`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const setProperty = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/global-parameters/set-property`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
