import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./Contact.css";
import emailIcon from "../../assets/icons/worker level elemento19.png";
import facebookIcon from "../../assets/icons/worker level elemento23.png";
import instagramIcon from "../../assets/icons/worker level elemento22.png";
import geoIcon from "../../assets/icons/worker level elemento20.png";
import phoneIcon from "../../assets/icons/worker level elemento21.png";
import { sendContactForm } from "../../utils/endpoints/contact-form/api";
import { toast } from "react-toastify";

function Contact() {
  const [data, setData] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await sendContactForm(data);
      toast.success(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div style={{minHeight: "100%"}}>
      <div
        style={{ display: "flex", justifyContent: "center" }}
      >
        <form>
          <div className="contact-title">
            <h2>Comunícate con Worker Level</h2>
            <p>Sera un gusto atender tus consultas</p>
          </div>
          <div className="contact-form">
            <div>
              <input
                placeholder="Nombre y apellido"
                id="name"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="text"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span style={{ color: "red" }}>
                  El nombre y apellido es requerido
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="Correo electrónico"
                id="email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="email"
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                })}
              />
              {errors.email?.type === "required" && (
                <span style={{ color: "red" }}>
                  El campo correo electrónico es requerido
                </span>
              )}
              {errors.email?.type === "pattern" && (
                <span style={{ color: "red" }}>
                  El correo electrónico no es válido
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="CUIT o CUIL"
                id="cuit_cuil"
                onChange={(e) =>
                  setData({ ...data, cuit_cuil: e.target.value })
                }
                type="text"
                {...register("cuit_cuil", {
                  required: true,
                  pattern: /^[0-9]{11}$/,
                })}
              />
              {errors.cuit_cuil && (
                <span style={{ color: "red" }}>
                  Este campo es requerido y debe ingresar un CUIT o CUIL válido
                </span>
              )}
            </div>
            <div>
              <textarea
                placeholder="Consulta"
                type="text"
                id="query"
                onChange={(e) => setData({ ...data, query: e.target.value })}
                {...register("query", { required: true })}
              />
              {errors.query && (
                <span style={{ color: "red" }}>La consulta es requerida</span>
              )}
            </div>
            <div className="button-container">
              <button onClick={handleSubmit(onSubmit)}>
                Enviar
              </button>
            </div>
          </div>
        </form>
      </div>
      <hr className="contact-hr"></hr>
      <div className="contact-bubbles">
        <div className="contact-bubble">
          <div className="contact-bubble-icon">
            <img src={emailIcon} alt="email icon" />
          </div>
          <div>
            <h6>Correo</h6>
            <span>info@workerlevel.com</span>
          </div>
        </div>
        <div className="contact-bubble">
          <div className="contact-bubble-icon">
            <img src={geoIcon} alt="geo icon" />
          </div>
          <div>
            <h6>Ubicación</h6>
            <span>San Juan Argentina.</span>
          </div>
        </div>
        <div className="contact-bubble">
          <div className="contact-bubble-icon">
            <img src={phoneIcon} alt="phone icon" />
          </div>
          <div>
            <h6>Teléfono</h6>
            <span>2646274088</span>
            <br></br>
            <span>2644123194</span>
          </div>
        </div>
        <div className="contact-bubble">
          <div className="contact-bubble-icon">
            <img src={instagramIcon} alt="instagram icon" />
          </div>
          <div>
            <h6>Instagram</h6>
            <span>workerlevel</span>
          </div>
        </div>
        <div className="contact-bubble">
          <div className="contact-bubble-icon">
            <img src={facebookIcon} alt="facebook icon" />
          </div>
          <div>
            <h6>Facebook</h6>
            <span>workerlevel</span>
          </div>
        </div>
        <div className="contact-bubble">
          <div className="contact-bubble-icon">
            <img src={emailIcon} alt="email icon" />
          </div>
          <div>
            <h6>Google</h6>
            <span>workerlevel</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
