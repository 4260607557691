import React, { useEffect } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyPanel/CompanyDashboard.css";
import WelcomeMessage from "../CompanyPanel/WelcomeMessage/WelcomeMessage";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";

export default function FreelanceDashboard({ user }) {
  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    const intervalId = setInterval(reloadPage, 30000);

    if (user?.employeeSummaryId === null) {
      toast.info(
        "Recuerde completar los datos de Mi Perfil, Titulo y Cursos y Mis Experiencias antes de crear una oferta de trabajo."
      );
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [user]);

  if (user?.role !== "FREELANCE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="dashboard-container">
      <Sidebar user={user} />
      <div className="content">
        <div className="content-inner">
          <WelcomeMessage user={user} />
        </div>
      </div>
    </div>
  );
}
