import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import "../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal.css";
import { toast } from "react-toastify";
import { updateAttitude } from "../../utils/attitudes/api";

export default function AttitudesDetailModal({ attitude }) {
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, [editing]);

  const handleEdit = () => {
    setEditing(true);

    setFormData({
      name: attitude?.name,
    });
  };

  const handleSave = async () => {
    if (attitude) {
      let res;
      try {
        res = await updateAttitude(attitude.id, formData);
        if (res.status === 200) {
          toast.success("Actitud modificada con éxito!");
          handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.response.data.message);
        }
      } catch (error) {
        const { errors } = res.response.data;
        Object.keys(errors).forEach((field) => {
          const errorMessage = errors[field];
          toast.error(`${errorMessage}`);
        });
      }
    }
  };

  const [formData, setFormData] = useState({
    name: attitude?.name || "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button className="btn btn-link" onClick={handleOpen}>
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{attitude.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Nombre:</label>
            <input
              type="text"
              defaultValue={attitude.name}
              onChange={handleInputChange}
              disabled={!editing}
              name="name"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!editing ? (
            <Button variant="outline-primary" onClick={handleEdit}>
              <FontAwesomeIcon icon={faEdit} /> Editar
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
