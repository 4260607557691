import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./EmployeeExperiences.css";
import { Container, Table } from "react-bootstrap";
import moment from "moment/moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getEmployeeSummary } from "../../utils/employee-summary/api";
import EmployeeWorkExperienceCreateModal from "./EmployeeWorkExperienceCreateModal/EmployeeWorkExperienceCreateModal";
import EmployeeWorkExperienceDetailModal from "./EmployeeWorkExperienceDetailModal/EmployeeWorkExperienceDetailModal";
import { getEmployeeWorkExperiences } from "../../utils/work-experience/api";

export default function EmployeeExperiences({ user }) {
  const [employeeSummary, setEmployeeSummary] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [workExperiences, setWorkExperiences] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.employeeSummaryId) return;
        let response = await getEmployeeSummary(user?.id);
        setEmployeeSummary(response.data);

        response = await getEmployeeWorkExperiences(
          user?.employeeSummaryId,
          currentPage,
          pageSize
        );
        setWorkExperiences(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();
  }, [user?.employeeSummaryId, currentPage]);

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  if (user?.role !== "EMPLOYEE" && user?.role !== "FREELANCE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Mis experiencias laborales</h4>
          <div className="table-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <EmployeeWorkExperienceCreateModal
                user={user}
                handleClose={handleCreateModalClose}
              />
              <span className="page-legend">
                Página {currentPage + 1} de {totalPages}
              </span>
            </div>
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Empresa</th>
                  <th>Posición</th>
                  <th>Fecha de inicio</th>
                  <th>Fecha de finalización</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {workExperiences?.map((workExperience) => (
                  <tr key={workExperience.id}>
                    <td>{workExperience.company}</td>
                    <td>{workExperience.position}</td>
                    <td>
                      {moment(workExperience.startDate).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      {workExperience.active
                        ? null
                        : moment(workExperience.endDate).format(
                            "DD/MM/YYYY"
                          )}{" "}
                    </td>
                    <td>{workExperience.active ? "Activo" : "Inactivo"}</td>
                    <td>
                      <EmployeeWorkExperienceDetailModal
                        workExperience={workExperience}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
