import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./SignIn.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../actions/authActions";

function SignIn() {
  const [data, setData] = useState({});
  const loggedIn = localStorage.getItem("jwtToken");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      dispatch(loginUser(data))
        .then((response) => {})
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } catch (error) {}
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  });

  return (
    <div className="signin-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className="contact-title">
            <h2>¡Bienvenido!</h2>
            <p>
              Acompañamos a las empresas y colaboradores para el siguiente nivel
            </p>
          </div>
          <div className="contact-form">
            <div>
              <input
                placeholder="CUIL/CUIT"
                id="cuil"
                onChange={(e) => setData({ ...data, cuil: e.target.value })}
                type="text"
                {...register("cuil", { required: true })}
              />
              {errors.cuil && (
                <span style={{ color: "red" }}>El campo es requerido</span>
              )}
            </div>
            <div>
              <input
                placeholder="Contraseña"
                id="password"
                onChange={(e) => setData({ ...data, password: e.target.value })}
                type="password"
                {...register("password", {
                  required: true,
                })}
              />
              {errors.password && (
                <span style={{ color: "red" }}>El campo es requerido</span>
              )}
            </div>
            <a href="/forgot-password" className="forgot-password-link">
              Olvidé mi contraseña
            </a>
            <div>
              <button className="send-button" onClick={handleSubmit(onSubmit)}>
                Ingresar
              </button>
            </div>
            <div style={{ display: "block", marginTop: "1rem" }}>
              <a href="/signup" className="signup-link">
                Aún no tengo cuenta
              </a>
              <button
                onClick={() => navigate("/signup")}
                className="register-button"
              >
                Registrarme
              </button>
            </div>
            <hr className="signin-hr"></hr>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignIn;
