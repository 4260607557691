import React, { useState, useEffect } from "react";

const MembershipLinks = ({ links, selectedMembershipType }) => {
  const [selectedLink, setSelectedLink] = useState(null);

  const mapMembershipTypeToPropertyName = (type) => {
    switch (type) {
      case "MENSUAL":
        return "Monthly";
      case "ANUAL":
        return "Yearly";
      case "TRIMESTRAL":
        return "Trimestral";
      case "SEMESTRAL":
        return "Semestral";
      default:
        return "";
    }
  };

  useEffect(() => {
    const propertyName = mapMembershipTypeToPropertyName(
      selectedMembershipType
    );

    const selectedLinkObj = links.find(
      (link) => link.propertyName === `urlOfTheMembershipType${propertyName}`
    );
    setSelectedLink(selectedLinkObj);
  }, [links, selectedMembershipType]);

  return (
    <div>
      {selectedLink && (
        <a
          href={selectedLink.propertyValue}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Ir a pagar</button>
        </a>
      )}
    </div>
  );
};

export default MembershipLinks;
