import React, { useState } from "react";
import "./Faq.css";

function Faq() {
  const [activeIndex, setActiveIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const faqData = [
    {
      question: "¿Cómo puedo crear una cuenta?",
      answer: "Para crear una cuenta, debes hacer clic en el botón 'Registrarse' en la página principal y seguir las instrucciones.",
    },
    {
      question: "¿Cómo puedo restablecer mi contraseña?",
      answer: "Para restablecer tu contraseña, debes hacer clic en el botón '¿Olvidaste tu contraseña?' en la página de inicio de sesión y seguir las instrucciones.",
    },
    {
      question: "¿Cuáles son las formas de pago disponibles?",
      answer: "Aceptamos pagos con tarjeta de crédito y débito, PayPal y transferencias bancarias.",
    },
  ];

  return (
    <div className="faq-container">
      <div className="faq-title">
        <h2>Preguntas frecuentes</h2>
        <p>Despeja tus dudas</p>
      </div>
      <div className="faq-content">
        {faqData.map((item, index) => (
          <div className="faq-item" key={index}>
            <div
              className="faq-question"
              onClick={() => toggleAccordion(index)}
            >
              <h5>{item.question}</h5>
              <button id="faq-button" className={activeIndex === index ? "active" : ""}>
                {activeIndex === index ? "-" : "+"}
              </button>
            </div>
            <div
              className={
                activeIndex === index ? "faq-answer active" : "faq-answer"
              }
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
