import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Container, Table } from "react-bootstrap";
import moment from "moment/moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "../CommentManagement/CommentManagement.css";
import {
  disableRecommendedCourse,
  fetchAllRecommendedCourses,
} from "../../utils/recommended-courses/api";
import RecommendedCourseCreateModal from "./RecommendedCourseCreateModal/RecommendedCourseCreateModal";
import RecommendedCourseDetailModal from "./RecommendedCourseCreateModal/RecommendedCourseDetailModal/RecommendedCourseDetailModal";

export default function RecommendedCoursesManagement({ user }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recommendedCourses, setRecommendedCourses] = useState([]);

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await fetchAllRecommendedCourses(currentPage, pageSize);
        setRecommendedCourses(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();
  }, [currentPage]);

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleToggleCollapse = (recommendedCourseId) => {
    setRecommendedCourses((prevRecommendedCourses) =>
      prevRecommendedCourses.map((recommendedCourse) => {
        if (recommendedCourse.id === recommendedCourseId) {
          return {
            ...recommendedCourse,
            collapsed: !recommendedCourse.collapsed,
          };
        }
        return recommendedCourse;
      })
    );
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  const handleToggleRecommendedCourse = (recommendedCourseId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deshabilitar" : "habilitar"
      } el curso?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await disableRecommendedCourse(
                recommendedCourseId
              );
              if (response.status === 200) {
                toast.success(
                  `Curso ${active ? "deshabilitado" : "habilitado"} con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${active ? "deshabilitar" : "habilitar"} el curso`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Gestión de cursos recomendados</h4>
          <div className="table-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <RecommendedCourseCreateModal
                handleClose={handleCreateModalClose}
              />
              {/* <span className="page-legend">
                Página {currentPage + 1} de {totalPages}
              </span> */}
            </div>
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha de creación</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {recommendedCourses?.map((recommendedCourse) => (
                  <tr key={recommendedCourse.id}>
                    <td>{recommendedCourse.name}</td>
                    <td>
                      <div
                        className={`comment-content ${
                          recommendedCourse.collapsed ? "collapsed" : ""
                        }`}
                        onClick={() =>
                          handleToggleCollapse(recommendedCourse.id)
                        }
                      >
                        {recommendedCourse.description}
                      </div>
                    </td>
                    <td>
                      {moment(recommendedCourse.creationDate).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>
                      <button
                        className={`toggle-button ${
                          recommendedCourse.enabled ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleToggleRecommendedCourse(
                            recommendedCourse.id,
                            recommendedCourse.enabled
                          )
                        }
                      >
                        {recommendedCourse.enabled
                          ? "Habilitado"
                          : "Deshabilitado"}
                      </button>
                    </td>
                    <td>
                      <RecommendedCourseDetailModal
                        recommendedCourse={recommendedCourse}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
