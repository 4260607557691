import React from "react";
import "./RatingStars.css";
const RatingStars = ({ rating }) => {
  const totalStars = 5;
  const filledStars = Math.ceil(rating);
  const emptyStars = totalStars - filledStars;

  const renderStars = (count, filled) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(
        <h3 key={i} className={`star ${filled ? "filled" : ""}`}>
          {filled ? "★" : "☆"}
        </h3>
      );
    }
    return stars;
  };

  return (
    <div className="rating-stars">
      {renderStars(filledStars, true)}
      {renderStars(emptyStars, false)}
    </div>
  );
};

export default RatingStars;
