import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "../SignUp/SignUp.css";
import { changePassword } from "../../utils/endpoints/users/api";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    try {
      toast.info("Estamos procesando su solicitud. Por favor, espere...");
      const response = await changePassword({
        password: data.oldPassword,
        newPassword: data.confirmPassword,
      });
      toast.dismiss();
      if (response.status === 200) {
        toast.success(response.data);
      }
      setTimeout(() => {
        navigate("/signin");
      }, 1000);
    } catch (error) {
      toast.dismiss();
      toast.error("Ha ocurrido un error. Por favor, intente nuevamente.");
    }
  };

  return (
    <div style={{ paddingBottom: "15rem" }} className="signin-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form>
          <div style={{ textAlign: "center" }}>
            <h3>Cambiar contraseña</h3>
            <div className="contact-form">
              <div>
                <input
                  placeholder="Contraseña actual"
                  id="oldPassword"
                  type="password"
                  {...register("oldPassword", { required: true })}
                />
                {errors.oldPassword && (
                  <span style={{ color: "red" }}>El campo es requerido</span>
                )}
              </div>
              <div>
                <input
                  placeholder="Contraseña nueva"
                  id="password"
                  type="password"
                  {...register("password", {
                    required: true,
                  })}
                />
                {errors.password && (
                  <span style={{ color: "red" }}>El campo es requerido</span>
                )}
              </div>
              <div>
                <input
                  placeholder="Confirmar contraseña"
                  id="confirmPassword"
                  type="password"
                  {...register("confirmPassword", {
                    required: true,
                    validate: (value) =>
                      value === watch("password") ||
                      "Las contraseñas no coinciden",
                  })}
                />
                {errors.confirmPassword && (
                  <span style={{ color: "red" }}>
                    {errors.confirmPassword.message}
                  </span>
                )}
              </div>
              <div>
                <button
                  className="send-button"
                  onClick={handleSubmit(onSubmit)}
                >
                  Restablecer contraseña
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
