import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyPostulates/CompanyPostulates.css";
import { Container, Form, FormSelect, Table } from "react-bootstrap";
import Avatar from "react-avatar";
import { BASE_URL } from "../../utils/endpoints/urls";
import CompanyJobOfferDetailsModal from "../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal";
import { getJobCategories } from "../../utils/job-category/api";
import moment from "moment";
import "../EmployeeJobOffer/EmployeeJobOffer.css";
import { getActiveFreelanceApplications } from "../../utils/freelance/api";
import FreelanceServicesDetailModal from "../FreelanceServices/FreelanceServicesCreateModal/FreelanceServicesDetailModal/FreelanceServicesDetailModal";
import RatingStars from "../EmployeeProfile/RatingStars/RatingStars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default function FreelanceApplications({ user }) {
  const [freelanceApplications, setFreelanceApplications] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;
  const [filters, setFilters] = useState({
    jobCategoryId: "",
    title: "",
  });

  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    const intervalId = setInterval(reloadPage, 30000);

    const fetchData = async () => {
      try {
        let response = await getActiveFreelanceApplications();
        setFreelanceApplications(response.data);
        setTotalPages(response.data[0].totalPages);

        response = await getJobCategories();
        setJobCategories(response.data);

        handleSearch();
      } catch (error) {}
    };

    fetchData();

    return () => {
      clearInterval(intervalId);
    };
  }, [user?.employeeSummaryId, currentPage]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    try {
      const freelanceApplications = await getActiveFreelanceApplications(
        currentPage,
        pageSize,
        filters.jobCategoryId,
        filters.title
      );

      if (freelanceApplications && freelanceApplications.data) {
        setFreelanceApplications(freelanceApplications.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      jobCategoryId: "",
      title: "",
    });
  };
  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      {user && <Sidebar user={user} />}
      <div className="content">
        <Container>
          <h2 className="benefits-h1">Ofertas de servicios de autónomos</h2>
          <hr></hr>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {currentPage + 1} de {totalPages}
            </span>
          </div>
          <div className="filters">
            <FormSelect
              name="jobCategoryId"
              className="modal-select"
              value={filters.jobCategoryId}
              onChange={handleFilterChange}
            >
              <option value="">Seleccione una categoría</option>
              {jobCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </FormSelect>
            <Form.Control
              type="text"
              name="title"
              value={filters.title}
              onChange={handleFilterChange}
              placeholder="Filtrar por título"
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Titulo</th>
                  <th>Autónomo</th>
                  <th>Calificación</th>
                  <th>Descripción</th>
                  <th>Categoria</th>
                  <th>Fecha de publicación</th>
                  <th>Ver</th>
                  <th>Reseñas</th>
                </tr>
              </thead>
              <tbody>
                {freelanceApplications &&
                  freelanceApplications.map((freelanceApplication) => (
                    <tr key={freelanceApplication.id}>
                      <td>{freelanceApplication.code}</td>
                      <td>{freelanceApplication?.title}</td>
                      <td>
                        {freelanceApplication?.employee?.image !== null ? (
                          <img
                            className="avatar-image"
                            src={`${BASE_URL}/images/${freelanceApplication?.employee.image?.id}`}
                            alt="Empleado"
                          />
                        ) : (
                          <Avatar
                            name={freelanceApplication.employee?.employee?.name}
                            size="40"
                            round
                          />
                        )}
                        &nbsp;
                        {freelanceApplication?.employee?.employee?.name}
                      </td>
                      <td>
                        <RatingStars
                          rating={
                            freelanceApplication?.employee?.averageQualification
                          }
                        />
                      </td>
                      <td>{freelanceApplication.description}</td>
                      <td>{freelanceApplication.jobCategory.name}</td>
                      <td>
                        {moment(freelanceApplication.startDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                      <td>
                        <FreelanceServicesDetailModal
                          service={freelanceApplication}
                          isDisabled={true}
                        />
                      </td>
                      <td>
                        <a
                          href={`/freelance-qualification/${freelanceApplication?.employee?.id}/all/freelance/${freelanceApplication?.employee?.employee?.id}`}
                        >
                          <button className="btn btn-link">
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
