import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Badge } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOffer.css";
import { createJobOffer } from "../../../utils/job-offers/api";
import { toast } from "react-toastify";
import { createFreelanceApplication } from "../../../utils/freelance/api";

export default function FreelanceServicesCreateModal({
  jobCategories,
  attitudes,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
      attitudesId: selectedAttitudes,
    }));
  }, [selectedAttitudes]);

  const handleSave = async () => {
    let res;
    try {
      res = await createFreelanceApplication(formData);
      if (res.status === 200) {
        toast.success("Publicación de servicio creada con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    jobCategoryId: "",
    attitudesId: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedAttitudes") {
      const attitudeId = value;
      setSelectedAttitudes((prevAttitudes) => {
        if (prevAttitudes.includes(attitudeId)) {
          return prevAttitudes;
        }
        return [...prevAttitudes, attitudeId];
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) =>
      prevAttitudes.filter((id) => id !== attitudeId)
    );
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Crear publicación de servicio
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Nueva publicación de servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Título:</label>
                <input type="text" onChange={handleInputChange} name="title" />
              </div>
            </Col>
            <div>
              <label>Descripción:</label>
              <textarea
                style={{ textAlign: "left" }}
                rows={5}
                onChange={handleInputChange}
                name="description"
              ></textarea>
            </div>
            <div>
              <label>Actitudes:</label>
              <div>
                {selectedAttitudes.map((attitudeId) => {
                  const attitude = attitudes.find(
                    (attitude) => attitude.id === attitudeId
                  );
                  if (attitude) {
                    return (
                      <Badge
                        key={attitude.id}
                        className="badge-pill"
                        onClick={() => handleRemoveAttitude(attitudeId)}
                      >
                        {attitude.name}
                      </Badge>
                    );
                  }
                  return null;
                })}
                <select
                  value=""
                  name="selectedAttitudes"
                  onChange={(e) => {
                    const attitudeId = e.target.value;
                    setSelectedAttitudes((prevAttitudes) => {
                      if (prevAttitudes.includes(attitudeId)) {
                        return prevAttitudes;
                      }
                      return [...prevAttitudes, attitudeId];
                    });
                  }}
                >
                  <option value="" disabled>
                    -- Agregar actitud --
                  </option>
                  {attitudes.map((attitude) => (
                    <option key={attitude.id} value={attitude.id}>
                      {attitude.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Col>
              <div>
                <label>Categoría:</label>
                <Form.Select
                  name="jobCategoryId"
                  className="modal-select"
                  onChange={handleInputChange}
                  defaultValue=""
                >
                  <option value="" disabled>
                    -- Seleccionar categoría --
                  </option>
                  {jobCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Crear
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
