import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/video/worker_level_elemento01.png";
import avatarLogo from "../assets/workerlevel elemento 44.png";

import "./Navigation.css";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { BASE_URL } from "../utils/endpoints/urls";
import Avatar from "react-avatar";
import { confirmAlert } from "react-confirm-alert";

export default function Navigation({ user }) {
  const loggedIn = localStorage.getItem("jwtToken");
  const userRole = user?.role;

  const logOut = () => {
    localStorage.removeItem("jwtToken");
    window.location.href = "/";
  };

  let userImageSrc;
  if (user?.imageId) {
    userImageSrc = `${BASE_URL}/images/${user.imageId}`;
  } else if (user?.role === "OWNER") {
    userImageSrc = avatarLogo;
  } else {
    userImageSrc = null;
  }

  const roleLinks = {
    FREELANCE: [
      {
        title: "Autónomos",
        links: [
          { text: "Mi panel", href: "/freelance-dashboard" },
          { text: "Mi perfil", href: "/freelance-profile" },
          { text: "Titulos y Cursos", href: "/employee-courses" },
          { text: "Mis experiencias", href: "/employee-experiences" },
          { text: "Publicar servicios", href: "/freelance-services" },
          { text: "QR", href: "/freelance-qr" },
          { text: "Calificaciones", href: "/freelance-qualification" },
          { text: "Ver cursos", href: "/recommended-courses" },
          { text: "Comentarios", href: "/comments" },
          { text: "Ayuda", href: "/faq" },
          { text: "Reportar error", href: "/reportar-error" },
        ],
      },
    ],
    COMPANY: [
      {
        title: "Empresas",
        links: [
          { text: "Mi panel", href: "/company-dashboard" },
          { text: "Mi perfil", href: "/company-profile" },
          { text: "Empleados", href: "/company-employees" },
          { text: "Interesados", href: "/company-postulates" },
          { text: "Membresía", href: "/company-membership" },
          { text: "Publicaciones", href: "/company-job-offer" },
          { text: "Capital Humano", href: "/company-prospect" },
          { text: "QR", href: "/company-qr" },
          { text: "Calificaciones", href: "/company-qualification" },
          { text: "Comentarios", href: "/comments" },
          { text: "Ayuda", href: "/faq" },
          { text: "Reportar error", href: "/reportar-error" },
        ],
      },
    ],
    EMPLOYEE: [
      {
        title: "Particulares",
        links: [
          { text: "Mi panel", href: "/employee-dashboard" },
          { text: "Mi perfil", href: "/employee-profile" },
          { text: "Titulos y Cursos", href: "/employee-courses" },
          { text: "Mis experiencias", href: "/employee-experiences" },
          { text: "Calificaciones", href: "/employee-qualification" },
          { text: "Buscar empleo", href: "/employee-job-offer" },
          { text: "Mis postulaciones", href: "/employee-applications" },
          { text: "Ver cursos", href: "/employee-recommended-courses" },
          { text: "Comentarios", href: "/comments" },
          { text: "Ayuda", href: "/faq" },
          { text: "Reportar error", href: "/reportar-error" },
        ],
      },
    ],
    OWNER: [
      {
        title: "Propietario",
        links: [
          { text: "Mi panel", href: "/owner-dashboard" },
          { text: "Mi perfil", href: "/owner-profile" },
          { text: "Actitudes", href: "/attitudes-management" },
          { text: "Rubros de trabajo", href: "/job-categories-management" },
          { text: "Comentarios", href: "/comment-management" },
          {
            text: "Cursos recomendados",
            href: "/recommended-courses-management",
          },
          { text: "Membresias", href: "/membership-management" },
          { text: "Reportes", href: "reports-dashboard" },
          { text: "Usuarios", href: "/user-management" },
          { text: "Sponsors", href: "/sponsors-management" },
          { text: "Parametros globales", href: "global-parameters-management" },
          { text: "Ayuda", href: "/faq" },
          { text: "Reportar error", href: "/reportar-error" },
        ],
      },
    ],
  };

  const getRoleLinks = (userRole) => {
    return roleLinks[userRole] || [];
  };

  const handleLogOut = (attitudeId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `Esta por salir del Acceso al Panel. ¿Esta seguro que desea cerrar la sesión? Aceptar o cancelar.'`,
      buttons: [
        {
          label: "Aceptar",
          onClick: () => {
            logOut();
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div>
      {loggedIn && (
        <Navbar
          collapseOnSelect
          id="my-navbar"
          bg="none"
          expand="lg"
          fixed="top"
        >
          <Navbar.Brand as={NavLink} onClick={() => handleLogOut()}>
            <img
              src={logo}
              height="50"
              className="d-inline-block align-top"
              alt="Worker Level Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-menu" />
          <Navbar.Collapse id="main-menu">
            <Nav className="ml-auto custom-link">
              <Nav.Link as={NavLink} onClick={() => handleLogOut()}>
                Inicio
              </Nav.Link>
              <Nav.Link as={NavLink} href="#" to="/contacto">
                Contacto
              </Nav.Link>
              {getRoleLinks(userRole).map((roleLink) => (
                <NavDropdown key={roleLink.title} title={roleLink.title}>
                  {roleLink.links.map((link) => (
                    <NavDropdown.Item
                      key={link.text}
                      id="my-dropdown"
                      href={link.href}
                    >
                      {link.text}
                    </NavDropdown.Item>
                  ))}
                  {loggedIn && (
                    <NavDropdown.Item
                      href="#"
                      id="my-dropdown"
                      onClick={() => logOut()}
                    >
                      Cerrar sesión
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
              ))}
            </Nav>
            <Nav>
              <div className="avatar-container">
                {userImageSrc ? (
                  <img
                    className="avatar-image"
                    src={userImageSrc}
                    alt="User Avatar"
                  />
                ) : (
                  <Avatar
                    name={user?.name}
                    color="#7800FF"
                    size="50"
                    round={true}
                  />
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}

      {!loggedIn && (
        <Navbar
          collapseOnSelect
          id="my-navbar"
          bg="none"
          expand="lg"
          fixed="top"
        >
          <Navbar.Brand as={NavLink} to="/">
            <img
              src={logo}
              height="50"
              className="d-inline-block align-top"
              alt="Worker Level Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="main-menu" />
          <Navbar.Collapse id="main-menu">
            <Nav className="ml-auto custom-link">
              <Nav.Link as={NavLink} href="#" to="/">
                Inicio
              </Nav.Link>
              <NavDropdown title="Particulares">
                <NavDropdown.Item id="my-dropdown" href="/signup">
                  Registrarme
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/employee-dashboard">
                  Mi panel
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/employee-job-offer">
                  Buscar empleo
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/recommended-courses">
                  Ver cursos
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Autónomos">
                <NavDropdown.Item id="my-dropdown" href="/signup">
                  Registrarme
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/freelance-dashboard">
                  Mi panel
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/freelance-services">
                  Publicar servicios
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/recommended-courses">
                  Ver cursos
                </NavDropdown.Item>
                <NavDropdown.Item
                  id="my-dropdown"
                  href="/freelance-applications"
                >
                  Ver servicios publicados
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Empresas">
                <NavDropdown.Item id="my-dropdown" href="/signup">
                  Registrarme
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/company-dashboard">
                  Mi panel
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/company-dashboard">
                  Membresía
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/company-dashboard">
                  Publicar empleo
                </NavDropdown.Item>
                <NavDropdown.Item id="my-dropdown" href="/company-dashboard">
                  Buscar capital humano
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} href="#" to="/contacto">
                Contacto
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link
                style={{ backgroundColor: "#7800FF" }}
                className="ingresar-btn"
                as={NavLink}
                href="#"
                to="/signin"
              >
                Ingresar
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </div>
  );
}
