import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyProfile.css";
import { Container } from "react-bootstrap";
import { BASE_URL } from "../../utils/endpoints/urls";
import Avatar from "react-avatar";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "../../utils/company/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../utils/endpoints/users/api";

export default function CompanyProfile({ user }) {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingUserInfo, setIsEditingUserInfo] = useState(false);
  const [newImageSrc, setNewImageSrc] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [company, setCompany] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.companyId) return;
        const response = await getCompany(user?.companyId);
        setCompany(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [user?.companyId]);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    sponsor: false,
    file: null,
  });

  const [userFormData, setUserFormData] = useState({
    name: "",
    email: "",
    birthDate: "",
    phone: "",
  });

  let imageSrc;
  if (user?.imageId) {
    imageSrc = `${BASE_URL}/images/${user.imageId}`;
  } else {
    imageSrc = null;
  }

  const handleImageChange = (event) => {
    handleEdit();

    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewImageSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
    toast.info("Imagen cargada, presione Guardar cambios para actualizarla.");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserInputChange = (event) => {
    const { name, value } = event.target;
    setUserFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (company) {
        setIsSubmitting(true);
        let res = await updateCompany(company?.id, formData);
        if (res.status === 200) {
          toast.success("Empresa modificada con éxito");
          navigate("/company-profile");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.response.data.message);
        }
      } else {
        if (!formData.name || !formData.description) {
          toast.error(
            "Debe completar los campos de Empresa y Descripción antes de guardar."
          );
          return;
        }

        let res = await createCompany(formData);
        if (res.status === 200) {
          toast.success("Empresa creada con éxito");
          navigate("/company-profile");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(res.response.data.message);
        }
      }
    } catch (error) {
      toast.error("Ocurrío un error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateUserInfo = async () => {
    try {
      setIsSubmitting(true);
      let res = await updateUser(userFormData);
      if (res.status === 200) {
        toast.success("Datos personales modificados con éxito");
        navigate("/company-profile");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (user?.role !== "COMPANY") {
    return <h3>No posee acceso para ver este sitio.</h3>;
  }

  const handleEdit = () => {
    setIsEditing(true);

    if (company) {
      setFormData({
        name: company?.name,
        description: company?.description,
        sponsor: company?.sponsor,
        file: null,
      });
    }
  };

  const handleEditUserInfo = () => {
    setIsEditingUserInfo(true);

    if (user) {
      setUserFormData({
        name: user?.name,
        email: user?.email,
        birthDate: user?.birthDate,
        phone: user?.phone,
      });
    }
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <div className="profile-section">
            <div className="profile-picture">
              {imageSrc ? (
                <img
                  className="avatar-image"
                  src={imageSrc}
                  alt="User Avatar"
                />
              ) : (
                <Avatar
                  name={user?.name}
                  color="#7800FF"
                  size="50"
                  round={true}
                />
              )}
              <hr></hr>
              <h4 className="benefits-h1">Datos personales</h4>
              <div className="user-info">
                <div className="user-field">
                  <label>Nombre:</label>
                  <input
                    type="text"
                    disabled={!isEditingUserInfo}
                    onChange={handleUserInputChange}
                    value={isEditingUserInfo ? userFormData.name : user.name}
                    name="name"
                  />
                </div>
                <div className="user-field">
                  <label>CUIT</label>
                  <input type="text" disabled value={user.cuil} />
                </div>
                <div className="user-field">
                  <label>Correo electrónico</label>
                  <input
                    type="text"
                    disabled={!isEditingUserInfo}
                    onChange={handleUserInputChange}
                    value={isEditingUserInfo ? userFormData.email : user.email}
                    name="email"
                  />
                </div>
                <div className="user-field">
                  <label>Fecha de nacimiento</label>
                  <input
                    type="date"
                    disabled={!isEditingUserInfo}
                    onChange={handleUserInputChange}
                    value={
                      isEditingUserInfo
                        ? userFormData.birthDate
                        : user.birthDate
                    }
                    name="birthDate"
                  />
                </div>
                <div className="user-field">
                  <label>Teléfono</label>
                  <input
                    type="text"
                    disabled={!isEditingUserInfo}
                    onChange={handleUserInputChange}
                    value={isEditingUserInfo ? userFormData.phone : user.phone}
                    name="phone"
                  />
                </div>
              </div>
              <button
                style={{ marginLeft: "15px", backgroundColor: "#7800ff" }}
                onClick={handleEditUserInfo}
              >
                {"Editar"}
              </button>
              <a href="/change-password">
                <button
                  style={{
                    marginLeft: "15px",
                    marginRight: "15px",
                    cursor: "pointer",
                    backgroundColor: "#06C798",
                    color: "white",
                    padding: "10px",
                    borderRadius: "15px",
                  }}
                >
                  Cambiar contraseña
                </button>
              </a>
              {isEditingUserInfo && (
                <button
                  className="send-button"
                  style={{ marginLeft: "15px", backgroundColor: "#4EA301" }}
                  onClick={updateUserInfo}
                >
                  {isSubmitting ? "Guardando..." : "Guardar"}
                </button>
              )}
            </div>
            <hr></hr>
            <h4 className="benefits-h1">Datos de empresa</h4>
            <input
              id="fileInput"
              style={{ display: "none" }}
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
            <label>(máx. 5MB, formato: jpg, jpeg, png)</label>
            <div>
              <div className="profile-field">
                <label>Empresa:</label>
                <input
                  type="text"
                  disabled={!isEditing}
                  value={
                    isEditing
                      ? formData.name
                      : user.companyId !== null
                      ? company?.name
                      : ""
                  }
                  placeholder={company ? company?.name : ""}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>
              <div className="profile-field">
                <label>Descripción:</label>
                <textarea
                  type="text"
                  disabled={!isEditing}
                  value={
                    isEditing
                      ? formData.description
                      : user.companyId !== null
                      ? company?.description
                      : ""
                  }
                  placeholder={company ? company?.description : ""}
                  onChange={handleInputChange}
                  name="description"
                />
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={
                    isEditing
                      ? formData.sponsor
                      : user.companyId !== null
                      ? company?.sponsor
                      : false
                  }
                  name="sponsor"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      sponsor: e.target.checked,
                    }))
                  }
                />
                <label>&nbsp;Me gustaría ser sponsor de Worker Level</label>
              </div>
            </div>
            <br></br>
            <div>
              <button
                style={{ backgroundColor: "#7800ff" }}
                onClick={handleEdit}
              >
                Editar
              </button>
              <label
                onClick={() => {
                  setIsEditing(true);
                }}
                htmlFor="fileInput"
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  cursor: "pointer",
                  backgroundColor: "#06C798",
                  color: "white",
                  padding: "10px",
                  borderRadius: "15px",
                }}
              >
                Cambiar foto
              </label>
              {isEditing && (
                <button
                  className="send-button"
                  style={{ backgroundColor: "#4EA301" }}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? "Guardando..." : "Guardar"}
                </button>
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
