import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyPostulates/CompanyPostulates.css";
import { Container, Table } from "react-bootstrap";
import CompanyJobOfferDetailsModal from "../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal";
import {
  getEmployeeApplications,
  getEmployeeSummary,
} from "../../utils/employee-summary/api";
import moment from "moment";

export default function EmployeeApplications({ user }) {
  const [employee, setEmployee] = useState({});
  const [jobOffers, setJobOffers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.employeeSummaryId) return;
        let response = await getEmployeeSummary(user?.id);
        setEmployee(response.data);

        if (!employee?.id) return;
        response = await getEmployeeApplications(
          employee?.id,
          currentPage,
          pageSize
        );
        setJobOffers(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();
  }, [user?.employeeSummaryId, employee?.id, currentPage]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  if (user?.role !== "EMPLOYEE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h2 className="benefits-h1">Mis postulaciones activas</h2>
          <hr></hr>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Titulo</th>
                  <th>Empresa</th>
                  <th>Requisito</th>
                  <th>Categoría</th>
                  <th>Fecha de creación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jobOffers &&
                  jobOffers.map((jobOffer) => (
                    <tr key={jobOffer?.employeeSummary?.id}>
                      <td>{jobOffer.code}</td>
                      <td>{jobOffer.title}</td>
                      <td>{jobOffer.company.name}</td>
                      <td>{jobOffer?.requirements}</td>
                      <td>{jobOffer.jobCategory.name}</td>
                      <td>{moment(jobOffer.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <CompanyJobOfferDetailsModal
                          jobOffer={jobOffer}
                          isDisabled={true}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
