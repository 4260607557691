import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyJobOffer.css";
import { Container, Table } from "react-bootstrap";
import { getCompany } from "../../utils/company/api";
import {
  disableJobOffer,
  getCompanyJobOffers,
} from "../../utils/job-offers/api";
import moment from "moment/moment";
import CompanyJobOfferDetailsModal from "./CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal";
import { getJobCategories } from "../../utils/job-category/api";
import { getAttitudes } from "../../utils/attitudes/api";
import CompanyJobOfferCreateModal from "./CompanyJobOfferCreateModal/CompanyJobOfferCreateModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

export default function CompanyJobOffer({ user }) {
  const [company, setCompany] = useState(null);
  const [jobOffers, setJobOffers] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [attitudes, setAttitudes] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.companyId) return;
        let response = await getCompany(user?.companyId);
        setCompany(response.data);

        if (!company?.id) return;
        response = await getCompanyJobOffers(company?.id);
        setJobOffers(response.data);

        response = await getJobCategories();
        setJobCategories(response.data);

        response = await getAttitudes();
        setAttitudes(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [user?.companyId, company?.id]);

  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleToggleJobOffer = (offerId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deshabilitar" : "habilitar"
      } la oferta de trabajo?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await disableJobOffer(offerId);
              if (response.status === 200) {
                toast.success(
                  `Oferta de trabajo ${
                    active ? "deshabilitada" : "habilitada"
                  } con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${
                  active ? "deshabilitar" : "habilitar"
                } la oferta de trabajo`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  if (
    user?.role !== "COMPANY" ||
    user?.companyId === null ||
    company?.membership === null ||
    company?.membership?.approvalPending === true
  ) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
              <a href="/company-membership">Solicite su membresía aquí</a>
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <div className="table-container">
            <CompanyJobOfferCreateModal
              jobCategories={jobCategories}
              attitudes={attitudes}
              handleClose={handleCreateModalClose}
            />

            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Título</th>
                  <th>Requisito</th>
                  <th>Categoría</th>
                  <th>Fecha de creación</th>
                  <th>Activa</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jobOffers.map((offer) => (
                  <tr key={offer.id}>
                    <td>{offer.code}</td>
                    <td>{offer.title}</td>
                    <td>{offer.requirements}</td>
                    <td>{offer.jobCategory.name}</td>
                    <td>{moment(offer.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      <button
                        className={`toggle-button ${
                          offer.active ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleToggleJobOffer(offer.id, offer.active)
                        }
                      >
                        {offer.active ? "Habilitada" : "Deshabilitada"}
                      </button>
                    </td>
                    <td>
                      <CompanyJobOfferDetailsModal
                        jobOffer={offer}
                        jobCategories={jobCategories}
                        attitudes={attitudes}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </div>
  );
}
