import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyPostulates/CompanyPostulates.css";
import { Container, Form, FormSelect, Table } from "react-bootstrap";
import Avatar from "react-avatar";
import { BASE_URL } from "../../utils/endpoints/urls";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { applyToJobOffer, getJobOffers } from "../../utils/job-offers/api";
import CompanyJobOfferDetailsModal from "../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal";
import { getJobCategories } from "../../utils/job-category/api";
import moment from "moment";
import { getEmployeeApplications } from "../../utils/employee-summary/api";
import "./EmployeeJobOffer.css";

export default function EmployeeJobOffer({ user }) {
  const [jobOffers, setJobOffers] = useState([]);
  const [applications, setApplications] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;
  const [filters, setFilters] = useState({
    jobCategoryId: "",
    title: "",
  });

  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    const intervalId = setInterval(reloadPage, 30000);

    const fetchData = async () => {
      try {
        let response = await getJobOffers();
        setJobOffers(response.data);
        setTotalPages(response.data[0].totalPages);

        response = await getJobCategories();
        setJobCategories(response.data);

        if (!user?.employeeSummaryId) return;
        response = await getEmployeeApplications(
          user?.employeeSummaryId,
          currentPage,
          pageSize
        );
        setApplications(response.data);

        handleSearch();
      } catch (error) {}
    };

    fetchData();

    return () => {
      clearInterval(intervalId);
    };
  }, [user?.employeeSummaryId, currentPage]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearch = async () => {
    try {
      const jobOffers = await getJobOffers(
        currentPage,
        pageSize,
        filters.jobCategoryId,
        filters.title
      );

      if (jobOffers && jobOffers.data) {
        setJobOffers(jobOffers.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      jobCategoryId: "",
      title: "",
    });
  };
  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePostulate = (employeeSummaryId, jobOfferApplicationId) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres postularte a esta oferta de trabajo?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              toast.info("Aplicando a oferta de trabajo...");
              let response = await applyToJobOffer(
                employeeSummaryId,
                jobOfferApplicationId
              );

              if (response.status === 200) {
                toast.dismiss(); // Ocultar el toast de "Postulando..."
                toast.success(response.data);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.dismiss(); // Ocultar el toast de "Postulando..."
              toast.error(
                `Ha ocurrido un error al intentar postularte a la oferta de trabajo`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      {user && <Sidebar user={user} />}
      <div className="content">
        <Container>
          <h2 className="benefits-h1">Ofertas de trabajo de empresas</h2>
          <hr></hr>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {currentPage + 1} de {totalPages}
            </span>
          </div>
          <div className="filters">
            <FormSelect
              name="jobCategoryId"
              className="modal-select"
              value={filters.jobCategoryId}
              onChange={handleFilterChange}
            >
              <option value="">Seleccione una categoría</option>
              {jobCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </FormSelect>
            <Form.Control
              type="text"
              name="title"
              value={filters.title}
              onChange={handleFilterChange}
              placeholder="Filtrar por título"
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th>Titulo</th>
                  <th>Empresa</th>
                  <th>Responsabilidades</th>
                  <th>Categoria</th>
                  <th>Fecha de publicación</th>
                  <th>Ver</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jobOffers &&
                  jobOffers.map((jobOffer) => (
                    <tr key={jobOffer.id}>
                      <td>{jobOffer.code}</td>
                      <td>{jobOffer?.title}</td>
                      <td>
                        {jobOffer.company.image !== null ? (
                          <img
                            className="avatar-image"
                            src={`${BASE_URL}/images/${jobOffer.company.image?.id}`}
                            alt="Empleado"
                          />
                        ) : (
                          <Avatar
                            name={jobOffer.company.name}
                            size="40"
                            round
                          />
                        )}
                        &nbsp;
                        {jobOffer.company.name}
                      </td>
                      <td>{jobOffer.responsibilities}</td>
                      <td>{jobOffer.jobCategory.name}</td>
                      <td>{moment(jobOffer.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <CompanyJobOfferDetailsModal
                          jobOffer={jobOffer}
                          isDisabled={true}
                        />
                      </td>
                      <td>
                        <button
                          className={`toggle-button ${
                            applications.find((app) => app.id === jobOffer.id)
                              ? ""
                              : "apply-button"
                          }`}
                          onClick={() =>
                            user
                              ? handlePostulate(
                                  user?.employeeSummaryId,
                                  jobOffer.id
                                )
                              : (window.location.href = "/signup")
                          }
                        >
                          {applications.find(
                            (app) => app.id === jobOffer.id
                          ) ? (
                            <>
                              <span role="img" aria-label="Tilde verde">
                                &#10003;
                              </span>{" "}
                              Postulado
                            </>
                          ) : (
                            <>
                              <span role="img" aria-label="Cruz roja">
                                &#10007;
                              </span>{" "}
                              Aplicar
                            </>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
