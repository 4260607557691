import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getAllCompanyQualifications = async (companyId, page, size) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company-qualification/company/${companyId}?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCompanyQualificationsByEmployee = async (
  employeeId,
  page,
  size
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company-qualification/employee/${employeeId}?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
