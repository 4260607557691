import axios from "axios";
import jwtDecode from "jwt-decode";
import setAuthToken from "../helpers/setAuthToken";
import { BASE_URL } from "../utils/endpoints/urls";
import { toast } from "react-toastify";
import { getUser } from "../utils/endpoints/users/api";

export const loginUser = (userData) => (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${BASE_URL}/users/login`, userData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        const { authorization } = res.headers;

        setAuthToken(authorization);

        const decoded = jwtDecode(authorization);

        dispatch(setCurrentUser({ user: decoded, loggedIn: true }));

        localStorage.setItem("jwtToken", authorization);

        try {
          const response = await getUser();

          switch (response?.data?.role) {
            case "COMPANY":
              window.location.href = "/company-dashboard";
              break;
            case "EMPLOYEE":
              window.location.href = "/employee-dashboard";
              break;
            case "OWNER":
              window.location.href = "/owner-dashboard";
              break;
            case "FREELANCE":
              window.location.href = "/freelance-dashboard";
              break;
            default:
              break;
          }
        } catch (getUserError) {
          console.error(getUserError);
        }

        resolve(res);

        toast.success("Bienvenido!");
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setCurrentUser = ({ user, loggedIn }) => {
  return {
    type: "SET_CURRENT_USER",
    payload: {
      user,
      loggedIn,
    },
  };
};

export const logoutUser = (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(
    setCurrentUser({
      user: {},
      loggedIn: false,
    })
  );
};
