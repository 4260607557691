import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import "../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import { approveMembership } from "../../utils/membership/api";

export default function MembershipCreateModal({ membership }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, []);

  const handleSave = async () => {
    let res;
    try {
      res = await approveMembership(membership.id, formData);
      if (res.status === 200) {
        toast.success("Membresía aprobada con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    startDate: "",
    membershipType: membership?.type || "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateEndDate = () => {
    const { startDate, membershipType } = formData;
    if (startDate && membershipType) {
      const startDateObj = new Date(startDate);
      let endDateObj = new Date(startDate);

      if (membershipType === "MONTHLY") {
        endDateObj.setMonth(startDateObj.getMonth() + 1);
      } else if (membershipType === "TRIMESTERLY") {
        endDateObj.setMonth(startDateObj.getMonth() + 3);
      } else if (membershipType === "SEMESTERLY") {
        endDateObj.setMonth(startDateObj.getMonth() + 6);
      } else if (membershipType === "ANUAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 12);
      }

      const endDate = `${
        endDateObj.getMonth() + 1
      }/${endDateObj.getDate() + 1}/${endDateObj.getFullYear()}`;
      return endDate;
    }

    return "";
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  switch (membership?.type) {
    case "MONTHLY":
      membership.type = "MENSUAL";
      break;
    case "TRIMESTERLY":
      membership.type = "TRIMESTRAL";
      break;
    case "SEMESTERLY":
      membership.type = "SEMESTRAL";
      break;
    case "YEARLY":
      membership.type = "ANUAL";
      break;
    default:
      break;
  }

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#7800FF" }}
        onClick={handleOpen}
      >
        Aprobar membresía
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Aprobar membresía</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Empresa:</label>
                <input type="text" value={membership?.company?.name} disabled />
              </div>
            </Col>
            <Col>
              <div>
                <label>Tipo de membresía:</label>
                <input type="text" value={membership?.type} disabled />
              </div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <div>
                <label>Comprobante de pago:</label>
                <input type="text" value={membership?.paymentReceipt} disabled />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  type="date"
                  onChange={handleInputChange}
                  name="startDate"
                />
              </div>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col>
              <div></div>
            </Col>
            <Col>
              <div>
                <label>Fecha de fin estimada:</label>
                <input type="text" value={calculateEndDate()} disabled />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Aprobar
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
