import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyEmployees.css";
import { Button, Container, Table } from "react-bootstrap";
import {
  assignPublicAttentionToEmployeeByCompany,
  deleteEmployeeValidation,
  getEmployeesByCompany,
  validateEmployeeByCompany,
} from "../../utils/employee-summary/api";
import Avatar from "react-avatar";
import { BASE_URL } from "../../utils/endpoints/urls";
import { getCompany } from "../../utils/company/api";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import CompanyEmployeesValidateCreateModal from "./CompanyEmployeesValidateCreateModal/CompanyEmployeesValidateCreateModal";
import { getAttitudes } from "../../utils/attitudes/api";
import { getCompanyValidateEmployeesByCompanyId } from "../../utils/company-validate-employee/api";
import CompanyEmployeesValidateDetailModal from "./CompanyEmployeesValidateDetailModal/CompanyEmployeesValidateDetailModal";
import { useParams } from "react-router-dom";

export default function CompanyEmployeesOwner({ user }) {
  const [employees, setEmployees] = useState([]);
  const { companyId } = useParams();
  const [company, setCompany] = useState({});
  const [attitudes, setAttitudes] = useState([]);
  const [companyValidateEmployees, setCompanyValidateEmployees] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [filters, setFilters] = useState({
    jobCategory: "",
    averageQualification: "",
    age: "",
    attitudeId: "",
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCompany(companyId);
        setCompany(response.data);

        response = await getEmployeesByCompany(companyId, page, size);
        setEmployees(response.data);

        response = await getAttitudes();
        setAttitudes(response.data);

        response = await getCompanyValidateEmployeesByCompanyId(companyId);
        setCompanyValidateEmployees(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [user?.companyId, companyId, page, size]);

  //   const handleFilterChange = (event) => {
  //     const { name, value } = event.target;
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       [name]: value,
  //     }));
  //   };

  //   const handleSearch = async () => {
  //     try {
  //       const employees = await getEmployeesForProspect(
  //         page,
  //         size,
  //         filters.jobCategory,
  //         filters.age,
  //         filters.attitudeId,
  //         filters.averageQualification
  //       );

  //       if (employees && employees.data) {
  //         setEmployees(employees.data);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   const handleClearFilters = () => {
  //     setFilters({
  //       jobCategory: "",
  //       averageQualification: "",
  //       age: "",
  //       attitudeId: "",
  //     });
  //   };

  // Calcular la cantidad total de páginas
  const totalPages = Math.ceil(employees.length / size);

  // Obtener los empleados para la página actual
  const startIndex = page * size;
  const endIndex = startIndex + size;
  const displayedEmployees = employees.slice(startIndex, endIndex);

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 0; i < totalPages; i++) {
      const isActive = i === page;
      buttons.push(
        <button
          key={i}
          className={`page-button ${isActive ? "active" : ""}`}
          onClick={() => handlePageChange(i)}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  const handleToggleJobOffer = (employeeId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deshabilitar" : "habilitar"
      } el empleado?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response;
              if (active) {
                response = await deleteEmployeeValidation(employeeId);
              } else {
                response = await validateEmployeeByCompany(employeeId);
              }

              if (response.status === 200) {
                toast.success(
                  `Empleado ${
                    active ? "deshabilitado" : "habilitado"
                  } con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${active ? "deshabilitar" : "habilitar"} el empleado`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleTogglePublicAttention = (employeeId, assignPublicAttention) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        assignPublicAttention ? "deshabilitar" : "habilitar"
      } la atención al público del empleado?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await assignPublicAttentionToEmployeeByCompany(
                employeeId
              );
              if (response.status === 200) {
                toast.success(
                  `Atención al público del empleado ${
                    assignPublicAttention ? "deshabilitada" : "habilitada"
                  } con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${
                  assignPublicAttention ? "deshabilitar" : "habilitar"
                } la atención al público del empleado`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handleDetailModalClose = () => {
    setShowDetailModal(false);
  };

  if (
    user?.role !== "OWNER" ||
    companyId === null
    // ||
    // company?.membership === null ||
    // company?.membership?.approvalPending === true
  ) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
              <a href="/company-membership">Solicite su membresía aquí</a>
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h2 className="benefits-h1">Empleados de {company?.name}</h2>
          <hr></hr>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {page + 1} de {totalPages}
            </span>
          </div>

          {/* <div className="filters">
            <FormSelect
              name="jobCategory"
              value={filters.jobCategory}
              onChange={handleFilterChange}
            >
              <option value="" disabled>
                Seleccione una categoría
              </option>
              {jobCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </FormSelect>
            <FormSelect
              name="attitudeId"
              value={filters.attitudeId}
              onChange={handleFilterChange}
            >
              <option value="" disabled>
                Seleccione una actitud
              </option>
              {attitudes.map((attitude) => (
                <option key={attitude.id} value={attitude.id}>
                  {attitude.name}
                </option>
              ))}
            </FormSelect>
            <Form.Control
              style={{
                marginBottom: "8px",
                backgroundColor: "white",
                textAlign: "center",
              }}
              type="number"
              step="0.01"
              name="averageQualification"
              value={filters.averageQualification}
              onChange={handleFilterChange}
              placeholder="Calificación promedio"
            />
            <Form.Control
              style={{
                marginBottom: "8px",
                backgroundColor: "white",
                textAlign: "center",
              }}
              type="number"
              name="age"
              value={filters.age}
              onChange={handleFilterChange}
              placeholder="Edad"
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              <i className="bi bi-search"></i>
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div> */}
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>CUIL</th>
                  <th>Calif. prom.</th>
                  <th>Estado</th>
                  <th>Ate. al público</th>
                  <th>Calificación</th>
                  <th>Ampliar</th>
                </tr>
              </thead>
              <tbody>
                {employees.map((employee) => (
                  <tr key={employee.id}>
                    <td>
                      {employee.image !== null ? (
                        <img
                          className="avatar-image"
                          src={`${BASE_URL}/images/${employee.image?.id}`}
                          alt="Empleado"
                        />
                      ) : (
                        <Avatar name={employee.employee.name} size="40" round />
                      )}
                      &nbsp; {employee.employee.name}
                    </td>
                    <td>{employee.employee.cuil}</td>
                    <td>{employee.averageQualification}</td>
                    <td>
                      <button
                        className={`toggle-button ${
                          employee.active ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleToggleJobOffer(
                            employee.employee?.id,
                            employee.active
                          )
                        }
                      >
                        {employee.active ? "Habilitado" : "Deshabilitado"}
                      </button>
                    </td>
                    <td>
                      <button
                        className={`toggle-button ${
                          employee.hasPublicAttention ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleTogglePublicAttention(
                            employee.employee?.id,
                            employee.hasPublicAttention
                          )
                        }
                      >
                        {employee.hasPublicAttention
                          ? "Habilitada"
                          : "Deshabilitada"}
                      </button>
                    </td>
                    <td>
                      {companyValidateEmployees?.find(
                        (companyValidateEmployee) =>
                          companyValidateEmployee.employee.employee.id ===
                          employee.employee?.id
                      ) ? (
                        <CompanyEmployeesValidateDetailModal
                          attitudes={attitudes}
                          companyValidateEmployee={companyValidateEmployees?.find(
                            (companyValidateEmployee) =>
                              companyValidateEmployee.employee.employee.id ===
                              employee.employee?.id
                          )}
                          handleClose={handleDetailModalClose}
                        />
                      ) : (
                        <CompanyEmployeesValidateCreateModal
                          attitudes={attitudes}
                          employee={employee}
                          company={company}
                          isDisabled={false}
                          handleClose={handleCreateModalClose}
                        />
                      )}
                    </td>
                    <td>
                      <a href={`/employee-summary/${employee.employee?.id}`}>
                        <Button
                          style={{
                            backgroundColor: "#06C798",
                            fontWeight: "bold",
                          }}
                        >
                          CV
                        </Button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages - 1}
              >
                Siguiente
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
