import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => (
  <div className="privacy-policy-container">
    <h1 className="privacy-policy-title">Políticas de uso y privacidad</h1>
    <div className="privacy-policy-content">
      <p>
        <b>TÉRMINOS Y CONDICIONES DE USO.</b>
        <br></br> 2.1 El presente acuerdo describe los términos y condiciones
        generales («Términos y condiciones») que regulan la navegación y
        utilización (I) del sitio de internet www.workerlevel.com, (II) de todos
        los subdominios que correspondan a www.workerlevel.com (en adelante, el
        “Sitio”), (III) de todos los subdominios derivados del mismo, propiedad
        y operados por GRUPO VIMAS S.A. con domicilio en Sargento Cabral 1498
        Oeste, Capital, San Juan, Argentina, (IV) del Sitio entendido como la
        “Plataforma” y (V) del o los Servicios (el “Servicio”) brindados a
        través de la misma, por parte de cualquier persona con acceso al mismo
        (en adelante el “Usuario”). Al usar o ingresar a la Plataforma y/o
        utilizar el Servicio, el Usuario acuerda expresamente que declara
        conocer y aceptar estos Términos y Condiciones. CUALQUIER PERSONA QUE NO
        ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES TIENEN UN CARÁCTER
        OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA PLATAFORMA
        Y/O EL SERVICIO. 2.2 El Servicio de WORKER LEVEL incluye un servicio que
        permite a los Usuarios acceder al Software (en adelante “la
        Plataforma”), creados con tecnología propiedad de GRUPO VILMA S.A. y/o
        de terceros.- Al Servicio se accede a través de una web o página de
        internet para el Usuario y a través de la creación de una cuenta en
        WORKER LEVEL.- <br></br>
        <b>PROPIEDAD INTELECTUAL.</b>
        <br></br> La Plataforma, la marca “WORKER LEVEL”, y la tecnología
        utilizada, son propiedad exclusiva de GRUPO VIMAS S.A.- Todos los
        textos, gráficos, interfaces de usuario, interfaces visuales,
        fotografías, imagen comercial, marcas, logotipos, sonidos, música,
        ilustraciones, y demás contenidos en la Plataforma, con excepción del
        contenido y datos subidos por los Usuarios, son propiedad, o está
        controlado o licenciado por o para GRUPO VIMA S.A., y está protegido por
        derechos de autor, patentes, marcas y /u otros derechos de propiedad
        intelectual (en adelante, «Contenido de GRUPO VIMA S.A.).- GRUPO VIMA
        S.A. le concede a los Usuarios una licencia no exclusiva, revocable, no
        transferible, no sub-licenciable sobre la Plataforma, para acceder a la
        misma y /o utilizar el Servicio de conformidad con los presentes
        Términos y Condiciones. Los Usuarios no podrán hacer otros usos de la
        Plataforma ni del Servicio por fuera de los permitidos expresamente en
        los presentes Términos y Condiciones. Toda licencia sobre el Contenido
        de GRUPO VIMA S.A. no otorgada expresamente en estos Términos y
        Condiciones se considerará no otorgada, y dicho contenido no podrá
        utilizarse de ninguna manera sin el expreso consentimiento previo por
        escrito de GRUPO VIMAS S.A. El Usuario bajo ninguna circunstancia podrá
        modificar, copiar, o crear trabajos derivados basados en los Servicios
        y/o vender, redistribuir, comercializar, alquilar, y/o sub-licenciar,
        los mismos, excepto aquellos expresamente autorizados.- 3.1.
        Utilización. El Usuario puede utilizar la Plataforma y el Servicio
        exclusivamente con fines lícitos.- 3.2. Derechos de terceros. Los
        Usuarios declaran y garantizan que el Contenido de Usuario no viola ni
        violará ningún derecho de terceros, incluyendo y sin limitarse a
        derechos de propiedad intelectual. Los Usuarios liberan expresamente de
        responsabilidad a GRUPO VIMA S.A. por cualquier reclamo de terceros que
        pudieren tener lugar por la utilización de la Plataforma y del Servicio.
        El Usuario se compromete a no utilizar, copiar o distribuir cualquier
        contenido no especificado y autorizado expresamente en estos Términos y
        Condiciones, incluyendo y sin limitarse a cualquier uso, copia o
        distribución de cualquier material de terceros obtenidos a través de la
        Plataforma y/o los Servicios para cualquier propósito comercial.-
        <br></br>
        <b>4- SITIOS Y SERVICIOS DE TERCEROS.</b>
        <br></br> 4.1. En ocasiones, la Plataforma y/o el Servicio podrían
        permitir la vinculación a otros sitios web, servicios o recursos en
        Internet, al mismo tiempo que otros sitios web, servicios o recursos de
        Internet podrían contener enlaces a la Plataforma y/o el Servicio.- 4.2.
        El acceso a los recursos de terceros en Internet se realizará bajo
        responsabilidad de los Usuarios. Los recursos de terceros no están bajo
        el control de GRUPO VIMA S.A. y los Usuarios reconocen que GRUPO VIMA
        S.A. no es responsable por el contenido, funciones, legalidad, o
        cualquier otro aspecto de los sitios o recursos de terceros.-<br></br>
        <b>5- RENUNCIA DE GARANTÍA.</b>
        <br></br> 5.1. El Servicio se ofrece sin garantía de ningún tipo,
        incluyendo pero no limitándose a las garantías de título,
        comercialización, y cualquier otro tipo de garantía personal o
        comercial.- 5.2. El Usuario entiende y acepta que ni GRUPO VIMA S.A. ni
        sus representantes, empleados, socios y/o licenciantes pueden dar
        garantía alguna acerca de: a) la seguridad del contenido al que acceden
        los Usuarios a través de la Plataforma y/o Servicio; b) que la
        Plataforma y/o el Servicio cumplan con las expectativas del Usuario; c)
        que el funcionamiento de la Plataforma y/o Servicio sea ininterrumpido,
        puntual, seguro o libre de errores; d) que cualquier defecto en el
        funcionamiento u operación de la Plataforma o su software relacionado
        será corregido.- <br></br>
        <b>6- LIMITACIONES DE LA RESPONSABILIDAD.</b>
        <br></br> 6.1. El Usuario entiende y acepta que GRUPO VIMA S.A. ni sus
        subsidiarias y/o representantes y/o empleados, serán responsables por
        daños directos, indirectos, accidentales, especiales, o punitivos que el
        Usuario pudiere sufrir, independientemente de su causa, por el uso de la
        Plataforma y/o del Servicio. 6.2. De igual manera, GRUPO VIMA S.A. no
        podrá ser declarada responsable por el incumplimiento o retraso de sus
        obligaciones sobre la base de los Términos si tal incumplimiento o
        retraso se debe a causas que puedan considerarse, siempre que se
        interprete de manera razonable, de “Fuerza Mayor”.- 6.3. GRUPO VIMA S.A.
        no garantiza, ni puede o podrá garantizar, en ningún momento, el
        correcto funcionamiento del Servicio en navegadores web que se
        encuentren desactualizados, independientemente de la circunstancia que
        lo motive. Por tanto, el Usuario entiende y es consciente de que la
        utilización de versiones obsoletas de navegadores web conlleva, de
        manera implícita, riesgos de carga, procesamiento, descarga, interacción
        y reproducción inherentes al disfrute de la Plataforma y/o el Servicio.-
        <br></br>
        <b>7- INDEMNIDAD. 7.1.</b>
        <br></br> El Usuario mantendrá indemne, defenderá y exonerará a GRUPO
        VIMA S.A. y, en su caso, a sus clientes, asociados y proveedores de todo
        daño, responsabilidad y costo que pudiera surgir como consecuencia de un
        reclamo de terceros contra GRUPO VIMA S.A. respecto del uso de la
        Plataforma contrario a lo estipulado en estos Términos y Condiciones.-
        7.2. El Usuario mantendrá indemne, defenderá y exonerará a GRUPO VIMA
        S.A. de todo daño, responsabilidad y costo que pudiera surgir como
        consecuencia de un reclamo de terceros contra GRUPO VIMA S.A. respecto
        de la violación de derechos de propiedad intelectual.- <br></br>
        <b>8- ACUERDO COMPLETO.</b>
        <br></br> 8.1. Estos Términos y Condiciones reconocen que existe acuerdo
        completo entre el Usuario y GRUPO VIMA S.A. respecto del uso de la
        Plataforma y/o el Servicio, y sustituyen todas las comunicaciones y
        propuestas anteriores o contemporáneas, ya sea orales, escritas o
        electrónicas, entre el Usuario y GRUPO VIMA S.A.- 8.2. Si alguna
        disposición de estos Términos y Condiciones resultare inválida, la
        disposición se limitará o eliminará en la medida mínima necesaria para
        que las demás estipulaciones de los Términos y Condiciones continúen
        siendo ejecutables.- 8.3. Si el Usuario o GRUPO VIMA S.A. no pudiesen
        ejercer los derechos y facultades que emanan de estos Términos y
        Condiciones, esto no se considerará una renuncia a la continuación de
        los demás derechos y facultades establecidos en los mismos. <br></br>
        <b>
          9- ACTUALIZACIONES EN EL SERVICIO, LA PLATAFORMA Y MODIFICACIONES EN
          LOS TÉRMINOS Y CONDICIONES.
        </b>
        <br></br> 9.1. GRUPO VIMA S.A. se reserva el derecho de modificar,
        reemplazar o interrumpir el uso de la Plataforma y/o Servicio y/o
        modificar las condiciones de acceso al mismo, en cualquier momento,
        previa notificación en la Plataforma, con anterioridad al cambio
        efectuado. GRUPO VIMA S.A. podrá modificar los Términos y Condiciones,
        siempre que lo entienda oportuno sobre la base de las circunstancias del
        negocio, la legislación o debido a motivos técnicos o requisitos
        operativos.- 9.2. GRPO VIMA S.A. se reserva el derecho a modificar estos
        Términos y Condiciones y/o las Políticas de Privacidad. El Usuario
        deberá revisar periódicamente los Términos y Condiciones y las Políticas
        de privacidad para mantenerse informado acerca de posibles cambios en
        los mismos.- 9.3. GRUPO VIMA S.A. ofrecerá siempre al Usuario la
        oportunidad de cancelar el Servicio con un mínimo de antelación de
        treinta (30) días corridos a la fecha efectiva determinada para la
        entrada en vigor de la modificación a operar sobre los Términos y
        Condiciones. El acceso a la Plataforma y/o uso del Servicio luego de
        efectuarse las modificaciones, implican una aceptación tácita de los
        cambios efectuados. Si el Usuario decide no aceptar la modificación
        operada en los Servicios, deberá cancelar su suscripción y cerrar su
        cuenta.- 9.4. GUPO VIMA S.A. puede realizar, a su solo criterio, siempre
        orientado al perfeccionamiento de la experiencia del Usuario mejoras en
        la Plataforma. Por ello, es posible que la presentación de la interfaz
        de la Plataforma o la configuración de estos varíe, pero siempre
        responderá a la mejora de las opciones que incluyen los Servicios de
        cara a ofrecer al Usuario la mejor experiencia en consonancia con la
        evolución de la tecnología utilizada por GRUPO VIMA S.A.- <br></br>
        <b>10- FUERZA MAYOR.</b>
        <br></br> Ni GRUPO VIMA S.A. ni el Usuario serán responsables por los
        incumplimientos en que pudiere incurrir siempre que dichos
        incumplimientos se deban a cuestiones que se encuentran más allá de su
        control razonable, incluyendo pero no limitándose a: cualquier
        catástrofe natural, huelga, cierre forzoso de cualquier índole,
        interrupción en el suministro de energía eléctrica, teléfono,
        telecomunicaciones, internet, y cualquier otra causa natural,
        tecnológica, política o económica u otra, fuera de su control que no
        pueda ser superada mediante el empleo de diligencia razonable sin
        ocasionar gastos excesivos.- <br></br>
        <b>11-TERMINACIÓN.</b>
        <br></br> 11.1. El Usuario podrá cancelar la suscripción en cualquier
        momento. La terminación tendrá efecto a partir del periodo siguiente al
        que se solicitó la baja, debiendo el Usuario abonar el precio
        correspondiente al periodo de facturación en curso en el que se produzca
        la solicitud de baja.- 11.2. GRUPO VIMA S.A. tiene derecho a cancelar,
        suspender o terminar la prestación de Servicios, en todo o en parte, si:
        a) El Usuario incumple cualquier disposición de estos Términos y
        Condiciones; b) GRUPO VIMA S.A. está obligado a hacerlo por ley o por
        una orden de una autoridad competente, o c) GRUPO VIMA S.A. decide
        suspender permanentemente los Servicios. En todos los casos, la
        terminación no dará derecho a los Usuarios de percibir ningún tipo de
        indemnización y/ o reembolso.- 11.3. GRUPO VIMA S.A. podrá continuar
        almacenando contenido de usuario, los datos ingresados por un usuario,
        por un periodo de hasta dos años de producida la terminación por
        cualquier causa, cuando razonablemente presuma la posibilidad de
        ulteriores encargos. Pasado ese periodo de tiempo, los datos serán
        borrados de las bases de datos propiedad de GRUPO VIMA S.A.- <br></br>
        <b>12- CESIÓN.</b>
        <br></br>
        GRUPO VIMA S.A. puede ceder, transferir o delegar cualquiera de sus
        derechos y obligaciones sin necesidad de consentimiento por parte del
        Usuario. Queda terminantemente prohibido que el Usuario ceda o transmita
        los derechos y obligaciones que le vincula como consecuencia de la
        aplicación de los Términos, así como disponer de ellos en algún otro
        modo, sin consentimiento previo y por escrito de GRUPO VIMA S.A.-
        <br></br>
        <b>13- ACUERDO GRUPO VIMA S.A.-EMPLEADOS Y/ CONTRATADOS.</b>
        <br></br>
        13.1. El futuro empleado y/o contratado de nuestros usuarios
        (empleadores y/o contratantes) autoriza a GRUPO VIMA S.A. a través de su
        plataforma WORKER LEVEL, como así también a su empleador a evaluar su
        actitud y aptitud en el puesto laboral desempeñado, mediante la
        puntuación de estrellas que se publicaran en la plataforma WORKER LEVEL
        quedando su desempeño registrado en nuestra plataforma, sirviendo el
        mismo como referencia en futuros vínculos laborales y/o contratos. -
        <br></br>
        <b>AUTORIZACIÓN DE EVALUACIÓN Y PUNTUACIÓN.</b>
        <br></br> 14.1. Al suscribir la presente autorización de evaluación y
        puntuación, declara que autoriza a la plataforma digital WORKER LEVEL,
        propiedad de GRUPO VIMA S.A., como así también a su empleadora y/o
        contratante, a puntualizar su actitud y aptitud durante la jornada
        laboral, en el puesto desempeñado, y que a modo ejemplificativo se
        mencionan: puntualidad, cordialidad con los clientes y con sus pares,
        valoración del desempeño en el puesto ocupado. 14.2. En tal sentido,
        autoriza que, dicha puntuación sea publicada y/o incorporada con fines
        evaluativos e informativos a través de la plataforma digital WORKER
        LEVEL, la cual, será utilizada y/o visualizada por todos aquellos
        usuarios que contraten con GRUPO VIMA S.A. - Deja expresa constancia que
        por medio del presente documento, toma conocimiento, y entiende que, la
        puntuación efectuada y publicada en la plataforma WORKER LEVEL,
        consistirá en sus aptitudes y actitudes de su desempeño durante la
        jornada laboral, la que, no violará las disposiciones contenidas en el
        Art. 17 de la Ley de Contrato de Trabajo N° 20.744 y/o cualquier otra
        norma aplicable. – Asimismo, manifiesta que, no existe relación de
        subordinación, ni dependencia con el GRUPO VIMA S.A., propietarios de la
        plataforma digital WORKER LEVEL, como así tampoco podrán ser citados en
        juicio ante eventuales reclamos laborales contra aquellas personas que
        hayan contratado la plataforma digital WORKER LEVEL. - <br></br>
        <b>POLÍTICA DE PUBLICIDAD.</b>
        <br></br> GRUPO VIMA S.A. a través de su plataforma WORKER LEVEL evitara
        en todo momento juicios de valor y/o agraviantes hacia los empleados y/o
        autónomos de sus usuarios.- La puntuación se efectuará mediante el uso
        de cinco (5) estrellas, y se publicará en la plataforma WORKER LEVEL,
        evitando cualquier tipo de terminología verbal y escrita.- <br></br>
        <b>LEY APLICABLE Y JURISDICCIÓN</b>
        <br></br> 16.1. Los presentes Términos y Condiciones se regirán e
        interpretarán de conformidad con las leyes de la República Argentina.-
        16.2. Cualquier controversia derivada de los Términos y Condiciones, su
        existencia, validez, interpretación, alcance o cumplimiento, será
        sometida a la Jurisdicción de los Tribunales en lo Civil y Comercial
        Federal de la República Argentina con sede en la Provincia de San Juan.-
        <br></br>
        Última actualización: 01 Octubre de 2023.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
