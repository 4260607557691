import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import jwtDecode from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";

const MAX_IDLE_TIME = 900000; // 15 minutos en milisegundos
const TOKEN_EXPIRATION = 5 * 24 * 60 * 60 * 1000; // 5 días en milisegundos

const useAuth = () => {
  const loggedIn = !!localStorage.getItem("jwtToken");
  return loggedIn;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  const [isSessionExpired, setSessionExpired] = useState(false);

  const handleUserActivity = () => {
    setSessionExpired(false);
  };

  const handleIdle = () => {
    setSessionExpired(true);
  };

  const { start, pause } = useIdleTimer({
    timeout: MAX_IDLE_TIME,
    onIdle: handleIdle,
    debounce: 500,
  });

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      if (decodedToken && decodedToken.exp) {
        const expirationDate = new Date(decodedToken.exp * 1000);
        const currentDate = new Date();
        if (currentDate > expirationDate) {
          localStorage.clear();
          setSessionExpired(true);
        } else {
          // Calcular el tiempo restante de expiración del token
          const tokenExpirationTime =
            expirationDate.getTime() - currentDate.getTime();
          if (tokenExpirationTime > TOKEN_EXPIRATION) {
            // Si el tiempo restante es mayor que la expiración en el backend, ajustar el timeout del idle timer
            start(tokenExpirationTime);
          } else {
            // Si el tiempo restante es menor o igual a la expiración en el backend, ajustar el timeout del idle timer a la expiración en el backend
            start(TOKEN_EXPIRATION);
          }
        }
      }
    }

    // Agregar event listeners para detectar la actividad del usuario
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Limpiar event listeners al desmontar el componente
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  if (isSessionExpired || !isAuth) {
    localStorage.clear();
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
