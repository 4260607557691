import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getAttitudes = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/attitudes`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllAttitudes = async (page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  try {
    const response = await axios.get(
      `${BASE_URL}/attitudes/all?page=${pageNumber}&size=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableAttitude = async (attitudeId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/attitudes/${attitudeId}/disable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createAttitude = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/attitudes`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateAttitude = async (attitudeId, formData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/attitudes/${attitudeId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
