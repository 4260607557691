import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./ReportError.css";
import { toast } from "react-toastify";
import { sendReportErrorForm } from "../../utils/endpoints/report-error/api";

function ReportError() {
  const [data, setData] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await sendReportErrorForm(data);
      toast.success(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div style={{ minHeight: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
      
        }}
      >
        <form>
          <div className="contact-title">
            <h2>Reportar un error</h2>
            <p>
              Si viste un error en nuestro funcionamiento acá puedes reportarlo
            </p>
          </div>
          <div className="contact-form">
            <div>
              <input
                placeholder="Nombre y apellido"
                id="name"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="text"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span style={{ color: "red" }}>
                  El nombre y apellido es requerido
                </span>
              )}
            </div>
            <div>
              <textarea
                placeholder="Comentanos sobre el error"
                id="error"
                onChange={(e) => setData({ ...data, error: e.target.value })}
                type="text"
                {...register("error", {
                  required: true,
                })}
              />
              {errors.error && (
                <span style={{ color: "red" }}>El campo es requerido</span>
              )}
            </div>
            <div className="button-container">
              <button onClick={handleSubmit(onSubmit)}>Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ReportError;
