import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const createCompany = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/company/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCompany = async (companyId, data) => {
  try {
    const response = await axios.put(`${BASE_URL}/company/` + companyId, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompany = async (companyId) => {
  try {
    const response = await axios.get(`${BASE_URL}/company/` + companyId, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyReduced = async (companyId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company/${companyId}/reduced`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompaniesReduced = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/company/reduced`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompaniesWithSponsorEnabled = async (page, size) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company/sponsor-enabled?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
