import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Container, Form, Table } from "react-bootstrap";
import moment from "moment/moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../CommentManagement/CommentManagement.css";
import { fetchEnabledRecommendedCourses } from "../../utils/recommended-courses/api";
import RecommendedCourseDetailModal from "../RecommendedCoursesManagement/RecommendedCourseCreateModal/RecommendedCourseDetailModal/RecommendedCourseDetailModal";

export default function EmployeeRecommendedCourses({ user }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [filters, setFilters] = useState({
    description: "",
    name: "",
  });
  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const reloadPage = () => {
      window.location.reload();
    };

    const intervalId = setInterval(reloadPage, 30000);

    const fetchData = async () => {
      try {
        let response = await fetchEnabledRecommendedCourses(
          currentPage,
          pageSize
        );
        setRecommendedCourses(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPage]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  const handleToggleCollapse = (recommendedCourseId) => {
    setRecommendedCourses((prevRecommendedCourses) =>
      prevRecommendedCourses.map((recommendedCourse) => {
        if (recommendedCourse.id === recommendedCourseId) {
          return {
            ...recommendedCourse,
            collapsed: !recommendedCourse.collapsed,
          };
        }
        return recommendedCourse;
      })
    );
  };

  const handleSearch = async () => {
    try {
      const recommendedCourses = await fetchEnabledRecommendedCourses(
        currentPage,
        pageSize,
        filters.description,
        filters.name
      );

      if (recommendedCourses && recommendedCourses.data) {
        setRecommendedCourses(recommendedCourses.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      description: "",
      name: "",
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      {user && <Sidebar user={user} />}
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Cursos recomendados por la plataforma</h4>
          <hr></hr>
          <div className="filters">
            <Form.Control
              type="text"
              name="description"
              value={filters.description}
              onChange={handleFilterChange}
              placeholder="Filtrar por descripción"
            />
            <Form.Control
              type="text"
              name="name"
              value={filters.name}
              onChange={handleFilterChange}
              placeholder="Filtrar por nombre"
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Descripción</th>
                  <th>Fecha de creación</th>
                  <th>Ver</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {recommendedCourses?.map((recommendedCourse) => (
                  <tr key={recommendedCourse.id}>
                    <td>{recommendedCourse.name}</td>
                    <td>
                      <div
                        className={`comment-content ${
                          recommendedCourse.collapsed ? "collapsed" : ""
                        }`}
                        onClick={() =>
                          handleToggleCollapse(recommendedCourse.id)
                        }
                      >
                        {recommendedCourse.description}
                      </div>
                    </td>
                    <td>
                      {moment(recommendedCourse.createdAt).format("DD/MM/YYYY")}
                    </td>
                    <td>
                      <RecommendedCourseDetailModal
                        recommendedCourse={recommendedCourse}
                        client={true}
                      />
                    </td>
                    <td>
                      <a
                        href={recommendedCourse.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button>Ir al curso</button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
