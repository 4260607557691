import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyQR.css";
import { Col, Container } from "react-bootstrap";
import { getCompany } from "../../utils/company/api";
import QRCode from "react-qr-code";
import { BASE_URL } from "../../utils/endpoints/urls";
import { useReactToPrint } from "react-to-print";

export default function CompanyQR({ user }) {
  const [company, setCompany] = useState(null);
  const qrRef = React.useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.companyId) return;
        const response = await getCompany(user?.companyId);
        setCompany(response.data);
      } catch (error) {}
    };
    fetchData();
  }, [user?.companyId]);

  const handlePrint = useReactToPrint({
    content: () => qrRef.current,
  });

  if (
    user?.role !== "COMPANY" ||
    user?.companyId === null ||
    company?.membership === null ||
    company?.membership?.approvalPending === true
  ) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
              <a href="/company-membership">Solicite su membresía aquí</a>
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="qr-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <Col>
            <div ref={qrRef} className="qr-div">
              <div className="header">
                <h4>{company?.name}</h4>
              </div>
              <p className="foot-qr">Calificar atención</p>
              <QRCode
                value={`${window.location.origin}/company-qualification/${company?.id}`}
              />
              <p className="foot-qr">Gracias</p>
              <p className="small-text">www.workerlevel.com</p>
              <button onClick={handlePrint}>Descargar</button>
            </div>
          </Col>
        </Container>
      </div>
    </div>
  );
}
