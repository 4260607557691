import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./EmployeeProfile.css";
import { Container } from "react-bootstrap";
import { getEmployeeSummary } from "../../utils/employee-summary/api";
import CreateEmployeeSummaryForm from "./CreateEmployeeSummaryForm/CreateEmployeeSummaryForm";
import EditEmployeeSummaryForm from "./EditEmployeeSummaryForm/EditEmployeeSummaryForm";
import { updateUser } from "../../utils/endpoints/users/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function EmployeeProfile({ user }) {
  const [employeeSummary, setEmployeeSummary] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingUserInfo, setIsEditingUserInfo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.employeeSummaryId) return;
        let response = await getEmployeeSummary(user?.id);
        setEmployeeSummary(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [user?.employeeSummaryId]);

  const [userFormData, setUserFormData] = useState({
    name: "",
    email: "",
    birthDate: "",
    phone: "",
  });

  const updateUserInfo = async () => {
    try {
      setIsSubmitting(true);
      let res = await updateUser(userFormData);
      if (res.status === 200) {
        toast.success("Datos personales modificados con éxito");
        navigate("/employee-profile");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUserInputChange = (event) => {
    const { name, value } = event.target;
    setUserFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditUserInfo = () => {
    setIsEditingUserInfo(true);

    if (user) {
      setUserFormData({
        name: user?.name,
        email: user?.email,
        birthDate: user?.birthDate,
        phone: user?.phone,
      });
    }
  };

  if (user?.role !== "EMPLOYEE" && user?.role !== "FREELANCE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <div className="profile-section">
            <h4 className="benefits-h1">Datos personales</h4>
            <div className="user-info">
              <div className="user-field">
                <label>Nombre:</label>
                <input
                  type="text"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={isEditingUserInfo ? userFormData.name : user.name}
                  name="name"
                />
              </div>
              <div className="user-field">
                <label>CUIT</label>
                <input type="text" disabled value={user.cuil} />
              </div>
              <div className="user-field">
                <label>Correo electrónico</label>
                <input
                  type="text"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={isEditingUserInfo ? userFormData.email : user.email}
                  name="email"
                />
              </div>
              <div className="user-field">
                <label>Fecha de nacimiento</label>
                <input
                  type="date"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={
                    isEditingUserInfo ? userFormData.birthDate : user.birthDate
                  }
                  name="birthDate"
                />
              </div>
              <div className="user-field">
                <label>Teléfono</label>
                <input
                  type="text"
                  disabled={!isEditingUserInfo}
                  onChange={handleUserInputChange}
                  value={isEditingUserInfo ? userFormData.phone : user.phone}
                  name="phone"
                />
              </div>
            </div>
          </div>
          <button
            style={{ marginLeft: "15px", backgroundColor: "#7800ff" }}
            onClick={handleEditUserInfo}
          >
            {"Editar"}
          </button>
          <a href="/change-password">
            <button
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                cursor: "pointer",
                backgroundColor: "#06C798",
                color: "white",
                padding: "10px",
                borderRadius: "15px",
              }}
            >
              Cambiar contraseña
            </button>
          </a>
          {isEditingUserInfo && (
            <button
              className="send-button"
              style={{ marginLeft: "15px", backgroundColor: "#4EA301" }}
              onClick={updateUserInfo}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </button>
          )}
          <hr></hr>
          {!user?.employeeSummaryId ? (
            <CreateEmployeeSummaryForm user={user} />
          ) : (
            <EditEmployeeSummaryForm
              user={user}
              employeeSummary={employeeSummary}
            />
          )}
        </Container>
      </div>
    </div>
  );
}
