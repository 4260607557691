import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Container, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  getGlobalParameters,
  setProperty,
} from "../../utils/global-parameters/api";

export default function GlobalParametersManagement({ user }) {
  const [globalParameters, setGlobalParameters] = useState([]);
  const [editingEnabled, setEditingEnabled] = useState({});
  const [updatedParameters, setUpdatedParameters] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getGlobalParameters();
        setGlobalParameters(response.data);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, []);

  const handleEdit = (propertyName) => {
    setEditingEnabled((prevEditing) => ({
      ...prevEditing,
      [propertyName]: true,
    }));
  };

  const handleValueChange = (propertyName, newValue) => {
    setUpdatedParameters((prevParameters) => ({
      ...prevParameters,
      [propertyName]: newValue,
    }));
  };

  const handleSave = async (propertyName, propertyValue) => {
    try {
      const formData = {
        propertyName: propertyName,
        propertyValue: propertyValue,
      };

      const res = await setProperty(formData);
      if (res.status === 200) {
        toast.success("Propiedad modificada con éxito!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error("Error al guardar la propiedad");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al guardar la propiedad");
    }
    setEditingEnabled({});
    setUpdatedParameters({});
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Gestión de Parámetros globales</h4>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Parametro</th>
                  <th>Valor</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {globalParameters.map((parameter) =>
                  parameter.propertyName === "sponsors" ? null : (
                    <tr key={parameter.propertyName}>
                      <td>{parameter.propertyTranslation}</td>
                      <td>
                        {editingEnabled[parameter.propertyName] ? (
                          <input
                            type="text"
                            value={
                              updatedParameters[parameter.propertyName] || ""
                            }
                            onChange={(e) =>
                              handleValueChange(
                                parameter.propertyName,
                                e.target.value
                              )
                            }
                          />
                        ) : Array.isArray(parameter.propertyValue) ? (
                          parameter.propertyValue.join(", ")
                        ) : (
                          parameter.propertyValue
                        )}
                      </td>
                      <td>
                        {editingEnabled[parameter.propertyName] ? (
                          <Button
                            variant="success"
                            onClick={() =>
                              handleSave(
                                parameter.propertyName,
                                updatedParameters[parameter.propertyName]
                              )
                            }
                          >
                            Guardar
                          </Button>
                        ) : (
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="edit-icon"
                            onClick={() => handleEdit(parameter.propertyName)}
                          />
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </div>
  );
}
