import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const createCourse = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/courses/`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeCourses = async (employeeId, page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/courses/employee/${employeeId}?page=${pageNumber}&size=${pageSize}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateCourse = async (data, courseId) => {
  try {
    const response = await axios.put(`${BASE_URL}/courses/` + courseId, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
