import axios from 'axios';
import { BASE_URL } from '../urls';

export const sendReportErrorForm = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/report-form/send`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
