import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "../CompanyQualificationClient/CompanyQualificationClient.css";
import "../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { BASE_URL } from "../../utils/endpoints/urls";
import Avatar from "react-avatar";
import RatingStars from "../EmployeeProfile/RatingStars/RatingStars";
import moment from "moment";

export default function CompanyQualificationDetailModal({
  companyQualification,
}) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) return;
  }, [showModal, companyQualification]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button className="btn btn-link" onClick={handleOpen}>
        <FontAwesomeIcon icon={faEye} />
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Calificación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Empleado:</label>
                <div className="employee-container">
                  <input
                    value={companyQualification?.employee.employee.name}
                    type="text"
                    disabled
                  />
                  {companyQualification?.employee?.image !== null ? (
                    <img
                      src={`${BASE_URL}/images/${companyQualification?.employee?.image?.id}`}
                      alt="Empleado"
                      className="employee-image"
                    />
                  ) : (
                    <Avatar
                      name={companyQualification?.employee.employee.name}
                      color="#7800FF"
                      size="150"
                      round={true}
                    />
                  )}
                </div>
              </div>
              <br></br>
              <div>
                <label>Calificación</label>
                <RatingStars rating={companyQualification.stars} />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de creación:</label>
                <input
                  value={moment(companyQualification.createdAt).format(
                    "DD/MM/YYYY"
                  )}
                  type="text"
                  disabled
                />
              </div>
              <div>
                <label>Comentario:</label>
                <textarea
                  value={companyQualification.comment}
                  type="text"
                  rows={5}
                  disabled
                />
              </div>
              <div>
                <label>Aptitudes:</label>
                <ul className="attitudes-list">
                  {companyQualification?.attitudes.map((attitude) => (
                    <li key={attitude.id}>{attitude.name}</li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
