import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Container, Table } from "react-bootstrap";
import moment from "moment/moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../CommentManagement/CommentManagement.css";
import { getAllCompanyQualificationsByEmployee } from "../../utils/company-qualification/api";
import RatingStars from "../EmployeeProfile/RatingStars/RatingStars";
import { BASE_URL } from "../../utils/endpoints/urls";
import Avatar from "react-avatar";
import CompanyQualificationDetailModal from "../CompanyQualification/CompanyQualificationDetailModal";
import { getEmployeeSummary } from "../../utils/employee-summary/api";

export default function EmployeeQualification({ user }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [companyQualifications, setCompanyQualifications] = useState([]);
  const [employee, setEmployee] = useState({});

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getAllCompanyQualificationsByEmployee(
          user.employeeSummaryId,
          currentPage,
          pageSize
        );
        setCompanyQualifications(response.data);
        setTotalPages(response.data[0].totalPages);

        response = await getEmployeeSummary(user.id);
        setEmployee(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [currentPage, user]);

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };
  const handleToggleCollapse = (commentId) => {
    setCompanyQualifications((prevComments) =>
      prevComments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            collapsed: !comment.collapsed,
          };
        }
        return comment;
      })
    );
  };

  if (user?.role !== "EMPLOYEE") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4 className="benefits-h1">Calificaciones de los clientes</h4>
            <h6 style={{ marginLeft: "auto" }}>
              Calificación promedio: {employee.averageQualification}
            </h6>
          </div>
          <span className="page-legend">
            Página {currentPage + 1} de {totalPages}
          </span>
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Empresa</th>
                  <th>Calificación</th>
                  <th>Comentario</th>
                  <th>Fecha de creación</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {companyQualifications?.map((companyQualification) => (
                  <tr key={companyQualification.id}>
                    <td>
                      {companyQualification?.company?.image?.id !== null ? (
                        <img
                          className="avatar-image"
                          src={`${BASE_URL}/images/${companyQualification?.company?.image?.id}`}
                          alt="Empleado"
                        />
                      ) : (
                        <Avatar
                          name={companyQualification?.company.name}
                          size="40"
                          round
                        />
                      )}
                      &nbsp; {companyQualification?.company.name}
                    </td>
                    <td>
                      <RatingStars rating={companyQualification.stars} />
                    </td>
                    <td>
                      <div
                        className={`comment-content ${
                          companyQualification.collapsed ? "collapsed" : ""
                        }`}
                        onClick={() =>
                          handleToggleCollapse(companyQualification.id)
                        }
                      >
                        {companyQualification.comment}
                      </div>
                    </td>
                    <td>
                      {moment(companyQualification.createdAt).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td>
                      <CompanyQualificationDetailModal
                        companyQualification={companyQualification}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
