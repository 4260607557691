import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../utils/endpoints/urls";

function VerifyAccount() {
  const [verified, setVerified] = useState(false);
  const { code } = useParams();

  useEffect(() => {
    axios
      .get(`${BASE_URL}/users/verify/${code}`)
      .then((response) => {
        setVerified(true);
      })
      .catch((err) => {
        setVerified(false);
      });
  }, []);

  return (
    <div className="verify-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        {verified ? (
          <div style={{ textAlign: "center" }}>
            <h3>Enhorabuena, su cuenta ha sido verificada.</h3>
            <h4>
              <Link to={"/signin"}>Haga clic aquí para iniciar sesión</Link>
            </h4>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h3>
              Lo sentimos, no hemos podido verificar la cuenta. Puede que ya
              esté verificada, o el código de verificación es incorrecto.
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyAccount;
