import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyMembership.css";
import { Button, Container, Table } from "react-bootstrap";
import checkIcon from "../../assets/worker level elemento24.png";
import { getCompany } from "../../utils/company/api";
import moment from "moment";
import { toast } from "react-toastify";
import { applyToMembership } from "../../utils/membership/api";
import { getMembershipLinks } from "../../utils/global-parameters/api";
import MembershipLinks from "./MembershipLinks";
import CompanyMembershipRenewal from "./CompanyMembershipRenewal/CompanyMembershipRenewal";

export default function CompanyMembership({ user }) {
  const [company, setCompany] = useState(null);
  const [paymentReceipt, setPaymentReceipt] = useState("");
  const [membershipType, setMembershipType] = useState("");
  const [membershipLinks, setMembershipLinks] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.companyId) {
          toast.info(
            "Por favor, complete su perfil para luego aplicar la membresía."
          );
        }
        let response = await getCompany(user?.companyId);
        setCompany(response.data);

        response = await getMembershipLinks();
        setMembershipLinks(response.data);
      } catch (error) {}
    };

    fetchData();
  }, [user?.companyId]);

  const handleCreateModalOpen = () => {
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  switch (company?.membership?.type) {
    case "MONTHLY":
      company.membership.type = "Mensual";
      break;
    case "TRIMESTERLY":
      company.membership.type = "Trimestral";
      break;
    case "SEMESTERLY":
      company.membership.type = "Semestral";
      break;
    case "YEARLY":
      company.membership.type = "Anual";
      break;
    default:
      break;
  }

  const sendPaymentCode = async () => {
    if (!paymentReceipt) {
      return toast.error("Debe ingresar un código de pago");
    }

    const formData = {
      paymentReceipt,
      membershipType,
      companyId: company.id,
    };

    let res;

    setIsSubmitting(true);

    try {
      toast.info(
        "Estamos procesando su solicitud. Por favor, espere, esto puede tardar unos segundos..."
      );
      res = await applyToMembership(formData);
      if (res.status === 200) {
        toast.dismiss();
        toast.success(
          "Ha aplicado a la membresía con éxito! Espere a que un administrador la apruebe."
        );

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (user?.role !== "COMPANY") {
    return <h3>No posee acceso para ver este sitio.</h3>;
  }

  return (
    <div style={{ minHeight: "70vh" }} className="membership-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h2 className="benefits-h1">Mis beneficios</h2>
          <div className="benefits-p">
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Verificar y calificar a mis empleados
            </h5>
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Obtener perfiles de interesados
            </h5>
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Publicar puestos vacantes en mi empresa
            </h5>
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Encontrar personal calificado por filtros de calificaciones
            </h5>
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Ingresar a publicaciones de perfiles con calificaciones de
              otras empresas
            </h5>
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Descargar QR para recibir calificaciones de clientes
            </h5>
            <h5 style={{ color: "black" }}>
              <i>
                <img src={checkIcon} alt="check icon" />
              </i>
              &nbsp; Acceso a sesión sponsor
            </h5>
          </div>
          <br></br>
          <hr></hr>
          <h2 className="benefits-h1">Membresia</h2>
          {company?.membership?.approvalPending &&
          !company?.membership?.isRenewal ? (
            <div>
              <h5 className="benefits-h1">
                Su membresía está pendiente de activación.
              </h5>
              <h5 className="benefits-h1">
                Por favor, espere a que un administrador la apruebe. Nos
                comunicaremos por correo electrónico.
              </h5>
            </div>
          ) : (
            <div className="table-container">
              <Table className="table-responsive">
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Fecha de alta</th>
                    <th>Fecha de inicio</th>
                    <th>Fecha de vencimiento</th>
                    <th>Estado</th>
                    <th>Recibo de pago</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{company?.membership?.type || "No posee"}</td>
                    <td>
                      {company?.registrationDate
                        ? moment(company?.registrationDate).format("DD/MM/yyyy")
                        : "-"}
                    </td>
                    <td>
                      {company?.membership?.startDate
                        ? moment(company?.membership?.startDate).format(
                            "DD/MM/yyyy"
                          )
                        : "-"}
                    </td>
                    <td>
                      {company?.membership?.endDate
                        ? moment(company?.membership?.endDate).format(
                            "DD/MM/yyyy"
                          )
                        : "-"}
                    </td>
                    <td>
                      {company?.membership?.active ? "Activa" : "Inactiva"}
                    </td>
                    <td>
                      {company?.membership ? (
                        company.membership.paymentReceipt
                      ) : (
                        <div className="payment-input-container">
                          <div>
                            <label>Tipo de membresía:</label>
                            <select
                              value={membershipType}
                              onChange={(e) =>
                                setMembershipType(e.target.value)
                              }
                            >
                              <option value="" disabled>
                                Seleccionar tipo
                              </option>
                              <option value="MENSUAL">MENSUAL</option>
                              <option value="TRIMESTRAL">TRIMESTRAL</option>
                              <option value="SEMESTRAL">SEMESTRAL</option>
                              <option value="ANUAL">ANUAL</option>
                            </select>
                            <MembershipLinks
                              links={membershipLinks}
                              selectedMembershipType={membershipType}
                            />
                            <input
                              type="text"
                              placeholder="Ingrese el código de pago"
                              value={paymentReceipt}
                              onChange={(e) =>
                                setPaymentReceipt(e.target.value)
                              }
                            />
                            <button
                              disabled={isSubmitting}
                              onClick={sendPaymentCode}
                            >
                              {isSubmitting ? "Enviando..." : "Enviar"}
                            </button>
                          </div>
                        </div>
                      )}
                    </td>
                    <td>
                      {company?.membership?.isRenewal &&
                      !company?.membership?.approvalPending ? (
                        <CompanyMembershipRenewal
                          membership={company?.membership}
                          membershipLinks={membershipLinks}
                        />
                      ) : company?.membership?.isRenewal === false &&
                        company?.membership?.approvalPending ===
                          false ? null : (
                        <Button disabled>
                          Solicitud de renovación pendiente de activación
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
}
