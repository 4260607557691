import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "./CompanyPostulates.css";
import { Container, Table } from "react-bootstrap";
import Avatar from "react-avatar";
import { BASE_URL } from "../../utils/endpoints/urls";
import { getCompany } from "../../utils/company/api";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import {
  getPostulatesByCompany,
  selectPostulate,
} from "../../utils/job-offers/api";
import CompanyJobOfferDetailsModal from "../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default function CompanyPostulates({ user }) {
  const [postulates, setPostulates] = useState([]);
  const [company, setCompany] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;
  const [filters, setFilters] = useState({
    jobCategory: "",
    averageQualification: "",
    age: "",
    attitudeId: "",
  });

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user?.companyId) return;
        let response = await getCompany(user?.companyId);
        setCompany(response.data);

        if (!company?.id) return;
        response = await getPostulatesByCompany(
          company?.id,
          currentPage,
          pageSize
        );
        setPostulates(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();
  }, [user?.companyId, company?.id, currentPage]);

  //   const handleFilterChange = (event) => {
  //     const { name, value } = event.target;
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       [name]: value,
  //     }));
  //   };

  //   const handleSearch = async () => {
  //     try {
  //       const employees = await getEmployeesForProspect(
  //         page,
  //         size,
  //         filters.jobCategory,
  //         filters.age,
  //         filters.attitudeId,
  //         filters.averageQualification
  //       );

  //       if (employees && employees.data) {
  //         setEmployees(employees.data);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   const handleClearFilters = () => {
  //     setFilters({
  //       jobCategory: "",
  //       averageQualification: "",
  //       age: "",
  //       attitudeId: "",
  //     });
  //   };

  const handleSelectPostulate = (jobOfferApplicationId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deseleccionar" : "seleccionar"
      } el empleado?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await selectPostulate(jobOfferApplicationId);

              if (response.status === 200) {
                toast.success(
                  `Empleado ${
                    active ? "deseleccionado" : "seleccionado"
                  } con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${
                  active ? "deseleccionar" : "seleccionar"
                } el empleado`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  if (
    user?.role !== "COMPANY" ||
    user?.companyId === null ||
    company?.membership === null ||
    company?.membership?.approvalPending === true
  ) {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
              <a href="/company-membership">Solicite su membresía aquí</a>
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h2 className="benefits-h1">
            Usuarios interesados en {company?.name}
          </h2>
          <hr></hr>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <span className="page-legend">
              Página {currentPage + 1} de {totalPages}
            </span>
          </div>

          {/* <div className="filters">
            <FormSelect
              name="jobCategory"
              value={filters.jobCategory}
              onChange={handleFilterChange}
            >
              <option value="" disabled>
                Seleccione una categoría
              </option>
              {jobCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </FormSelect>
            <FormSelect
              name="attitudeId"
              value={filters.attitudeId}
              onChange={handleFilterChange}
            >
              <option value="" disabled>
                Seleccione una actitud
              </option>
              {attitudes.map((attitude) => (
                <option key={attitude.id} value={attitude.id}>
                  {attitude.name}
                </option>
              ))}
            </FormSelect>
            <Form.Control
              style={{
                marginBottom: "8px",
                backgroundColor: "white",
                textAlign: "center",
              }}
              type="number"
              step="0.01"
              name="averageQualification"
              value={filters.averageQualification}
              onChange={handleFilterChange}
              placeholder="Calificación promedio"
            />
            <Form.Control
              style={{
                marginBottom: "8px",
                backgroundColor: "white",
                textAlign: "center",
              }}
              type="number"
              name="age"
              value={filters.age}
              onChange={handleFilterChange}
              placeholder="Edad"
            />
            <button
              className="btn btn-outline-secondary rounded-pill"
              onClick={handleSearch}
            >
              <i className="bi bi-search"></i>
              Buscar
            </button>
            <div>
              <p onClick={handleClearFilters}>Limpiar Filtros</p>
            </div>
          </div> */}
          <div className="table-container">
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>CUIL</th>
                  <th>Calificación promedio</th>
                  <th>Codigo de Oferta</th>
                  <th>Oferta de trabajo de interes para la empresa</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {postulates.map((postulate) => (
                  <tr key={postulate.employeeSummary.id}>
                    <td>
                      {postulate.employeeSummary.image !== null ? (
                        <img
                          className="avatar-image"
                          src={`${BASE_URL}/images/${postulate.employeeSummary.image?.id}`}
                          alt="Empleado"
                        />
                      ) : (
                        <Avatar
                          name={postulate.employeeSummary.employee.name}
                          size="40"
                          round
                        />
                      )}
                      &nbsp; {postulate.employeeSummary.employee.name}
                      <a
                        href={`/employee-summary/${postulate.employeeSummary.employee.id}`}
                      >
                        <button className="btn btn-link">
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </a>
                    </td>
                    <td>{postulate.employeeSummary.employee.cuil}</td>
                    <td>{postulate.employeeSummary.averageQualification}</td>
                    <td>{postulate.jobOffer.code}</td>
                    <td>
                      {postulate.jobOffer.title}
                      <CompanyJobOfferDetailsModal
                        jobOffer={postulate.jobOffer}
                        isDisabled={true}
                      />
                    </td>
                    <td>
                      <button
                        className={`toggle-button ${
                          postulate.selected ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleSelectPostulate(
                            postulate.id,
                            postulate.selected
                          )
                        }
                      >
                        {postulate.selected ? (
                          <>
                            <span role="img" aria-label="Tilde verde">
                              &#10003;
                            </span>{" "}
                            Seleccionado
                          </>
                        ) : (
                          <>
                            <span role="img" aria-label="Cruz roja">
                              &#10007;
                            </span>{" "}
                            Seleccionar
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
