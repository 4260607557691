import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getCompaniesEntered = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/company/count/companies-entered`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeesEntered = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/users/count/employees-entered`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getFreelancersEntered = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/users/count/freelancers-entered`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getMembershipsCloseToExpire = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/membership/count/close-to-expire`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getUsersEntered = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/users/count/users-entered/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
