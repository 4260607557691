import axios from "axios";
import { BASE_URL } from "../urls";

export const fetchComments = async (page, size) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/comments?page=${page}&size=${size}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchAllComments = async (page, size) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  try {
    const response = await axios.get(
      `${BASE_URL}/comments/all?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchAllCommentsByUser = async (userId, page, size) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/comments/user/${userId}/?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const createComment = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/comments/`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const disableComment = async (commentId) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/comments/${commentId}/disable`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
