import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../CompanyQR/CompanyQR.css";
import { Col, Container } from "react-bootstrap";
import { getCompany } from "../../utils/company/api";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";

export default function CompanyQROwner({ user }) {
  const [company, setCompany] = useState(null);
  const qrRef = React.useRef(null);
  const { companyId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getCompany(companyId);
        setCompany(response.data);
      } catch (error) {}
    };
    fetchData();
  }, [companyId]);

  const handlePrint = useReactToPrint({
    content: () => qrRef.current,
  });

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio. <br></br>
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="qr-container">
      <Sidebar user={user} />
      <div className="content">
        {company?.membership !== null ||
        company?.membership?.approvalPending !== true ? (
          <Container>
            <Col>
              <div ref={qrRef} className="qr-div">
                <div className="header">
                  <h4>{company?.name}</h4>
                </div>
                <p className="foot-qr">Calificar atención</p>
                <QRCode
                  value={`${window.location.origin}/company-qualification/${company?.id}`}
                />
                <p className="foot-qr">Gracias</p>
                <p className="small-text">www.workerlevel.com</p>
                <button onClick={handlePrint}>Descargar</button>
              </div>
            </Col>
          </Container>
        ) : (
          <Container>
            <h2 className="benefits-h1">
              La empresa no posee membresía activa. <br></br>
            </h2>
          </Container>
        )}
      </div>
    </div>
  );
}
