import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "../SignUp/SignUp.css";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../utils/endpoints/users/api";

function ForgotPassword() {
  const [data, setData] = useState({});
  const loggedIn = localStorage.getItem("jwtToken");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      toast.info("Estamos procesando su solicitud. Por favor, espere...");
      const response = await forgotPassword(data);
      toast.dismiss(); 
      if (response.status === 200) {
        toast.success(response.data);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Ha ocurrido un error. Por favor, intente nuevamente");
    }
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  });

  return (
    <div style={{paddingBottom: "15rem"}} className="signin-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form>
          <div className="contact-title">
            <h2>¿Olvidó su contraseña?</h2>
            <p>Indíquenos su CUIL/CUIT</p>
          </div>
          <div className="contact-form">
            <div>
              <input
                placeholder="Ingresar CUIL/CUIT"
                id="cuil"
                onChange={(e) => setData({ ...data, cuil: e.target.value })}
                type="text"
                {...register("cuil", { required: true })}
              />
              {errors.cuil && (
                <span style={{ color: "red" }}>El campo es requerido</span>
              )}
            </div>
            <div>
              <button className="send-button" onClick={handleSubmit(onSubmit)}>
                Restablecer contraseña
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
