import axios from "axios";
import { BASE_URL } from "../endpoints/urls";

export const getFreelanceApplicationsByEmployee = async (employeeId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/freelance-application/employee/` + employeeId,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getActiveFreelanceApplications = async (
  page,
  size,
  jobCategoryId,
  title
) => {
  const defaultPage = 0;
  const defaultSize = 10;

  const pageNumber = page || defaultPage;
  const pageSize = size || defaultSize;

  let url = `${BASE_URL}/freelance-application?page=${pageNumber}&size=${pageSize}`;

  if (jobCategoryId) {
    url += `&jobCategoryId=${jobCategoryId}`;
  }

  if (title) {
    url += `&title=${title}`;
  }

  url += `&active=true`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const createFreelanceApplication = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/freelance-application/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateFreelanceApplication = async (id, data) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/freelance-application/` + id,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const disableFreelanceApplication = async (id) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/freelance-application/` + id + "/disable",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
