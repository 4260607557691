import React from "react";
import image2 from "../../../assets/worker level elemento02.png";
import "./WhiteStaticImage.css";

export default function WhiteStaticImage() {
  return (
    <div>
      <div className="row">
        <div className="col-md-6 ">
          <div className="white-static-text">
            <h1 className="title">Worker Level</h1>
            <p className="description">
              Sistema de trazabilidad y puntaje<br></br> laboral, acompañamos a
              las empresas <br></br> y colaboradores para el siguiente nivel
            </p>
            <div className="buttons">
              <button
                style={{ backgroundColor: "#4EA301" }}
                className="btn btn-primary"
              >
                <a
                  href="/employee-job-offer"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Particulares
                </a>
              </button>
              <button
                style={{ backgroundColor: "#C101FA" }}
                className="btn btn-primary"
              >
                <a
                  href="/freelance-applications"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Autónomos
                </a>
              </button>
              <button
                style={{ backgroundColor: "#06C798" }}
                className="btn btn-primary"
              >
                <a
                  href="/signin"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Empresas
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6" style={{ marginTop: "30px" }}>
          <img
            className="d-block w-100"
            style={{ height: "80%" }}
            src={image2}
            alt="First slide"
          />
        </div>
      </div>
    </div>
  );
}
