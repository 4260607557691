import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { faEdit, faEye, faSave } from "@fortawesome/free-solid-svg-icons";
import "./RecommendedCourseDetailModal.css";
import "../../../CompanyJobOffer/CompanyJobOfferDetailsModal/CompanyJobOfferDetailsModal.css";
import { toast } from "react-toastify";
import { updateRecommendedCourse } from "../../../../utils/recommended-courses/api";
import { BASE_URL } from "../../../../utils/endpoints/urls";

export default function RecommendedCourseDetailModal({
  recommendedCourse,
  client,
}) {
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newImageSrc, setNewImageSrc] = useState(null);

  useEffect(() => {
    if (!showModal) return;

    setFormData((prevData) => ({
      ...prevData,
    }));
  }, [editing]);

  const handleEdit = () => {
    setEditing(true);

    setFormData({
      name: recommendedCourse?.name,
      description: recommendedCourse?.description,
      url: recommendedCourse?.url,
      file: null,
    });
  };

  let imageSrc;
  if (recommendedCourse?.image?.id) {
    imageSrc = `${BASE_URL}/images/${recommendedCourse?.image?.id}`;
  } else {
    imageSrc = null;
  }

  const handleSave = async () => {
    if (recommendedCourse) {
      let res;
      try {
        res = await updateRecommendedCourse(recommendedCourse.id, formData);
        if (res.status === 200) {
          toast.success("Actitud modificada con éxito!");
          handleClose();

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          throw new Error();
        }
      } catch (error) {
        const { errors } = res.response.data;
        Object.keys(errors).forEach((field) => {
          const errorMessage = errors[field];
          toast.error(`${errorMessage}`);
        });
      }
    }
  };

  const [formData, setFormData] = useState({
    name: recommendedCourse?.name || "",
    description: recommendedCourse?.description || "",
    url: recommendedCourse?.url || "",
    file: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const newFile = { base64, name: file.name, type: file.type };
        setFormData((prevData) => ({
          ...prevData,
          file: newFile,
        }));
        setNewImageSrc(newFile);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button className="btn btn-link" onClick={handleOpen}>
        <FontAwesomeIcon icon={faEye} />
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{recommendedCourse.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label>Nombre:</label>
            <input
              type="text"
              defaultValue={recommendedCourse.name}
              onChange={handleInputChange}
              disabled={!editing}
              name="name"
            />
          </div>
          <div>
            <label>Descripción:</label>
            <textarea
            rows={5}
              type="text"
              defaultValue={recommendedCourse.description}
              onChange={handleInputChange}
              disabled={!editing}
              name="description"
            />
          </div>
          <div>
            <label>URL:</label>
            <input
              type="text"
              defaultValue={recommendedCourse.url}
              onChange={handleInputChange}
              disabled={!editing}
              name="url"
            />
          </div>
          <div className="modal-image-container">
            {imageSrc ? (
              <img className="modal-image" src={imageSrc} alt="Imagen" />
            ) : (
              <p>Imagen no disponible.</p>
            )}
          </div>
          {editing && (
            <div>
              <label className="change-image-label">
                Subir imagen (formato .jpg/.png/.jpeg)
                <br />
                <input
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </label>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!client && (
            <div>
              {!editing ? (
                <Button variant="outline-primary" onClick={handleEdit}>
                  <FontAwesomeIcon icon={faEdit} /> Editar
                </Button>
              ) : (
                <Button variant="outline-primary" onClick={handleSave}>
                  <FontAwesomeIcon icon={faSave} /> Guardar
                </Button>
              )}
            </div>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
