import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import { createUser } from "../../utils/endpoints/users/api";
import ReCAPTCHA from "react-google-recaptcha";

function SignUp() {
  const [data, setData] = useState({});
  const loggedIn = localStorage.getItem("jwtToken");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  const handleRecaptcha = (value) => {
    setCaptcha(value);
  };

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (!checked) {
      return toast.error("Debe aceptar los términos y condiciones");
    }

    if (captcha === false) {
      return toast.error("Debe validar que no es un robot");
    }

    if (data.email !== data.confirmEmail) {
      return toast.error("Los correos electrónicos no coinciden");
    }

    if (data.encryptedPassword !== data.confirmPassword) {
      return toast.error("Las contraseñas no coinciden");
    }

    try {
      setIsSubmitting(true);
      toast.info("Estamos procesando su solicitud. Por favor, espere...");
      const response = await createUser(data);
      toast.dismiss();
      if (response.status === 200) {
        toast.success("Usuario creado con éxito, por favor verifique su email");
        navigate("/signin");
      } else {
        toast.error(response.response.data.message);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isAdult = (value) => {
    const birthDate = new Date(value);
    const ageDiff = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiff);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age >= 18;
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  });

  return (
    <div className="signin-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form>
          <div className="contact-title">
            <h2>¡Bienvenido!</h2>
            <p>
              Acompañamos a las empresas y colaboradores para el siguiente nivel
            </p>
          </div>
          <div className="contact-form">
            <div>
              <input
                placeholder="Nombre y Apellido"
                id="name"
                onChange={(e) => setData({ ...data, name: e.target.value })}
                type="text"
                {...register("name", {
                  required: true,
                  pattern: /^[a-zA-Z\s]*$/i,
                })}
              />
              {errors.name && (
                <span style={{ color: "red" }}>El nombre es requerido</span>
              )}
            </div>

            <div>
              <input
                placeholder="Correo electrónico"
                id="email"
                onChange={(e) => setData({ ...data, email: e.target.value })}
                type="email"
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                })}
              />
              {errors.email && (
                <span style={{ color: "red" }}>
                  El correo electrónico es requerido y debe ser válido
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="Confirmar correo electrónico"
                id="confirmEmail"
                onChange={(e) =>
                  setData({ ...data, confirmEmail: e.target.value })
                }
                type="email"
                {...register("confirmEmail", {
                  required: true,
                  pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                })}
              />
              {errors.confirmEmail && (
                <span style={{ color: "red" }}>
                  El correo electrónico es requerido y debe ser válido
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="Contraseña"
                id="encryptedPassword"
                onChange={(e) =>
                  setData({ ...data, encryptedPassword: e.target.value })
                }
                type="password"
                {...register("encryptedPassword", {
                  required: true,
                  minLength: 6,
                })}
              />
              {errors.encryptedPassword && (
                <span style={{ color: "red" }}>
                  {errors.encryptedPassword.type === "required"
                    ? "La contraseña es requerida"
                    : "La contraseña debe tener al menos 6 caracteres"}
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="Confirmar contraseña"
                id="confirmPassword"
                onChange={(e) =>
                  setData({ ...data, confirmPassword: e.target.value })
                }
                type="password"
                {...register("confirmPassword", {
                  required: true,
                  minLength: 6,
                })}
              />
              {errors.confirmPassword && (
                <span style={{ color: "red" }}>
                  {errors.confirmPassword.type === "required"
                    ? "La contraseña es requerida"
                    : "La contraseña debe tener al menos 6 caracteres"}
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="Teléfono"
                id="phone"
                onChange={(e) => setData({ ...data, phone: e.target.value })}
                type="text"
                {...register("phone", {
                  required: true,
                  pattern: /^[0-9]+$/,
                })}
              />
              {errors.phone && (
                <span style={{ color: "red" }}>
                  El teléfono es requerido y debe ser válido
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="CUIL/CUIT"
                id="cuil"
                onChange={(e) => setData({ ...data, cuil: e.target.value })}
                type="text"
                {...register("cuil", {
                  required: true,
                  pattern: /^[0-9]{11}$/, // Expresión regular para validar 11 números
                })}
              />
              {errors.cuil?.type === "required" && (
                <span style={{ color: "red" }}>El cuil/cuit es requerido</span>
              )}
              {errors.cuil?.type === "pattern" && (
                <span style={{ color: "red" }}>
                  El campo debe contener 11 números
                </span>
              )}
            </div>
            <div>
              <input
                placeholder="Domicilio"
                id="address"
                onChange={(e) => setData({ ...data, address: e.target.value })}
                type="text"
                {...register("address", {
                  required: true,
                })}
              />
              {errors.address && (
                <span style={{ color: "red" }}>El domicilio es requerido</span>
              )}
            </div>
            <div className="date-input-container">
              <input
                id="birthDate"
                type="date"
                {...register("birthDate", {
                  required: true,
                  validate: isAdult,
                })}
              />
              <label htmlFor="birthDate">Fecha de nacimiento</label>
              {errors.birthDate?.type === "required" && (
                <span style={{ color: "red" }}>
                  La fecha de nacimiento es requerida
                </span>
              )}
              {errors.birthDate?.type === "validate" && (
                <span style={{ color: "red" }}>Debes ser mayor de 18 años</span>
              )}
            </div>

            <div>
              <select
                id="role"
                onChange={(e) => setData({ ...data, role: e.target.value })}
                {...register("role")}
                defaultValue=""
              >
                <option disabled value="">
                  Selecciona un rol
                </option>
                <option value="EMPRESA">EMPRESA</option>
                <option value="EMPLEADO">EMPLEADO</option>
                <option value="FREELANCE">AUTONOMO</option>
              </select>
              {errors.role && (
                <span style={{ color: "red" }}>El campo rol es requerido</span>
              )}
            </div>
            <div className="checkbox-container">
              <label htmlFor="checkbox">
                <input
                  id="checkbox"
                  type="checkbox"
                  checked={checked}
                  onChange={handleCheckboxChange}
                />
                He leído y acepto las{" "}
                <Link to="/privacy-policy" target="_blank">
                  políticas de uso y privacidad
                </Link>
              </label>
            </div>
            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleRecaptcha}
              />
            </div>
            <div>
              <button
                className="send-button"
                disabled={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? "Enviando..." : "Enviar"}
              </button>
            </div>
            <div style={{ display: "block", marginTop: "1rem" }}>
              <a href="/signin" className="signup-link">
                Ya tengo una cuenta
              </a>
              <a href="/forgot-password" className="forgot-password-link">
                Olvidé mi contraseña
              </a>
            </div>
            <hr className="signin-hr"></hr>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
