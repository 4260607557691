import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "../SignUp/SignUp.css";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../utils/endpoints/users/api";
import axios from "axios";
import { BASE_URL } from "../../utils/endpoints/urls";

function ResetPassword() {
  const [data, setData] = useState({});
  const loggedIn = localStorage.getItem("jwtToken");
  const [verified, setVerified] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { token } = useParams();

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Las contraseñas no coinciden");
      return;
    }

    try {
      toast.info("Estamos procesando su solicitud. Por favor, espere...");
      const response = await resetPassword(data, token);
      toast.dismiss();
      if (response.status === 200) {
        toast.success(response.data);
      }
      navigate("/signin");
    } catch (error) {
      toast.dismiss();
      toast.error("Ha ocurrido un error. Por favor, intente nuevamente.");
    }
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/users/forgot_password/${token}`)
      .then((res) => {
        setVerified(true);
      })
      .catch((err) => {
        setVerified(false);
      });
  }, []);

  return (
    <div style={{ paddingBottom: "15rem" }} className="signin-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form>
          {verified ? (
            <div style={{ textAlign: "center" }}>
              <h3>Restablecer contraseña</h3>
              <div className="contact-form">
                <div>
                  <input
                    placeholder="Contraseña"
                    id="password"
                    onChange={(e) =>
                      setData({ ...data, password: e.target.value })
                    }
                    type="password"
                    {...register("password", {
                      required: true,
                    })}
                  />
                  {errors.password && (
                    <span style={{ color: "red" }}>El campo es requerido</span>
                  )}
                </div>
                <div>
                  <input
                    placeholder="Confirmar contraseña"
                    id="confirmPassword"
                    onChange={(e) =>
                      setData({ ...data, confirmPassword: e.target.value })
                    }
                    type="password"
                    {...register("confirmPassword", {
                      required: true,
                    })}
                  />
                  {errors.confirmPassword && (
                    <span style={{ color: "red" }}>El campo es requerido</span>
                  )}
                </div>
                <div>
                  <button
                    className="send-button"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Restablecer contraseña
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <h3>
                El token es incorrecto. Por favor, compruebe su correo
                electrónico e inténtelo de nuevo.
              </h3>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
