import React from "react";
import "./Footer.css";
import logo from "../../../assets/worker level elemento18.png";
import emailIcon from "../../../assets/icons/worker level elemento19.png";
import facebookIcon from "../../../assets/icons/worker level elemento23.png";
import instagramIcon from "../../../assets/icons/worker level elemento22.png";
import geoIcon from "../../../assets/icons/worker level elemento20.png";
import phoneIcon from "../../../assets/icons/worker level elemento21.png";

const Footer = () => {
  return (
    <footer>
      <div style={{ textAlign: "left", marginLeft: "5px" }} className="row">
        <div className="col">
          <h3>Empresas</h3>
          <ul>
            <li>
              <a href="/signup">Registrarme</a>
            </li>
            <li>
              <a href="/company-dashboard">Mi panel</a>
            </li>
            <li>
              <a href="/company-membership">Membresía</a>
            </li>
            <li>
              <a href="/company-job-offer">Publicar empleo</a>
            </li>
            <li>
              <a href="/company-prospect">Capital Humano</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>Autónomos</h3>
          <ul className="list-unstyled">
            <li>
              <a href="/signup">Registrarme</a>
            </li>
            <li>
              <a href="#">Mi panel</a>
            </li>
            <li>
              <a href="#">Publicar servicios</a>
            </li>
            <li>
              <a href="/recommended-courses">Ver cursos</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>Particulares</h3>
          <ul className="list-unstyled">
            <li>
              <a href="/signup">Registrarme</a>
            </li>
            <li>
              <a href="/employee-dashboard">Mi panel</a>
            </li>
            <li>
              <a style={{ textDecoration: "none" }} href="/employee-job-offer">
                Buscar empleo
              </a>
            </li>
            <li>
              <a href="/recommended-courses">Ver cursos</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>Worker Level</h3>
          <ul className="list-unstyled">
            <li>
              <a href="/contacto">Contacto</a>
            </li>
            <li>
              <a href="/faq">Consultas frecuentes</a>
            </li>
            <li>
              <a href="/reportar-error">Reportar un error</a>
            </li>
            <li>
              <a href="/privacy-policy">Políticas de uso y privacidad</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>Contacto</h3>

          <div className="row">
            <div className="col-sm-10">
              <p>
                <i>
                  <img
                    src={emailIcon}
                    alt="email icon"
                    className="icon-footer"
                  />
                </i>
                &nbsp; info@workerlevel.com
              </p>
              <p>
                <i>
                  <img src={geoIcon} alt="geo icon" className="icon-footer" />
                </i>
                &nbsp; San Juan, Argentina.
              </p>
              <p>
                <i>
                  <img
                    src={phoneIcon}
                    alt="phone icon"
                    className="icon-footer"
                  />
                </i>
                &nbsp; <a href="https://wa.me/2646274088">2646274088</a> &nbsp;/
                &nbsp;
                <a href="https://wa.me/2644123194">2644123194</a>
              </p>
              <div>
                <p>
                  <i>
                    <img
                      src={instagramIcon}
                      alt="instagram icon"
                      className="icon-footer"
                    />
                  </i>
                  &nbsp;
                  <a
                    target="_noblank"
                    href="https://www.instagram.com/workerlevel/"
                  >
                    workerlevel
                  </a>
                </p>
              </div>
              <div>
                <p>
                  <i>
                    <img
                      src={facebookIcon}
                      alt="facebook icon"
                      className="icon-footer"
                    />
                  </i>
                  &nbsp;
                  <a
                    target="_noblank"
                    href="https://www.facebook.com/workerlevel1"
                  >
                    workerlevel
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col">
          <a style={{ textDecoration: "none" }} href="/employee-job-offer">
            <button className="btn btn-outline-secondary rounded-pill custom-button">
              <i className="bi bi-search"></i>
              Buscar Empleo
            </button>
          </a>
          <br></br>
          <img src={logo} alt="logo" className="logo-footer" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
