import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { faCheck, faSave } from "@fortawesome/free-solid-svg-icons";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { createJobOffer } from "../../../utils/job-offers/api";
import { toast } from "react-toastify";
import MembershipLinks from "../MembershipLinks";
import { applyToRenewalMembership } from "../../../utils/membership/api";
import moment from "moment";

export default function CompanyMembershipRenewal({
  membership,
  membershipLinks,
}) {
  const [showModal, setShowModal] = useState(false);
  const [paymentReceipt, setPaymentReceipt] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [membershipTypeRenewal, setMembershipTypeRenewal] = useState("");
  const [endDateRenewal, setEndDateRenewal] = useState(null);

  const formattedStartDate = membership.startDate
    ? membership.startDate.substring(0, 10)
    : "";
  const formattedEndDate = membership.endDate
    ? membership.endDate.substring(0, 10)
    : "";

  useEffect(() => {
    if (!showModal) return;
  }, [membershipTypeRenewal]);

  const handleSave = async () => {
    let res;
    setIsSubmitting(true);
    try {
      toast.info(
        "Estamos procesando su solicitud. Por favor, espere, esto puede tardar unos segundos..."
      );
      res = await applyToRenewalMembership(formData, membership?.id);
      if (res.status === 200) {
        toast.dismiss();
        toast.success(
          "Ha aplicado a la renovación de membresía con éxito! Espere a que un administrador la apruebe."
        );
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.error(res.response.data.message);
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [formData, setFormData] = useState({
    startDate: membership?.endDate || "",
    membershipType: membershipTypeRenewal || "",
    paymentReceipt: membership?.paymentReceipt || "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateEndDate = () => {
    const { startDate, membershipTypeRenewal } = formData;
    if (startDate && membershipTypeRenewal) {
      const startDateObj = new Date(startDate);
      let endDateObj = new Date(startDate);

      if (membershipTypeRenewal === "MENSUAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 1);
      } else if (membershipTypeRenewal === "TRIMESTRAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 3);
      } else if (membershipTypeRenewal === "SEMESTRAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 6);
      } else if (membershipTypeRenewal === "ANUAL") {
        endDateObj.setMonth(startDateObj.getMonth() + 12);
      }

      const endDate = `${endDateObj.getDate()}/${
        endDateObj.getMonth() + 1
      }/${endDateObj.getFullYear()}`;
      return endDate;
    }

    return "";
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        style={{ marginBottom: "5px", backgroundColor: "#06C798" }}
        onClick={handleOpen}
      >
        Renovar membresía
      </button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Renovar Membresía</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <label>Tipo de membresía:</label>
                <select
                  value={formData.membershipTypeRenewal}
                  name="membershipTypeRenewal"
                  defaultValue={""}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Seleccionar tipo
                  </option>
                  <option value="MENSUAL">MENSUAL</option>
                  <option value="TRIMESTRAL">TRIMESTRAL</option>
                  <option value="SEMESTRAL">SEMESTRAL</option>
                  <option value="ANUAL">ANUAL</option>
                </select>
                <MembershipLinks
                  links={membershipLinks}
                  selectedMembershipType={formData.membershipTypeRenewal}
                />
                <br></br>
              </div>
            </Col>
            <Col></Col>
          </Row>
          <div>
            <input
              type="text"
              placeholder="Ingrese el código de pago"
              value={paymentReceipt}
              onChange={(e) => setPaymentReceipt(e.target.value)}
            />
          </div>
          <br></br>
          <Row>
            <Col>
              <div>
                <label>Fecha de inicio:</label>
                <input
                  type="text"
                  value={moment(membership?.endDate).format("DD/MM/YYYY")}
                  disabled
                  name="startDate"
                />
              </div>
            </Col>
            <Col>
              <div>
                <label>Fecha de fin estimada:</label>
                <input type="text" value={calculateEndDate()} disabled />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isSubmitting} onClick={handleSave}>
            {isSubmitting ? "Solicitando..." : "Solicitar renovación"}
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
