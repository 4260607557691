import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeePanel/EmployeeDashboard.css";
import { Container } from "react-bootstrap";
import { getEmployeeSummary } from "../../utils/employee-summary/api";
import { useParams } from "react-router-dom";
import EmployeeSummaryContent from "./EmployeeSummaryContent/EmployeeSummaryContent";
import { getAllCompanyQualificationsByEmployee } from "../../utils/company-qualification/api";
import { getCompanyValidateEmployeesByEmployeeId } from "../../utils/company-validate-employee/api";

export default function EmployeeSummary({ user }) {
  const [employeeSummary, setEmployeeSummary] = useState(null);
  const [companyQualifications, setCompanyQualifications] = useState([]);
  const [companyValidateEmployees, setCompanyValidateEmployees] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getEmployeeSummary(id);
        setEmployeeSummary(response.data);

        response = await getAllCompanyQualificationsByEmployee(
          response.data.id,
          0,
          100
        );
        setCompanyQualifications(response.data);

        response = await getCompanyValidateEmployeesByEmployeeId(
          response.data[0].employee.id,
          0
        );
        setCompanyValidateEmployees(response.data);
      } catch (error) {}
    };

    fetchData();
  }, []);

  if (user?.role !== "COMPANY") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }
  return (
    <div style={{ minHeight: "70vh" }} className="dashboard-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <EmployeeSummaryContent
            employeeData={employeeSummary}
            companyQualifications={companyQualifications}
            companyValidateEmployees={companyValidateEmployees}
          />
        </Container>
      </div>
    </div>
  );
}
