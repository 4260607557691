import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Badge, Col } from "react-bootstrap";
import {
  faEdit,
  faMinus,
  faPlus,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import "./CompanyEmployeesValidateDetailModal.css";
import "../../CompanyJobOffer/CompanyJobOfferCreateModal/CompanyJobOfferCreateModal.css";
import { toast } from "react-toastify";
import {
  createCompanyValidateEmployee,
  updateCompanyValidateEmployee,
} from "../../../utils/company-validate-employee/api";
import AttitudeRatingStar from "../AttitudeRatingStar/AttitudeRatingStar";
import { BASE_URL } from "../../../utils/endpoints/urls";
import Avatar from "react-avatar";

export default function CompanyEmployeesValidateDetailModal({
  attitudes,
  companyValidateEmployee,
  isDisabled,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedAttitudes, setSelectedAttitudes] = useState([]);
  const fulfilledObjectivesFromBackend =
    companyValidateEmployee?.objectives.reduce((acc, objective) => {
      acc[objective.id] = objective.fulfilled;
      return acc;
    }, {});
  const [fulfilledObjectives, setFulfilledObjectives] = useState(
    fulfilledObjectivesFromBackend
  );
  const attitudesRatingsFromBackend =
    companyValidateEmployee?.attitudesRating.reduce((acc, attitudeRating) => {
      acc[attitudeRating.attitude.id] = attitudeRating.rating;
      return acc;
    }, {});
  const [attitudesRatings, setAttitudesRatings] = useState(
    attitudesRatingsFromBackend
  );
  const [inputObjective, setInputObjective] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (!showModal) return;

    const updateAttitudeRatings = async () => {
      for (const attitudeId of selectedAttitudes) {
        if (!attitudesRatings.hasOwnProperty(attitudeId)) {
          await new Promise((resolve) => setTimeout(resolve, 0)); // Pequeña pausa para permitir que el estado se actualice
          setAttitudesRatings((prevRatings) => ({
            ...prevRatings,
            [attitudeId]: 0,
          }));
        }
      }
    };

    updateAttitudeRatings();
  }, [showModal, selectedAttitudes, attitudesRatings, editing]);

  useEffect(() => {
    // Actualizar el estado formData cuando attitudesRatings cambia
    setFormData((prevData) => ({
      ...prevData,
      attitudesRating: Object.entries(attitudesRatings).map(
        ([attitudeId, rating]) => ({
          id: attitudeId,
          rating: parseInt(rating),
        })
      ),
    }));
  }, [attitudesRatings]);

  const handleEdit = () => {
    setEditing(true);

    setFormData({
      comment: companyValidateEmployee?.comment,
      attitudesRating: companyValidateEmployee?.attitudesRating,
      objectives: companyValidateEmployee?.objectives,
      employeeId: companyValidateEmployee.employee.employee?.id,
    });
  };

  const handleSave = async () => {
    const attitudesRatingArray = Object.entries(attitudesRatings).map(
      ([attitudeId, rating]) => ({
        id: attitudeId,
        rating: parseInt(rating),
      })
    );

    const objectivesArray = companyValidateEmployee?.objectives.map(
      (objective) => ({
        skillId: objective.id,
        fulfilled: fulfilledObjectives[objective.id],
      })
    );

    setFormData((prevData) => ({
      ...prevData,
      attitudesRating: attitudesRatingArray,
      objectives: objectivesArray,
    }));

    let res;

    try {
      res = await updateCompanyValidateEmployee(
        companyValidateEmployee.id,
        formData
      );
      if (res.status === 200) {
        toast.success("Calificación del empleado actualizada con éxito!");
        handleClose();

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        throw new Error();
      }
    } catch (error) {
      const { errors } = res.response.data;
      Object.keys(errors).forEach((field) => {
        const errorMessage = errors[field];
        toast.error(`${errorMessage}`);
      });
    }
  };

  const [formData, setFormData] = useState({
    comment: companyValidateEmployee?.comment || "",
    attitudesRating: [],
    objectives: [],
    employeeId: companyValidateEmployee.employee.employee?.id,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "selectedAttitudes") {
      const attitudeId = value;
      setSelectedAttitudes((prevAttitudes) => {
        if (prevAttitudes.includes(attitudeId)) {
          return prevAttitudes;
        }
        return [...prevAttitudes, attitudeId];
      });
    } else if (name === "objectives") {
      setInputObjective(value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveAttitude = (attitudeId) => {
    setSelectedAttitudes((prevAttitudes) =>
      prevAttitudes.filter((id) => id !== attitudeId)
    );

    setAttitudesRatings((prevRatings) => {
      const updatedRatings = { ...prevRatings };
      delete updatedRatings[attitudeId];
      return updatedRatings;
    });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleAttitudeRatingChange = (attitudeId, rating) => {
    setAttitudesRatings((prevRatings) => ({
      ...prevRatings,
      [attitudeId]: rating,
    }));
  };

  const handleObjectiveFulfillmentChange = async (objectiveId, isFulfilled) => {
    await setFulfilledObjectives((prevFulfilledObjectives) => ({
      ...prevFulfilledObjectives,
      [objectiveId]: !prevFulfilledObjectives[objectiveId],
    }));

    await setFormData((prevData) => ({
      ...prevData,
      objectives: prevData.objectives.map((objective) => {
        if (objective.id === objectiveId) {
          return {
            ...objective,
            fulfilled: isFulfilled,
          };
        }
        return objective;
      }),
    }));
  };

  return (
    <>
      <Button
        style={{ marginBottom: "5px", backgroundColor: "#C101FA" }}
        onClick={handleOpen}
      >
        Ver
      </Button>
      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Calificación de {companyValidateEmployee.employee.employee?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div>
                <div className="employee-container">
                  {companyValidateEmployee.employee?.image?.id !== null ? (
                    <img
                      src={`${BASE_URL}/images/${companyValidateEmployee.employee?.image?.id}`}
                      alt="Empleado"
                      className="employee-image"
                    />
                  ) : (
                    <Avatar
                      name={companyValidateEmployee.employee.employee.name}
                      color="#7800FF"
                      size="150"
                      round={true}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <label>
                  <strong>Comentario:</strong>
                </label>
                <div>
                  <textarea
                    name="comment"
                    value={formData.comment}
                    rows="5"
                    onChange={handleInputChange}
                    disabled={!editing}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col>
              <div>
                <label>
                  <strong>Objetivo/s:</strong>
                </label>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>P</th>
                      <th>L</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyValidateEmployee?.objectives.map((objective) => (
                      <tr key={objective.id}>
                        <td>{objective.name}</td>
                        <td>
                          <input type="checkbox" checked disabled />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={fulfilledObjectives[objective.id]}
                            disabled={!editing}
                            onChange={(e) =>
                              handleObjectiveFulfillmentChange(
                                objective.id,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br></br>
                <div style={{ fontSize: "14px", color: "#777" }}>
                  <p>
                    <strong>P:</strong> En Proceso
                  </p>
                  <p>
                    <strong>L:</strong> Logrado
                  </p>
                </div>
              </div>
            </Col>
            <hr className="vertical-hr"></hr>
            <Col>
              <div>
                <label>
                  <strong>Actitud/es:</strong>
                </label>
                <div>
                  {companyValidateEmployee?.attitudesRating.map(
                    (attitudeRating) => {
                      const { attitude, rating } = attitudeRating;
                      return (
                        <div key={attitude.id}>
                          <Badge
                            key={attitude.id}
                            className="badge-pill"
                            onClick={() =>
                              editing ? handleRemoveAttitude(attitude.id) : null
                            }
                          >
                            {attitude.name}
                          </Badge>
                          <AttitudeRatingStar
                            disabled={!editing}
                            rating={attitudesRatings[attitude.id]}
                            onChange={(newRating) =>
                              handleAttitudeRatingChange(attitude.id, newRating)
                            }
                          />
                          <hr />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {!editing ? (
            <Button
              variant="outline-primary"
              onClick={handleEdit}
              style={isDisabled ? { display: "none" } : {}}
            >
              <FontAwesomeIcon icon={faEdit} /> Editar
            </Button>
          ) : (
            <Button variant="outline-primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Guardar
            </Button>
          )}
          <Button variant="outline-secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
