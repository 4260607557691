import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/Sidebar/Sidebar";
import "../EmployeeExperiences/EmployeeExperiences.css";
import { Container, Table } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import JobCategoriesCreateModal from "./JobCategoriesCreateModal";
import JobCategoriesDetailModal from "./JobCategoriesDetailModal";
import {
  disableJobCategory,
  getAllJobCategories,
} from "../../utils/job-category/api";

export default function JobCategoriesManagement({ user }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [jobCategories, setJobCategories] = useState([]);

  const pageSize = 10;
  const MAX_VISIBLE_PAGES = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getAllJobCategories(currentPage, pageSize);
        setJobCategories(response.data);
        setTotalPages(response.data[0].totalPages);
      } catch (error) {}
    };

    fetchData();
  }, [currentPage]);

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
  };

  const handlePrevPageClick = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPageClick = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToFirstPage = () => {
    setCurrentPage(0);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(
      currentPage - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i + 1}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePageClick = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
    }
  };

  if (user?.role !== "OWNER") {
    return (
      <div style={{ minHeight: "70vh" }} className="profile-container">
        <Sidebar user={user} />
        <div className="content">
          <Container>
            <h2 className="benefits-h1">
              No posee acceso para ver este sitio.
            </h2>
          </Container>
        </div>
      </div>
    );
  }

  const handleToggleAttitude = (jobCategoryId, active) => {
    confirmAlert({
      title: "Confirmar acción",
      message: `¿Estás seguro que quieres ${
        active ? "deshabilitar" : "habilitar"
      } el rubro de trabajo?`,
      buttons: [
        {
          label: "Sí",
          onClick: async () => {
            try {
              let response = await disableJobCategory(jobCategoryId);
              if (response.status === 200) {
                toast.success(
                  `Rubro ${active ? "deshabilitado" : "habilitado"} con éxito`
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              } else {
                throw new Error();
              }
            } catch (error) {
              toast.error(
                `Error al ${active ? "deshabilitar" : "habilitar"} el rubro`
              );
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <div style={{ minHeight: "70vh" }} className="profile-container">
      <Sidebar user={user} />
      <div className="content">
        <Container>
          <h4 className="benefits-h1">Gestión de Rubros</h4>
          <div className="table-container">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <JobCategoriesCreateModal handleClose={handleCreateModalClose} />
              <span className="page-legend">
                Página {currentPage + 1} de {totalPages}
              </span>
            </div>
            <Table className="table-responsive">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Estado</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {jobCategories?.map((jobCategory) => (
                  <tr key={jobCategory.id}>
                    <td>{jobCategory.name}</td>
                    <td>
                      <button
                        className={`toggle-button ${
                          jobCategory.active ? "active" : "inactive"
                        }`}
                        onClick={() =>
                          handleToggleAttitude(
                            jobCategory.id,
                            jobCategory.active
                          )
                        }
                      >
                        {jobCategory.active ? "Habilitada" : "Deshabilitada"}
                      </button>
                    </td>
                    <td>
                      <JobCategoriesDetailModal jobCategory={jobCategory} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* Botones de paginado */}
            <div className="pagination">
              <button
                className="page-button"
                onClick={goToFirstPage}
                disabled={currentPage === 0}
              >
                {"<<"}
              </button>
              <button
                className="page-button"
                onClick={handlePrevPageClick}
                disabled={currentPage === 0}
              >
                Anterior
              </button>
              {renderPageButtons()}
              <button
                className="page-button"
                onClick={handleNextPageClick}
                disabled={currentPage === totalPages - 1}
              >
                Siguiente
              </button>
              <button
                className="page-button"
                onClick={goToLastPage}
                disabled={currentPage === totalPages - 1}
              >
                {">>"}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
