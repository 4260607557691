import React from "react";
import { Carousel, Container } from "react-bootstrap";
import "./BenefitsCarousel.css";
import image1 from "../../../assets/worker level elemento10.png";
import image2 from "../../../assets/worker level elemento11.png";
import image3 from "../../../assets/worker level elemento12.png";
import checkIcon from "../../../assets/worker level elemento24.png";

function BenefitsCarousel() {
  return (
    <Container>
      <div
        style={{ marginTop: "5rem" }}
        className="benefits-carousel-container"
      >
        <Carousel id="benefits-carousel" className="benefits-carousel">
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 ">
                <div className="benefits-carousel-text">
                  <h1 className="benefits-h1">Beneficios para empresas</h1>
                  <div className="benefits-p">
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Publicar ofertas de empleo
                    </p>
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Buscar personal con valoraciones de otras empresas
                    </p>
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Posicionar contenido o publicaciones de tu empresa
                    </p>
                  </div>
                  <div>
                    <button
                      style={{ backgroundColor: "#7800FF" }}
                      className="btn btn-primary"
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ height: "80vh" }}>
                  <img
                    className="d-block benefits-img"
                    src={image1}
                    style={{ height: "60%" }}
                    alt="First slide"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 ">
                <div className="benefits-carousel-text">
                  <h1 className="benefits-h1">Beneficios para particulares</h1>
                  <div className="benefits-p">
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Buscar ofertas de empleo
                    </p>
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Recibir valoraciones del trabajo que realizaste
                    </p>
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Capacitarse para el empleo
                    </p>
                  </div>
                  <div>
                    <button
                      style={{ backgroundColor: "#7800FF" }}
                      className="btn btn-primary"
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ height: "80vh" }}>
                  <img
                    className="d-block benefits-img"
                    src={image2}
                    style={{ height: "60%" }}
                    alt="First slide"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="row">
              <div className="col-md-6 ">
                <div className="benefits-carousel-text">
                  <h1 className="benefits-h1">Beneficios para autónomos</h1>
                  <div className="benefits-p">
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Publicar los servicios que ofreces
                    </p>
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Recibir valoraciones del trabajo que realizaste
                    </p>
                    <p style={{ color: "black" }}>
                      <i>
                        <img src={checkIcon} alt="check icon" />
                      </i>
                      &nbsp; Capacitarse para el empleo
                    </p>
                  </div>
                  <div>
                    <button
                      style={{ backgroundColor: "#7800FF" }}
                      className="btn btn-primary"
                    >
                      Ver más
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div style={{ height: "80vh" }}>
                  <img
                    className="d-block benefits-img"
                    src={image3}
                    style={{ height: "60%" }}
                    alt="First slide"
                  />
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </Container>
  );
}

export default BenefitsCarousel;
